import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';

import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import InputLabel from '../../components/InputLabel/InputLabel';
import CheckboxesFields from './components/CheckboxesFields';
import EditFormAdminDefaultValue from './EditFormAdminDefaultValue';

import requestHttp from '../../http/requestHttp/requestHttp';
import { notifySuccess } from '../../utils/utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CurrencyInputNew from '../../components/CurrencyInputNew/CurrencyInputNew';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import { columnsEditFormAdmin, columnsRequestLogViewDataAdmin } from './helpers/columnsEditFormAdmin';
import ServiceLogModalShow from '../../components/Modal/ServiceLogModalShow';
import Line from '../../components/Line/Line';
import { useDropzone } from 'react-dropzone';
import MultiDropZone from '../../components/DropZone/MultiDropZone';
import Button from '../../components/Button/Button';

export default function EditFormAdmin() {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState([]);
  const [tbodyData, setTbodyData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [isOpenCommentShow, setIsOpenCommentShow] = useState(false);
  const [activityId, setActivityId] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [showAddEmail, setShowAddEmail] = useState(false);
  const [stateImage, setStateImage] = useState('');
  const [stateImageEmployee, setStateImageEmployee] = useState('');

  const params = useParams();
  const navigate = useNavigate();
  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    multiple: true,
  });
  const {
    acceptedFiles: acceptedFilesEmployee,
    getRootProps: getRootPropsEmployee,
    getInputProps: getInputPropsEmployee,
    isFocused: isFocusedEmployee,
    isDragAccept: isDragAcceptEmployee,
    isDragReject: isDragRejectEmployee,
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    multiple: true,
  });

  const afterSubmit = () => {
    notifySuccess('Request Successfully Changed');
    setTimeout(() => {
      navigate('/Admin/Requests');
    }, 2000);
  };
  const afterUpload = () => {
    notifySuccess('Files Successfully Uploaded');
    setTimeout(() => {
      navigate('/Admin/Requests');
    }, 2000);
  };

  const onClickCancel = () => {
    navigate('/Admin/Requests');
  };
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    setValue,
    getValues,
  } = useForm({
    defaultValues: useMemo(() => EditFormAdminDefaultValue(form), [form]),
    // resolver: yupResolver(RequestErrorEditFormAdmin()),
  });

  const { fields: Fields } = useFieldArray({
    control,
    name: 'formFields',
  });

  const {
    fields: ActivitiesFields,
    append: ActivitiesAppend,
    remove: ActivitiesRemove,
  } = useFieldArray({
    control,
    name: `activities`,
  });

  const onClickAddNewActivities = () => {
    ActivitiesAppend({});
  };

  const handleRemoveFile = id => {
    setIsLoading(true);
    requestHttp
      ?.removeFileFormAdmin(params?.id, id)
      // .then(() => afterRequest('File successfully Removed'))
      .then(e => setForm(e?.data?.data))
      .finally(() => setIsLoading(false));
  };

  const handleRemoveFileEmployee = id => {
    setIsLoading(true);
    requestHttp
      ?.removeEmployeeFileFormAdmin(params?.id, viewData?.employee_id, id)
      // .then(() => afterRequest('File successfully Removed'))
      .then(e => setViewData(e?.data?.data))
      .finally(() => setIsLoading(false));
  };

  const onClickView = employeId => {
    requestHttp.requestLogView(params.id, employeId).then(e => {
      setViewData(e?.data?.data);

      // setStateImageEmployee()
    });
  };

  useEffect(() => {
    if (!!viewData?.files) {
      setStateImageEmployee({ cdnUrl: viewData?.files });
    } else {
      setStateImageEmployee([]);
    }
    requestHttp.getRequestHours(params?.id).then(e => {
      setTbodyData(e?.data?.data);
    });
  }, [viewData]);

  const onClickCommentShow = obj => {
    setIsOpenCommentShow(true);
    setActivityId(obj);
  };

  const onSubmit = data => {
    const sendData = {
      email: data?.email,
      service_id: data?.service_id,
      name: data?.name,
      acceptable_hours: data?.acceptable_hours,
      // hourly_rate: data?.hourly_rate,
      completion_date: data?.completion_date || null,
      deadline: data?.deadline,
      formFields: data?.formFields.map(elem => {
        return {
          id: elem.id,
          value: elem?.type === 'checkbox' ? null : elem?.value,
          options: elem?.type === 'checkbox' ? elem?.options : null,
        };
      }),
      activities:
        data.activities?.length >= 1
          ? data?.activities?.map(elem => {
              return {
                id: elem?.id,
                hours: elem?.hours,
                activity: elem?.activity,
              };
            })
          : [],
    };
    setIsLoading(true);
    const formData = new FormData();
    acceptedFiles?.forEach(file => {
      formData?.append('files', file); // Appends multiple files
    });
    sendData.files = formData?.getAll('files');
    form?.status === 'Completed' || form?.status === 'Approved'
      ? requestHttp
          .acceptRequestFormAdmin(params.id, sendData)
          .then(() => afterSubmit())
          .catch(err => {
            err && setIsLoading(false);
            const errorFromBackend = Object.keys(err?.response?.data?.errors);
            errorFromBackend.forEach(item =>
              setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
            );
          })
      : requestHttp
          .editSubmittedFormAdmin(params.id, sendData)
          .then(() => afterSubmit())
          .catch(err => {
            err && setIsLoading(false);
            const errorFromBackend = Object.keys(err?.response?.data?.errors);
            errorFromBackend.forEach(item =>
              setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
            );
          });
  };

  const onSave = data => {
    const sendData = {
      email: data?.email,
      service_id: data?.service_id,
      name: data?.name,
      acceptable_hours: data?.acceptable_hours,
      // hourly_rate: data?.hourly_rate,
      completion_date: data?.completion_date || null,
      deadline: data?.deadline,
      formFields: data?.formFields.map(elem => {
        return {
          id: elem.id,
          value: elem?.type === 'checkbox' ? null : elem?.value,
          options: elem?.type === 'checkbox' ? elem?.options : null,
        };
      }),
      activities:
        data.activities?.length >= 1
          ? data?.activities?.map(elem => {
              return {
                id: elem?.id,
                hours: elem?.hours,
                activity: elem?.activity,
              };
            })
          : [],
    };
    setIsLoading(true);
    const formData = new FormData();
    acceptedFiles?.forEach(file => {
      formData?.append('files', file); // Appends multiple files
    });

    sendData.files = formData?.getAll('files');
    requestHttp
      .updateFormNoChangeStatusAdmin(params.id, sendData)
      .then(() => afterSubmit())
      .catch(err => {
        err && setIsLoading(false);
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
      });
  };
  const onUpload = employeId => {
    const sendData = {};
    setIsLoading(true);
    const formData = new FormData();
    acceptedFilesEmployee?.forEach(file => {
      formData?.append('files', file); // Appends multiple files
    });
    sendData.files = formData?.getAll('files');
    requestHttp
      .UploadFilesEmployeeFromAdmin(params.id, employeId, sendData)
      .then(() => afterUpload())
      .catch(err => {
        err && setIsLoading(false);
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
      });
  };

  useEffect(() => {
    reset(EditFormAdminDefaultValue(form));
    requestHttp.getRequestHours(params.id).then(e => {
      setTbodyData(e?.data?.data);
    });
    setShowFile(!!form?.included_file);
    setShowAddEmail(!!form?.included_email);
    if (!!form?.files?.length > 0) {
      setStateImage({ cdnUrl: form?.files });
    } else {
      setStateImage([]);
    }
  }, [form]);

  useEffect(() => {
    requestHttp.getSubmittedFormClient(params?.id).then(e => {
      setForm(e?.data?.data);
    });
  }, []);

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <ServiceLogModalShow open={isOpenCommentShow} setOpen={setIsOpenCommentShow} activityId={activityId} />
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title={`Edit Form for - ${form?.title}`}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="py-4 w-full">
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline">
              {Fields?.map(({ label, type, id }, index) =>
                type === 'input' ? (
                  <div className="w-full" key={id}>
                    <InputLabel
                      labelTitle={label}
                      register={register}
                      name={`formFields.${index}.value`}
                      classNameLabel="text-[#60657B] opacity-50 text-xs"
                      classNameInput="flex flex-col flex-1"
                      type={'text'}
                      placeholder={label}
                      className="w-full"
                      required={{ required: 'This field is required' }}
                    />
                    {errors && (
                      <p className="mb-0 text-sm text-amber-800">{errors?.formFields?.[index]?.value?.message}</p>
                    )}
                  </div>
                ) : (
                  type === 'date' && (
                    <div className="" key={id + label}>
                      <InputLabel
                        classNameInput="flex flex-col "
                        classNameLabel={'font-[system-ui] text-[#60657B] text-xs font-normal opacity-50'}
                        className="w-full border p-[7px]"
                        type="date"
                        labelTitle={label}
                        register={register}
                        name={`formFields.${index}.value`}
                        required={{ required: 'This field is required' }}
                      />
                      {errors && (
                        <p className="mb-0 text-sm text-amber-800">{errors?.formFields?.[index]?.value?.message}</p>
                      )}
                    </div>
                  )
                ),
              )}
            </div>
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline mt-5 h-full">
              {Fields?.map(
                ({ label, type, id }, index) =>
                  type === 'textarea' && (
                    <div className="" key={id + label}>
                      <div className="w-full flex flex-col gap-2">
                        <label className={'font-[system-ui] text-[#60657B] opacity-50 text-xs font-normal'}>
                          {label}
                        </label>
                        <textarea
                          rows={7}
                          className="p-[14px] outline-none w-full border"
                          {...register(`formFields.${index}.value`, { required: 'This field is required' })}
                        />
                        {errors && (
                          <p className="mb-0 text-sm text-amber-800">{errors?.formFields?.[index]?.value?.message}</p>
                        )}
                      </div>
                    </div>
                  ),
              )}
            </div>
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline mt-5 h-full">
              {Fields?.map(
                ({ name, label, type, id }, index) =>
                  type === 'checkbox' && (
                    <div className="space-y-5 h-full" key={id + name + label}>
                      <p className="text-lg font-semibold">{label}</p>
                      <CheckboxesFields control={control} register={register} index={index} />
                    </div>
                  ),
              )}
            </div>
            {showAddEmail && (
              <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline">
                <div className="w-full mt-5 ">
                  <InputLabel
                    labelTitle={'Applicant Email'}
                    inputId={`email`}
                    className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                    placeholder={`Notification Recipient's Email`}
                    classNameInput="w-full"
                    name={`email`}
                    register={register}
                    classNameLabel={'text-[#60657B] w-full opacity-50 text-xs font-normal'}
                    required={{ required: 'This field is required' }}
                  />
                  {errors && <p className="mb-0 text-sm text-amber-800">{errors?.email?.message}</p>}
                </div>
              </div>
            )}
            {showFile && (
              <div className="sm:px-7 px-4">
                <MultiDropZone
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  isFocused={isFocused}
                  isDragAccept={isDragAccept}
                  isDragReject={isDragReject}
                  state={stateImage}
                  handleRemoveFile={handleRemoveFile}
                />
              </div>
            )}
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 sm:gap-6 gap-2 items-baseline mt-5 h-full">
              <div>
                <InputLabel
                  labelTitle={'Request Name'}
                  className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                  placeholder={'Request Name'}
                  name={`name`}
                  register={register}
                  classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                  required={{ required: 'This field is required' }}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.name?.message}</p>}
              </div>
              {form?.acceptable_hours ? (
                <CurrencyInputNew
                  classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                  className={'w-full border outline-none rounded border p-4 text-start h-[43px]'}
                  name={`acceptable_hours`}
                  setValue={setValue}
                  placeholder="Acceptable Hours"
                  defaultValue={+form?.acceptable_hours}
                  labelTitle={'Acceptable Hours'}
                  prefix=""
                  decimalSeparator=":"
                />
              ) : (
                <CurrencyInputNew
                  classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                  className={'w-full border outline-none rounded border p-4 text-start h-[43px]'}
                  name={`acceptable_hours`}
                  setValue={setValue}
                  placeholder="Acceptable Hours"
                  labelTitle={'Acceptable Hours'}
                  prefix=""
                  decimalSeparator=":"
                />
              )}
              {(form?.status === 'Completed' || form?.status === 'Approved') && (
                <div>
                  <InputLabel
                    classNameInput="flex flex-col"
                    classNameLabel={'font-[system-ui] text-[#60657B] text-xs font-normal opacity-50'}
                    className="w-full border p-[7px]"
                    type="date"
                    labelTitle="Completion Date"
                    register={register}
                    name="completion_date"
                    required={{ required: 'This field is required' }}
                  />
                  {errors && <p className="mb-0 text-sm text-amber-800">{errors?.completion_date?.message}</p>}
                </div>
              )}

              {/* {form?.hourly_rate ? (
                <CurrencyInputNew
                  classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                  className={'w-full border outline-none rounded border p-4 text-start h-[43px]'}
                  name={`hourly_rate`}
                  setValue={setValue}
                  placeholder="Hourly Rate $ 0.00"
                  defaultValue={form?.hourly_rate}
                  labelTitle={'Hourly Rate'}
                />
              ) : (
                <CurrencyInputNew
                  classNameLabel="text-[#60657B] opacity-50 text-xs font-normal mb-2"
                  className={'w-full border outline-none rounded border p-4 text-start h-[43px]'}
                  name={`hourly_rate`}
                  setValue={setValue}
                  placeholder="Hourly Rate $ 0.00"
                  labelTitle={'Hourly Rate'}
                />
              )} */}
            </div>
            <div className="sm:px-7 px-4 items-baseline mt-5 h-full">
              {(form?.status === 'Completed' || form?.status === 'Approved') && (
                <div id="currencyUseFieldArray" className="w-full">
                  <Line />
                  <p className="text-xl font-bold my-2">Administrator overwrite of Activity Logs</p>
                  {ActivitiesFields.map((item, activitiesindex) => {
                    return (
                      <div key={item?.id} className={`${activitiesindex !== 0 && 'mt-2'}`}>
                        <div className="grid md:grid-cols-2 md:gap-6 gap-2">
                          <div className="">
                            <CurrencyInputNew
                              classNameLabel="text-[#60657B]  text-xs font-normal p-1 opacity-50"
                              className={'w-full border p-[7px] outline-none rounded border p-1'}
                              name={`activities.${activitiesindex}.hours`}
                              setValue={setValue}
                              placeholder="0.00"
                              defaultValue={form?.activities?.[activitiesindex]?.hours}
                              prefix=""
                              labelTitle={'Hours'}
                            />
                            {errors && (
                              <p className="mb-0 text-sm text-amber-800">
                                {errors?.activities?.[activitiesindex]?.hours?.message}
                              </p>
                            )}
                          </div>
                          <div className="flex flex-col w-full">
                            <div className="flex w-full">
                              <InputLabel
                                labelTitle={'Activity'}
                                className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                classNameInput="w-full"
                                placeholder={'Activity'}
                                name={`activities.${activitiesindex}.activity`}
                                register={register}
                                classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                                required={{ required: 'This field is required' }}
                              />
                              {activitiesindex >= 1 && (
                                <button
                                  type="button"
                                  className="w-8 h-[40px] mt-6 box-border rounded border border-[#E4E6F2] text-[#60657B]"
                                  onClick={() => ActivitiesRemove(activitiesindex)}>
                                  X
                                </button>
                              )}
                            </div>
                            {errors && (
                              <p className="mb-0 text-sm text-amber-800">
                                {errors?.activities?.[activitiesindex]?.activity?.message}
                              </p>
                            )}
                          </div>
                        </div>
                        {activitiesindex === ActivitiesFields?.length - 1 && (
                          <section>
                            <button
                              className="text-[#2B2D38] opacity-50 text-base"
                              type="button"
                              onClick={onClickAddNewActivities}>
                              + Add Activities
                            </button>
                          </section>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {(form?.status === 'Completed' || form?.status === 'Approved') && (
            <div className="px-4 py-1 sm:m-4 mx-4 my-6 bg-white rounded-xl">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full">
                  {
                    <TableEmployeesAdmin
                      tbody={tbodyData ?? []}
                      columns={columnsEditFormAdmin(onClickView)}
                      maxHeight="500px"
                    />
                  }
                </div>
              </div>
            </div>
          )}
          {viewData.length != 0 && (
            <div className={`px-8 py-1 mt-4 bg-white rounded-xl ${isLoading && 'animate-pulse'}`}>
              <div>
                <MultiDropZone
                  getRootProps={getRootPropsEmployee}
                  getInputProps={getInputPropsEmployee}
                  isFocused={isFocusedEmployee}
                  isDragAccept={isDragAcceptEmployee}
                  isDragReject={isDragRejectEmployee}
                  state={stateImageEmployee}
                  handleRemoveFile={handleRemoveFileEmployee}
                  employee_id={viewData?.employee_id}
                />
              </div>
              <div className="flex justify-end mt-3">
                <Button
                  onClick={() => onUpload(viewData?.employee_id)}
                  buttonText={'Upload'}
                  className="text-[#fff] px-3"
                  disabled={isLoading}
                  type="button"
                />
              </div>
              <div className="flex justify-between mb-5 ">
                <p className="mt-10 text-xl font-medium text-[#2B2D38]">{`${viewData?.employee ?? ''}`}</p>
              </div>
              <div className="w-full overflow-x-auto">
                <div className="inline-block min-w-full">
                  <TableEmployeesAdmin
                    tbody={viewData?.days ?? []}
                    columns={columnsRequestLogViewDataAdmin(viewData, onClickCommentShow)}
                    maxHeight="700px"
                  />
                </div>
              </div>
              <div className="mt-5">
                <div>
                  <p className="text-base font-black text-center">{`Total: ${viewData?.total}`}</p>
                </div>
              </div>
            </div>
          )}
          <div>
            <CancleAdd
              buttonText={form?.status === 'Completed' || form?.status === 'Approved' ? 'Approve' : 'Save'}
              onClickCancel={onClickCancel}
              disabled={isLoading}
              isSave={form?.status === 'Completed' || form?.status === 'Approved'}
              onClickSave={() => onSave(getValues())}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
