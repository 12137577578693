import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';
import InfoListModal from '../../components/Modal/InfoListModal';
import TableClients from '../../components/DataTable/TableClients';
import { columnsRequest, statuses } from './helpers/RequestsUtils';
import requestHttp from '../../http/requestHttp/requestHttp';
import { notifySuccess } from '../../utils/utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TitleBlockRequest from '../../components/TitleBlockRequest/TitleBlockRequest';
import EmployeesHttp from '../../http/employeesHttp/employeesHttp';
import ClientHttp from '../../http/clientsHttp/clientsHttp';

export default function Request() {
  const navigate = useNavigate();
  const [requestList, setRequestList] = useState([]);
  const [requestListPage, setRequestListDataPage] = useState();
  const [newLoad, setNewLoad] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [companyFormId, setCompanyFormId] = useState();
  const [isOpenEmployee, setIsOpenEmployee] = useState(false);
  const [List, setList] = useState();
  const [dataEmployeeCurrent, setDataEmployeeCurrent] = useState('');
  const [AllEmployeesData, setAllEmployeesData] = useState([]);
  const [dataClientCurrent, setDataClientCurrent] = useState('');
  const [dataStatusCurrent, setDataStatusCurrent] = useState('');
  const [AllClientsData, setAllClientsData] = useState([]);
  const [isDesc, setIsDesc] = useState('desc');

  const fetchData = useCallback(() => {
    requestHttp.getRequests('', dataEmployeeCurrent, dataClientCurrent, dataStatusCurrent, isDesc).then(e => {
      setRequestListDataPage(e?.data);
      setRequestList(e?.data?.data);
    });
    EmployeesHttp.employeesAllLinks().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          id: item?.id || item?.[0]?.employee_id,
          name: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.id)) {
            uniqueIdsSet.add(item.id);
            result.push(item);
          }
          return result;
        }, []);
      employesList.unshift({ id: '', name: 'All Employees' });
      setAllEmployeesData(employesList.length ? employesList : [{ id: '', name: 'All Employees' }]);
    });
    ClientHttp.getAllClientss().then(e => {
      e?.data?.data.unshift({ name: 'All Clients', id: '' });
      setAllClientsData(e?.data?.data);
    });
  }, [dataEmployeeCurrent, dataClientCurrent, dataStatusCurrent, isDesc]);

  useEffect(() => {
    fetchData();
  }, [fetchData, newLoad]);

  const onPaginationClick = useCallback(
    id => {
      requestHttp.getRequests(id, dataEmployeeCurrent, dataClientCurrent, dataStatusCurrent, isDesc).then(e => {
        setRequestListDataPage(e?.data);
        setRequestList(e?.data?.data);
      });
    },
    [dataEmployeeCurrent, dataClientCurrent, dataStatusCurrent, isDesc],
  );

  const onClickRemove = useCallback(id => {
    setIsOpen(true);
    setCompanyFormId(id);
  }, []);

  const onClickYes = useCallback(id => {
    requestHttp.deleteRequest(id).then(e => {
      if (e.status === 200) setNewLoad(prev => !prev);
    });
    setIsOpen(false);
  }, []);

  const onClickEmployeeList = useCallback(list => {
    if (list?.array?.length > 1) {
      setIsOpenEmployee(true);
      setList(list);
    }
  }, []);

  const onClickAssignEmployees = useCallback(
    (id, title, employees, status) => {
      status
        ? navigate(`/Admin/EditRequestToEmployees/${id}`, { state: { title, employees } })
        : navigate(`/Admin/AssignRequestToEmployees/${id}`, { state: { title, employees } });
    },
    [navigate],
  );

  const onClickEdit = useCallback(
    id => {
      navigate(`/Admin/EditForm/${id}`);
    },
    [navigate],
  );

  const onClickAccept = useCallback(
    id => {
      navigate(`/Admin/AcceptRequestForm/${id}`);
    },
    [navigate],
  );

  const afterSubmit = useCallback(() => {
    notifySuccess('Request status successfully Changed');
  }, []);

  const onClickUnAccept = useCallback(
    id => {
      requestHttp
        .unAcceptRequestFormAdmin(id)
        .then(e => {
          if (e.status === 200) afterSubmit();
        })
        .then(() => setNewLoad(prev => !prev));
    },
    [afterSubmit],
  );

  return (
    <div className="w-full sm:p-8 p-2">
      <CompanyFormModal
        open={isOpen}
        setOpen={setIsOpen}
        onClickYes={onClickYes}
        companyFormId={companyFormId}
        text="Are you sure you want to delete this Request ?"
      />
      <InfoListModal
        open={isOpenEmployee}
        setOpen={setIsOpenEmployee}
        List={List}
        title={'For which employees is this Request'}
      />
      <TitleBlockRequest
        src={'/InvoicesIcon.svg'}
        textItem={'Requests'}
        className="text-base font-bold"
        setDataEmployeeCurrent={setDataEmployeeCurrent}
        AllEmployeesData={AllEmployeesData}
        AllClientsData={AllClientsData}
        setDataClientCurrent={setDataClientCurrent}
        statuses={statuses}
        setDataStatusCurrent={setDataStatusCurrent}
        button={true}
      />
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className="w-full overflow-x-auto">
          <div className="inline-block min-w-full">
            <TableClients
              maxHeight="500px"
              tbody={requestList ?? []}
              columns={columnsRequest(
                onClickEmployeeList,
                onClickEdit,
                onClickRemove,
                onClickAssignEmployees,
                onClickAccept,
                onClickUnAccept,
                setIsDesc,
                isDesc,
              )}
            />
          </div>
        </div>
        <Pagination onPaginationClick={onPaginationClick} paginationData={requestListPage?.links} />
      </div>
      <ToastContainer />
    </div>
  );
}
