import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { createDateFromArray } from '../../../utils/utils';

export default function FullCalendarAdminUnbillableHours({ transformedArray, arr, calendarRef }) {
  return (
    <FullCalendar
      height={700}
      width={500}
      ref={calendarRef}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialDate={createDateFromArray(arr)}
      initialView="timeGridWeek"
      initialEvents={transformedArray}
    />
  );
}
