export const AssignRequestToEmployeeDefaultValue = () => {
  return {
    employee_ids: [
      {
        service_id: '',
        position_id: '',
        id: '',
      },
    ],
  };
};

export default AssignRequestToEmployeeDefaultValue;
