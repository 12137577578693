import { useEffect, useMemo, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RegenerateCustomLogDefaultValue } from './RegenerateCustomLogDefaultValue';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notifySuccess } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClientsSlice, loadParentsSlice } from '../../redux/clientsSlice/clientsSlice';
import Select from '../../components/Select/Select';
import DatePicker from 'react-multi-date-picker';
import ClientHttp from '../../http/clientsHttp/clientsHttp';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import AdminServiceLogHttp from '../../http/adminServiceLogHttp/adminServiceLogHttp';
import { columnsCreatCustomLogAdmin } from './helpers/RegenerateCustomLogUtils';

export default function RegenerateCustomLog() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [dateRange, setDateRange] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const params = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    defaultValues: useMemo(() => RegenerateCustomLogDefaultValue(data), [data]),
    // resolver: yupResolver(RequestErrorRegenerateCustomLog()),
  });
  const navigate = useNavigate();
  const [isError, setIsError] = useState();
  const [disabledButton, setDisabledButton] = useState(false);
  const [allParentsSelectData, setAllParentsSelectData] = useState();
  const [allParentsClients, setAllParentsClients] = useState([]);

  const clientsData = useSelector(state => state?.clients?.storeAllClientsData);
  const allParents = useSelector(state => state?.clients?.parentClientSliceDataAll?.data);

  const [currentParentId, setCurrentParentId] = useState('');
  const [currentSchoolId, setCurrentSchoolId] = useState();

  const [tbodyData, setTbodyData] = useState([]);
  const [showtbodyData, setShowTbodyData] = useState([]);

  const afterSubmit = () => {
    notifySuccess('Custom Log Successfully Created');
    setTimeout(() => {
      navigate('/Admin/ServiceLog');
    }, 2000);
  };
  
  const onSubmit = data => {
    setIsLoading(true);
    if (dateRange && Array.isArray(dateRange) && dateRange.length === 2) {
      data['dateRange'] = {
        startDate: data['dateRange'][0].format('MM/DD/YYYY'),
        endDate: data['dateRange'][1].format('MM/DD/YYYY'),
      };
    }
    const allids = Object.keys(data)
      .map((item, i) => item)
      .filter(elem => Number(elem) === +elem);
    let selectedIds = [];
    for (let i = 0; i < allids.length; i++) {
      data?.[allids?.[i]] && selectedIds.push({ id: allids?.[i] });
    }

    const sendedData = {
      school_id: data?.school_id || '',
      // selectedIds: selectedIds,
      group_school_id: data?.group_school_id || '',
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    };
    AdminServiceLogHttp.EditCustomLog(params?.id, sendedData)
      .then(() => afterSubmit())
      .catch(err => {
        setIsError(err?.response?.data?.message);
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false)
      });
  };

  const onClickCancel = () => {
    navigate('/Admin/ServiceLog');
  };

  const handleDateChange = value => {
    const date = {
      startDate: value?.[0]?.format('MM/DD/YYYY'),
      endDate: value?.[1]?.format('MM/DD/YYYY'),
    };
    setDateRange(date);
  };

  const onClickDelete = () => {
    setIsOpen(true);
  };

  const onClickYes = id => {
    AdminServiceLogHttp.deleteCustom(id).then(() => afterDelete());
    setIsOpen(false);
  };

  const afterDelete = () => {
    notifySuccess('Costum Log successfully Deleted');
    setTimeout(() => {
      navigate('/Admin/ServiceLog');
    }, 2000);
  };

  useEffect(() => {
    const dataAllParents = allParents?.map(item => ({
      name: item?.['Client Name'],
      id: item?.id,
    }));
    dataAllParents?.unshift({ id: '', name: 'Not Selected' });
    setAllParentsSelectData(dataAllParents);
  }, [allParents]);

  useEffect(() => {
    currentParentId &&
      ClientHttp.getClientsByParent(currentParentId).then(e => {
        const data = e?.data?.data;
        data?.unshift({ id: '', name: 'Not Selected' });
        setAllParentsClients(data);
      });
  }, [currentParentId]);

  useEffect(() => {
    reset(RegenerateCustomLogDefaultValue(data));
    dispatch(loadParentsSlice());
    dispatch(getAllClientsSlice());
    setCurrentSchoolId(data?.school_id);
    const newdateStart = data?.startDate?.split('-');
    const start = `${newdateStart?.[1]}/${newdateStart?.[2]}/${newdateStart?.[0]}`;
    const newdateEnd = data?.endDate?.split('-');
    const end = `${newdateEnd?.[1]}/${newdateEnd?.[2]}/${newdateEnd?.[0]}`;
    const date = {
      startDate: start,
      endDate: end,
    };
    setDateRange(date);
    setValue(`${data?.employee?.id}`, true);
  }, [data]);

  useEffect(() => {
    AdminServiceLogHttp.getCustomLog(params?.id).then(e => setData(e?.data?.data));
  }, []);

  useEffect(() => {
    (currentParentId || currentSchoolId) && dateRange?.startDate && dateRange?.endDate
      ? AdminServiceLogHttp.getEmployeesCustomBySchoolId(currentSchoolId, dateRange, currentParentId).then(e =>
          setTbodyData(e?.data?.data),
        )
      : setTbodyData([]);
  }, [currentSchoolId, dateRange, currentParentId]);

  useEffect(() => {
    const a = tbodyData?.some(e => e.employee_id == data?.employee?.id);
    if (a) {
      setDisabledButton(false);
      const filteredData = tbodyData.filter(item => item.employee_id === data?.employee?.id);
      setShowTbodyData(filteredData);
    } else {
      setDisabledButton(true);
      setShowTbodyData([]);
    }
  }, [tbodyData]);

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <CompanyFormModal
          open={isOpen}
          setOpen={setIsOpen}
          onClickYes={onClickYes}
          companyFormId={params?.id}
          text="Are you sure you want to delete this Custom Log"
        />
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title={`Regenerate Custom Log For ${data?.employee?.employee}`}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          {allParentsSelectData && (
            <div className="w-full bg-white sm:px-7 px-2">
              <Select
                register={register}
                title={'Select Parent Company'}
                selectData={allParentsSelectData}
                selectClass="flex-col gap-2"
                // onChange={onChangeParentSchoolId}
                disable={true}
                name={'group_school_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.group_school_id?.message}</p>}
            </div>
          )}

          <div className="py-4 sm:px-7 px-2 w-full flex gap-6">
            {currentParentId == '' ? (
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Select Client Company'}
                  selectData={clientsData}
                  selectClass="flex-col gap-2"
                  name={'school_id'}
                  disable={true}
                  // onChange={onChangeSchoolId}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_id?.message}</p>}
              </div>
            ) : (
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Select Client Company'}
                  selectData={allParentsClients}
                  selectClass="flex-col gap-2"
                  name={'school_id'}
                  disable={true}
                  // onChange={onChangeSchoolId}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_id?.message}</p>}
              </div>
            )}
          </div>
          <div className="py-4 sm:px-7 px-2 w-full flex flex-col gap-2">
            <p className="text-[#60657B] opacity-50 text-xs">{'Date Range'}</p>
            <div className="w-full [&>div>input]:w-full [&>div]:w-full [&>div>input]:h-[38px] [&>div>input]:border-none [&>div>input]:focus:border-hidden [&>div>input]:hover:shadow-none [&>div>input]:focus:outline-none [&>div>input]:hover:border-none border rounded [&>div>input]:focus:shadow-none">
              <DatePicker
                range
                format="MM/DD/YYYY"
                value={[dateRange?.startDate, dateRange?.endDate]}
                placeholder="MM/DD/YYYY - MM/DD/YYYY"
                onChange={handleDateChange}
              />
              {errors?.startDate?.message ||
                (errors?.endDate?.message && <p className="mb-0 text-sm text-amber-800">Date Range is Required</p>)}
            </div>
          </div>
          <div>
            <div className="px-4 py-1 sm:m-4 mx-4 my-6 bg-white rounded-xl">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full">
                  <TableEmployeesAdmin
                    tbody={showtbodyData ?? []}
                    columns={columnsCreatCustomLogAdmin(register)}
                    maxHeight="500px"
                  />
                </div>
              </div>
            </div>
          </div>
          {isError && <p className="text-base font-medium text-[red] py-4 sm:px-7 px-2">{isError}</p>}
          {disabledButton && (
            <p className="text-base font-medium text-[red] py-4 sm:px-7 px-2">{`You cant Regenerate Invoice For ${data?.employee?.employee} with this Date`}</p>
          )}
          <div>
            <CancleAdd
              disabled={isLoading || disabledButton}
              buttonText={`Regenerate Custom Log`}
              onClickCancel={onClickCancel}
              isDelete={true}
              onClickDelete={onClickDelete}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
