import { $authHost,$authHosttUpload } from "..";

export default class companyFormsHttp {
    static getAllCompanyForms(id) {
        return $authHost.get(`/admin/companyForm?page=${id ? id : ""}`)
    };
    static getAllCompanyFormsClients(id) {
        return $authHost.get(`/admin/getClientsCompanyForm?page=${id ? id : ""}`)
    };
    static getAllCompanyFormsEmployee(id) {
        return $authHost.get(`/employee/companyForms?page=${id ? id : ""}`)
    };
    static getAllCompanyFormsClient(id) {
        return $authHost.get(`/client/companyForms?page=${id ? id : ""}`)
    };
    static createCompanyForms(data) {
        return $authHosttUpload.post(`/admin/companyForm`, data)
    };
    static createCompanyFormsClient(data) {
        return $authHosttUpload.post(`/admin/companyFormSchool`, data)
    };
    static deleteCompanyForms(id) {
        return $authHost.post(`/admin/companyForm/${id}`)
    };
    static deleteCompanyFormsClients(id) {
        return $authHost.post(`/admin/delete/companyFormSchool/${id}`)
    };
    static getCompanyForms(id) {
        return $authHost.get(`/admin/companyForm/${id}`)
    };
    static getCompanyFormsClient(id) {
        return $authHost.get(`/admin/companyFormSchool/${id}`)
    };
    static EditCompanyForms(id, data) {
        return $authHosttUpload.post(`/admin/companyForm/${id}`, data)
    };
    static EditCompanyFormsClient(id, data) {
        return $authHosttUpload.post(`/admin/companyFormSchool/${id}`, data)
    };
    static getSchoolsForCompanyForm(){
        return $authHost.get(`/admin/getSchoolsForCompanyForm`);
    }
    static getAddSchoolsForCompanyForm(){
        return $authHost.get(`/admin/getAddSchoolsForCompanyForm`);
    }
    static getGroupSchoolsForCompanyForm(){
        return $authHost.get(`/admin/getGroupSchoolsForCompanyForm`);
    }
} 
