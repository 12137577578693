import { Fragment, useEffect,} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useForm } from 'react-hook-form'
import CancleAdd from '../CancleAdd/CancleAdd'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";
import InputLabel from '../InputLabel/InputLabel'
import CurrencyInputNew from '../CurrencyInputNew/CurrencyInputNew'
import { today } from '../../utils/utils'




export default function PartialPaidModal({ open, setOpen, onClickPartial,dataPartial }) {

    const onClickClose = () => {
        setValue("comment", "")
        setValue("date", "")
        setOpen(false)
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        // defaultValues: useMemo(() => defaultValue(activityId), [activityId]),
        // defaultValues: 
        resolver: yupResolver(yup.object().shape({
            date: yup.string().required("This field is required"),
            amount: yup.string().required("This field is required"),
        })),
    });


    const onSubmit = (data) => {
        onClickPartial(dataPartial?.id,data,dataPartial?.page)
    };

    useEffect(() => {
        reset();
    }, []);


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[50rem] sm:p-6 ">
                                <div className="absolute right-0 top-0 pr-4 pt-4">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={onClickClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="sm:flex-col sm:items-center justify-center">
                                    <div className="mt-3 text-center ">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {`Partial Paid`}
                                        </Dialog.Title>
                                    </div>
                                    <div className="sm:px-8 px-2">
                                        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                                        <div className="grid md:grid-cols-2 md:gap-6 gap-2 items-end">
                                        <div className="w-full">
                                            <InputLabel
                                                classNameInput="flex flex-col"
                                                classNameLabel={"text-[#60657B] text-xs font-normal p-1 opacity-50"}
                                                className="w-full border p-[7px]"
                                                type="date"
                                                labelTitle={"Date"}
                                                max={today}
                                                register={register}
                                                name={`date`} />

                                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.date?.message}</p>}
                                        </div>
                                        <div className="">
                                            <CurrencyInputNew classNameLabel="text-[#60657B]  text-xs font-normal p-1 opacity-50 mb-2" className={"w-full border p-[7px] outline-none rounded border p-1"} name={`amount`} setValue={setValue} placeholder="$0.00" labelTitle={"Amount"} />
                                        {errors && <p className="mb-0 text-sm text-amber-800">{errors?.amount?.message}</p>}
                                        </div>
                                        </div>
                                            <div className=''>
                                                <CancleAdd buttonText={`Save`} onClickCancel={onClickClose} />
                                            </div>
                                        </form>
                                    </div>
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
