import { useCallback, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import Pagination from '../../components/Pagination/Pagination';
import InvoiceModal from '../../components/Modal/InvoiceModal';
import TableClients from '../../components/DataTable/TableClients';
import EmployeesHttp from '../../http/employeesHttp/employeesHttp';
import ClientHttp from '../../http/clientsHttp/clientsHttp';
import { afterMove, afterSend, afterUnMove, columnsRequestServiceLogsAdmin } from './helpers/RequestServiceLogsUtils';
import InfoListModal from '../../components/Modal/InfoListModal';

import 'react-toastify/dist/ReactToastify.css';
import TitleBlockRequestServiceLogs from '../../components/TitleBlockRequestServiceLogs/TitleBlockRequestServiceLogs';
import requestHttp from '../../http/requestHttp/requestHttp';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';

export default function RequestServiceLogs() {
  const [dataId, setDateId] = useState('');
  const [data, setData] = useState([]);
  const [dataPage, setDataPage] = useState([]);
  const [isSend, setIsSend] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [currentPage, setCurrentPage] = useState('');
  const [AllEmployeesData, setAllEmployeesData] = useState([]);
  const [AllClientsData, setAllClientsData] = useState([]);
  const [dataEmployeeCurrent, setDataEmployeeCurrent] = useState('');
  const [dataClientCurrent, setDataClientCurrent] = useState('');
  const [List, setList] = useState();
  const [isOpenList, setIsOpenList] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteConfirmation = useCallback(formId => {
    setIsDeleteModalOpen(true);
    setSelectedFormId(formId);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    try {
      const response = await requestHttp.deleteRequestServiceLog(selectedFormId);
      if (response.status === 200) {
        setIsLoading(prev => !prev);
      }
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting request log:', error);
    }
  }, [selectedFormId]);

  const fetchData = async (id, e_id, is_send, page) => {
    try {
      const response = await requestHttp.getRequestPdf(id);
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      setFileUrl({ url, filename: `RequestLog_${e_id}`, id: e_id, is_send: is_send, page: page });
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  const onClickEmployeeList = list => {
    if (list?.length > 1) {
      setIsOpenList(true);
      setList(list);
    }
  };

  const onClickResend = (id, page) => {
    requestHttp
      .sendRequestLog(id)
      .then(e => afterSend())
      .then(() => setCurrentPage(page))
      .then(() => setIsSend(!isSend));
  };
  const onClickMove = (id, page) => {
    requestHttp
      .moveRequestLog(id)
      .then(() => afterMove())
      .then(() => setCurrentPage(page))
      .then(() => setIsSend(!isSend));
  };
  const onClickUnMove = (id, page) => {
    requestHttp
      .unMoveRequestLog(id)
      .then(() => afterUnMove())
      .then(() => setCurrentPage(page))
      .then(() => setIsSend(!isSend));
  };

  const onClickSend = (id, page) => {
    requestHttp
      .sendRequestLog(id)
      .then(() => afterSend())
      .then(() => setCurrentPage(page))
      .then(() => setIsOpen(false))
      .then(() => setIsSend(!isSend));
  };

  // const onClickRegenerate = id => {
  //   naviagete(`/Admin/RegenerateInvoice/${id}`);
  // };

  // const onClickEdit = id => {
  //   naviagete(`/Admin/EditInvoice/${id}`);
  // };

  const onClickView = (e, e_id, is_send, page) => {
    setIsOpen(true);
    fetchData(e, e_id, is_send, page);
  };

  const onPaginationClick = id => {
    requestHttp.getRequestLogsPaginationList(dataId, dataEmployeeCurrent, id, dataClientCurrent).then(e => {
      setDataPage(e?.data);
      setData(e?.data?.data);
    });
    ClientHttp.getAllClientss().then(e => {
      e?.data?.data.unshift({ name: 'All Clients', id: '' });
      const clients = e?.data?.data;
      setAllClientsData(clients);
    });
    EmployeesHttp.employeesAllLinks().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          id: item?.id || item?.[0]?.employee_id,
          name: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.id)) {
            uniqueIdsSet.add(item.id);
            result.push(item);
          }
          return result;
        }, []);
      employesList.unshift({ id: '', name: 'All Employees' });
      employesList?.length
        ? setAllEmployeesData(employesList)
        : setAllEmployeesData([{ id: '', name: 'All Employees' }]);
    });
  };

  useEffect(() => {
    EmployeesHttp.employeesAllLinks().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          id: item?.id || item?.[0]?.employee_id,
          name: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.id)) {
            uniqueIdsSet.add(item.id);
            result.push(item);
          }
          return result;
        }, []);
      employesList.unshift({ id: '', name: 'All Employees' });
      employesList?.length
        ? setAllEmployeesData(employesList)
        : setAllEmployeesData([{ id: '', name: 'All Employees' }]);
    });
    ClientHttp.getAllClientss().then(e => {
      e?.data?.data.unshift({ name: 'All Clients', id: '' });
      const clients = e?.data?.data;
      setAllClientsData(clients);
    });
    requestHttp.getRequestLogsPaginationList(dataId, dataEmployeeCurrent, currentPage, dataClientCurrent).then(e => {
      setDataPage(e?.data);
      setData(e?.data?.data);
    });
  }, [dataId, isSend, dataEmployeeCurrent, dataClientCurrent, isLoading]);

  return (
    <div className="w-full sm:p-8 p-2 overflow-hidden">
      <CompanyFormModal
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        onClickYes={handleConfirmDelete}
        companyFormId={selectedFormId}
        text={'Are you sure you want to delete this Request Service Log ?'}
      />

      <InfoListModal
        open={isOpenList}
        setOpen={setIsOpenList}
        List={List}
        title={'For which employees is this Request Logs'}
      />
      <InvoiceModal open={isOpen} setOpen={setIsOpen} fileUrl={fileUrl} onClickSend={onClickSend} sendable={true} />
      <TitleBlockRequestServiceLogs
        src={'/InvoicesIcon.svg'}
        textItem={'Request Service Logs'}
        className="text-base font-bold"
        setDateId={setDateId}
        setDataEmployeeCurrent={setDataEmployeeCurrent}
        AllEmployeesData={AllEmployeesData}
        setCurrentPage={setCurrentPage}
        AllClientsData={AllClientsData}
        setDataClientCurrent={setDataClientCurrent}
      />
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className="w-full overflow-x-auto">
          <div className="inline-block min-w-[1200px]">
            <TableClients
              tbody={data ?? []}
              maxHeight="600px"
              columns={columnsRequestServiceLogsAdmin(
                onClickView,
                onClickResend,
                dataPage,
                onClickMove,
                onClickUnMove,
                onClickEmployeeList,
                handleDeleteConfirmation,
              )}
            />
          </div>
        </div>
        <Pagination onPaginationClick={onPaginationClick} paginationData={dataPage.links} />
      </div>
      <ToastContainer />
    </div>
  );
}
