import Button from '../../components/Button/Button';
import Pagination from '../../components/Pagination/Pagination';
import { useEffect, useState } from 'react';
import AdminServiceLogHttp from '../../http/adminServiceLogHttp/adminServiceLogHttp';
import { notifySuccess } from '../../utils/utils';
import { ToastContainer } from 'react-toastify';
import EmployeesHttp from '../../http/employeesHttp/employeesHttp';
import TitleBlockServiceLog from '../../components/TitleBlockServiceLog/TitleBlockServiceLog';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import { useLocation, useNavigate } from 'react-router-dom';
import TableClients from '../../components/DataTable/TableClients';
import TitleBlockCustomLogs from '../../components/TitleBlockCustomLogs/TitleBlockCustomLogs';
import InvoiceModal from '../../components/Modal/InvoiceModal';
import ServiceLogModal from '../../components/Modal/ServiceLogModal';
import ServiceLogModalShow from '../../components/Modal/ServiceLogModalShow';
import ClientHttp from '../../http/clientsHttp/clientsHttp';
import {
  columnsCusomServiceLogAdmin,
  columnsServiceLogAdmin,
  columnsServiceLogViewDataAdmin,
  statuses,
} from './helpers/ServiceLogUtils';
import CustomLogCreateModal from '../../components/Modal/CustomLogCreateModal';
import InfoListModal from '../../components/Modal/InfoListModal';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '../../components/Modal/Modal';

export default function ServiceLog() {
  const [data, setData] = useState([]);
  const [dataCustom, setDataCustom] = useState([]);
  const [dataPage, setDataPage] = useState([]);
  const [currentPage, setCurrentPage] = useState('');
  const [dataPageCustom, setDataPageCustom] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [dateId, setDateId] = useState(null);
  const [dataEmployeeCurrent, setDataEmployeeCurrent] = useState('');
  const [dataStatusCurrent, setDataStatusCurrent] = useState('');
  const [AllEmployeesData, setAllEmployeesData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [approve, setApprove] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileUrlCustom, setFileUrlCustom] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenComment, setIsOpenComment] = useState(false);
  const [isOpenCommentShow, setIsOpenCommentShow] = useState(false);
  const [activityId, setActivityId] = useState(false);
  const [companyFormId, setCompanyFormId] = useState();
  const [activeTab, setActiveTap] = useState(1);
  const [AllClientData, setAllClientData] = useState([]);
  const [dataClientCurrent, setDataClientCurrent] = useState('');
  const [dataPublishCurrent, setDataPublishCurrent] = useState('');
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenSendModalCustom, setIsOpenSendModalCustom] = useState(false);
  const [isOpeCreateModal, setIsOpeCreateModal] = useState(false);
  const [isOpenPublishModalCustom, setIsOpenPublishModalCustom] = useState(false);
  const [isOpenEmployee, setIsOpenEmployee] = useState(false);
  const [List, setList] = useState();
  const [title, setTitle] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { state } = useLocation();
  const navigate = useNavigate();

  const changeTab = bool => {
    setActiveTap(bool);
    setCurrentPage(1);
    setDateRange(null);
    setDateId(null);
    setDataEmployeeCurrent('');
    setDataClientCurrent('');
    setDataPublishCurrent('');
    setDataStatusCurrent('');
  };

  const onClickComment = id => {
    setIsOpenComment(true);
    setActivityId(id);
  };
  const onClickCommentShow = obj => {
    setIsOpenCommentShow(true);
    setActivityId(obj);
  };

  const fetchDataCusom = async (id, client_name, employee_name, week_of) => {
    try {
      const response = await AdminServiceLogHttp.getCustomPdf(id);
      const blob = new Blob([response?.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const filename = `${employee_name}_date of ${week_of}_${client_name}`.replace(/\./g, ' ');
      setFileUrlCustom({ url, filename: filename, id: id });
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  const afterRequest = title => {
    notifySuccess(title);
  };
  const fetchData = async (id, employeId, clientId, client_name, employee_name, week_of) => {
    try {
      const response = await AdminServiceLogHttp.serviceLogWeekAdminPDFLinks(id, employeId, clientId);
      const blob = new Blob([response?.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const filename = `${employee_name}_date of ${week_of}_${client_name}`.replace(/\./g, ' ');
      setFileUrl({ url, filename: filename, client_name });
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };
  const onClickView = (id, employeId, status, clientId, client_name, employee_name, week_of) => {
    AdminServiceLogHttp.serviceLogWeekAdminLinks(id, employeId).then(e => setViewData(e?.data?.data));
    status == '1' && fetchData(id, employeId, clientId, client_name, employee_name, week_of);
  };
  const onClickViewCusom = (id, client_name, employee_name, week_of) => {
    setIsOpenView(true);
    fetchDataCusom(id, client_name, employee_name, week_of);
  };
  const onClickSend = (id, employeId, clientId) => {
    AdminServiceLogHttp.serviceLogWeekAdminSendLinks(id, employeId, clientId).then(() =>
      afterRequest('Message successfully sent'),
    );
  };

  const onClickSendCustom = (id, page) => {
    AdminServiceLogHttp.customLogWeekAdminSendLinks(id)
      .then(() => setCurrentPage(page))
      .then(() => setApprove(prev => !prev))
      .then(() => afterRequest('Message successfully sent'));
  };
  const onClickMove = (id, employeId, clientId, page) => {
    AdminServiceLogHttp.serviceLogWeekAdminMoveLinks(id, employeId, clientId)
      .then(() => setCurrentPage(page))
      .then(() => setApprove(prev => !prev))
      .then(() => afterRequest('Service Log successfully Published'));
  };
  const onClickUnMove = (id, employeId, clientId, page) => {
    AdminServiceLogHttp.serviceLogWeekAdminUnMoveLinks(id, employeId, clientId)
      .then(() => setCurrentPage(page))
      .then(() => setApprove(prev => !prev))
      .then(() => afterRequest('Service Log successfully Unpublished'));
  };
  const onClickMoveCustom = (id, page) => {
    AdminServiceLogHttp.serviceLogWeekAdminMoveLinksCustom(id)
      .then(() => setCurrentPage(page))
      .then(() => setApprove(prev => !prev))
      .then(() => afterRequest('Service Log successfully Published'));
  };
  const onClickUnMoveCustom = (id, page) => {
    AdminServiceLogHttp.serviceLogWeekAdminUnMoveLinksCustom(id)
      .then(() => setCurrentPage(page))
      .then(() => setApprove(prev => !prev))
      .then(() => afterRequest('Service Log successfully Unpublished'));
  };
  const onClickRemind = (id, employeId) => {
    setIsLoading(true);
    AdminServiceLogHttp.serviceLogRemaindAdminLinks(id, employeId)
      .then(() => afterRequest('Message successfully sent'))
      .catch(err => {
        console.log(err, 'err');
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const onClickYes = (id, employeId, page) => {
    AdminServiceLogHttp.serviceLogDeleteAdminLinks(id, employeId)
      .then(() => setCurrentPage(page))
      .then(() => setApprove(prev => !prev))
      .then(() => afterRequest('Service Log successfully Deleted'));
    setIsOpen(false);
  };
  const onClickYesReturn = (id, employeId, page) => {
    AdminServiceLogHttp.serviceLogReturnAdminLinks(id, employeId)
      .then(() => setCurrentPage(page))
      .then(() => setApprove(prev => !prev))
      .then(() => afterRequest('Service Log successfully Returned'));
    setIsOpen(false);
  };
  const onClickYesCustom = (id, page) => {
    AdminServiceLogHttp.deleteCustom(id)
      .then(() => setCurrentPage(page))
      .then(() => setApprove(prev => !prev))
      .then(() => afterRequest('Custom Service Log successfully Deleted'));
    setIsOpen(false);
  };
  const onClickDelete = (id, employeId, page, week_status) => {
    setIsOpen(true);
    setCompanyFormId({ id: id, employeId: employeId, page: page, week_status: week_status });
  };
  const onClickDeleteCustom = (id, page) => {
    setIsOpen(true);
    setCompanyFormId({ id: id, page: page });
  };
  const onClickDiscard = (id, employeId) => {
    AdminServiceLogHttp.serviceLogDiscardAdminLinks(id, employeId)
      .then(() => setViewData([]))
      .then(() => afterRequest('Service Log successfully Discarded'));
  };
  const onClickDisApprove = (id, employeId, page) => {
    AdminServiceLogHttp.serviceLogDisApporveAdminLinks(id, employeId)
      .then(() => setCurrentPage(page))
      .then(() => setApprove(prev => !prev))
      .then(() => setViewData([]))
      .then(() => afterRequest('Service Log successfully Reverse Approval'));
  };
  const onClickApprove = (id, employeId, page) => {
    AdminServiceLogHttp.serviceLogApporveAdminLinks(id, employeId)
      .then(() => setCurrentPage(page))
      .then(() => setApprove(prev => !prev))
      .then(() => setViewData([]))
      .then(() => afterRequest('Service Log successfully Aprroved'));
  };
  const onClickRegenerate = id => {
    navigate(`/Admin/RegenerateCustomLog/${id}`);
  };
  const onClickEditCustom = id => {
    navigate(`/Admin/EditServiceLogCustom/${id}`);
  };
  const onPaginationClick = id => {
    if (activeTab) {
      AdminServiceLogHttp.serviceLogDataLinks(
        dateRange,
        dateId,
        dataEmployeeCurrent,
        dataStatusCurrent,
        id,
        dataClientCurrent,
        dataPublishCurrent,
      ).then(e => {
        setDataPage(e?.data);
        const flatArray = [].concat(...e?.data?.data);
        setData(flatArray);
      });
      ClientHttp.getAllClientss().then(e => {
        e?.data?.data.unshift({ name: 'All Clients', id: '' });
        const clients = e?.data?.data;
        setAllClientData(clients);
      });
    } else {
      AdminServiceLogHttp.customServiceLogDataLinks(
        dateRange,
        dateId,
        dataEmployeeCurrent,
        id,
        dataClientCurrent,
        dataPublishCurrent,
      ).then(e => {
        setDataPageCustom(e?.data);
        const flatArray = [].concat(...e?.data?.data);
        setDataCustom(flatArray);
      });
    }
  };

  const onClickEmployeeList = (list, title_) => {
    if (list?.length > 1) {
      setIsOpenEmployee(true);
      setList(list);
      setTitle(title_);
    }
  };

  useEffect(() => {
    EmployeesHttp?.employeesAllLinks().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          id: item?.id || item?.[0]?.employee_id,
          name: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.id)) {
            uniqueIdsSet.add(item.id);
            result.push(item);
          }

          return result;
        }, []);
      employesList?.unshift({ id: '', name: 'All Employees' });
      employesList?.length
        ? setAllEmployeesData(employesList)
        : setAllEmployeesData([{ id: '', name: 'All Employees' }]);
    });
    if (activeTab) {
      AdminServiceLogHttp?.serviceLogDataLinks(
        dateRange,
        dateId,
        dataEmployeeCurrent,
        dataStatusCurrent,
        currentPage,
        dataClientCurrent,
        dataPublishCurrent,
      )
        .then(e => {
          setDataPage(e?.data);
          const flatArray = [].concat(...e?.data?.data);
          setData(flatArray);
        })
        .catch(err => console.log(err, 'errrr'));
      ClientHttp.getAllClientss().then(e => {
        e?.data?.data.unshift({ name: 'All Clients', id: '' });
        const clients = e?.data?.data;
        setAllClientData(clients);
      });
    } else {
      AdminServiceLogHttp?.customServiceLogDataLinks(
        dateRange,
        dateId,
        dataEmployeeCurrent,
        currentPage,
        dataClientCurrent,
        dataPublishCurrent,
      )
        .then(e => {
          setDataPageCustom(e?.data);
          const flatArray = [].concat(...e?.data?.data);
          setDataCustom(flatArray);
        })
        .catch(err => console.log(err, 'errrr'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateRange,
    dateId,
    dataEmployeeCurrent,
    dataStatusCurrent,
    approve,
    activeTab,
    dataClientCurrent,
    dataPublishCurrent,
  ]);

  useEffect(() => {
    state?.page && onPaginationClick(state?.page);
  }, [state]);

  return (
    <div className="sm:p-8 p-4 w-full overflow-hidden">
      <InfoListModal
        open={isOpenEmployee}
        setOpen={setIsOpenEmployee}
        List={List}
        title={title ?? 'For which employees is this Custom log'}
      />
      <CompanyFormModal
        open={isOpen}
        setOpen={setIsOpen}
        onClickYes={!!companyFormId?.week_status ? onClickYesReturn : onClickYes}
        companyFormId={companyFormId}
        isServiceLog={true}
        text={
          !activeTab
            ? 'Are you sure you want to delete Custom Service Log'
            : !!companyFormId?.week_status
            ? 'Are you sure you want to return this week'
            : 'Are you sure you want to delete this Week ?'
        }
        onClickYesCustom={!activeTab ? onClickYesCustom : false}
      />
      <InvoiceModal open={isOpenView} setOpen={setIsOpenView} fileUrl={fileUrlCustom} sendable={true} />
      <CustomLogCreateModal open={isOpenCreateModal} setOpen={setIsOpenCreateModal} />
      <Modal
        open={isOpeCreateModal}
        setOpen={setIsOpeCreateModal}
        navigateYes={`/Admin/AddServiceLog/${dataEmployeeCurrent}`}
        navigateNo={`/Admin/AddServiceLogRequest/${dataEmployeeCurrent}`}
        question="Choose Service Log Type"
        buttonOne="Regular Log"
        buttonTwo="Report Log"
      />
      <CustomLogCreateModal
        open={isOpenSendModalCustom}
        setOpen={setIsOpenSendModalCustom}
        navigateEmployee="/Admin/SendCustomLogsCase"
        navigateNonEmployee="/Admin/SendCustomLogs"
        question="Send Custom Log Per Assignment/Case"
      />
      <CustomLogCreateModal
        open={isOpenPublishModalCustom}
        setOpen={setIsOpenPublishModalCustom}
        navigateEmployee="/Admin/PublishCustomLogsCase"
        navigateNonEmployee="/Admin/PublishCustomLogs"
        question="Publish Custom Log Per Assignment/Case"
      />
      <ServiceLogModal
        open={isOpenComment}
        setOpen={setIsOpenComment}
        activityId={activityId}
        setViewData={setViewData}
      />
      <ServiceLogModalShow open={isOpenCommentShow} setOpen={setIsOpenCommentShow} activityId={activityId} />
      {activeTab ? (
        <>
          <TitleBlockServiceLog
            setDateRange={setDateRange}
            setDateId={setDateId}
            AllEmployeesData={AllEmployeesData}
            setDataEmployeeCurrent={setDataEmployeeCurrent}
            dataEmployeeCurrent={dataEmployeeCurrent}
            textItem="Servce Logs"
            className="sm:text-base text-xs font-semibold"
            setCurrentPage={setCurrentPage}
            setDataStatusCurrent={setDataStatusCurrent}
            statuses={statuses}
            AllClientsData={AllClientData}
            Parent={true}
            setDataClientCurrent={setDataClientCurrent}
            setDataPublishCurrent={setDataPublishCurrent}
            setIsOpeCreateModal={setIsOpeCreateModal}
            dataStatusCurrent={dataStatusCurrent}
          />
        </>
      ) : (
        <>
          <TitleBlockCustomLogs
            setDateRange={setDateRange}
            setDateId={setDateId}
            AllEmployeesData={AllEmployeesData}
            setDataEmployeeCurrent={setDataEmployeeCurrent}
            dataEmployeeCurrent={dataEmployeeCurrent}
            textItem="Custom Logs"
            className="sm:text-base text-xs font-semibold"
            setCurrentPage={setCurrentPage}
            AllClientsData={AllClientData}
            setDataClientCurrent={setDataClientCurrent}
            Parent={true}
            setDataPublishCurrent={setDataPublishCurrent}
            setIsOpenCreateModal={setIsOpenCreateModal}
            setIsOpenSendModalCustom={setIsOpenSendModalCustom}
            setIsOpenPublishModalCustom={setIsOpenPublishModalCustom}
          />
        </>
      )}
      <div>
        <div className="px-4 py-1 mt-4 bg-white rounded-xl">
          <div className="w-full flex flex-col mt-2">
            <div className="mt-2 w-full">
              <div className="flex sm:gap-4 gap-2">
                <div onClick={() => changeTab(true)} className={'w-1/2 cursor-pointer'}>
                  <p
                    className={
                      activeTab
                        ? 'text-lg md:text-xl font-semibold pl-4 flex gap-7'
                        : 'text-[#686973] text-lg md:text-xl  font-semibold pl-4 flex gap-7'
                    }>
                    {'Regular Service Logs'}
                  </p>
                  <div
                    className={
                      activeTab
                        ? 'bg-[#70ACFB] rounded  w-full h-[6px] mt-3'
                        : 'bg-[#B0CEF5] rounded w-full h-[6px] mt-3'
                    }></div>
                </div>
                <div onClick={() => changeTab(false)} className={'w-1/2 cursor-pointer'}>
                  <p
                    className={
                      !activeTab
                        ? 'text-lg md:text-xl font-semibold pl-4 flex gap-7'
                        : 'text-[#686973] text-lg md:text-xl font-semibold pl-4 flex gap-7'
                    }>
                    {'Custom Service Logs'}
                  </p>
                  <div
                    className={
                      !activeTab
                        ? 'bg-[#70ACFB] rounded  w-full h-[6px] mt-3'
                        : 'bg-[#B0CEF5] rounded w-full h-[6px] mt-3'
                    }></div>
                </div>
              </div>
            </div>
          </div>
          {activeTab ? (
            <>
              <div className="overflow-x-auto mt-8">
                <div className="inline-block min-w-[1200px]">
                  <TableEmployeesAdmin
                    tbody={data ?? []}
                    columns={columnsServiceLogAdmin(
                      onClickRemind,
                      onClickDelete,
                      dataPage,
                      navigate,
                      onClickView,
                      onClickSend,
                      onClickMove,
                      onClickUnMove,
                      isLoading
                    )}
                    maxHeight="500px"
                  />
                </div>
              </div>
              <div>
                <Pagination onPaginationClick={onPaginationClick} paginationData={dataPage?.links} />
              </div>
            </>
          ) : (
            <>
              <div className="overflow-x-auto mt-8">
                <div className="inline-block min-w-[1000px]">
                  <TableClients
                    tbody={dataCustom ?? []}
                    columns={columnsCusomServiceLogAdmin(
                      onClickViewCusom,
                      onClickMoveCustom,
                      dataPageCustom,
                      onClickUnMoveCustom,
                      onClickRegenerate,
                      onClickSendCustom,
                      onClickDeleteCustom,
                      onClickEmployeeList,
                      onClickEditCustom,
                    )}
                    maxHeight="500px"
                  />
                </div>
              </div>
              <div>
                <Pagination onPaginationClick={onPaginationClick} paginationData={dataPageCustom?.links} />
              </div>
            </>
          )}
        </div>
      </div>
      {activeTab && viewData.length != 0 && (
        <div className="px-4 py-1 mt-4 bg-white rounded-xl">
          <div id="download-div">
            <div className="flex justify-between">
              <p className="mt-10 text-xl font-medium text-[#2B2D38]">{`${viewData?.employee ?? ''}`}</p>
              <p className="mt-10 text-xl font-medium text-[#2B2D38]">{`Log ID: #${viewData?.id ?? ''}`}</p>
            </div>
            <div className="w-full overflow-x-auto mt-8">
              <div className="inline-block min-w-full">
                <TableClients
                  tbody={viewData?.days ?? []}
                  columns={columnsServiceLogViewDataAdmin(viewData, onClickComment, onClickCommentShow)}
                  maxHeight="700px"
                />
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div>
              <p className="text-base font-black text-center">{`Total: ${viewData?.total ?? ''}`}</p>
            </div>
            <div className="flex justify-end mb-5">
              <div className="flex-col gap-2 items-center">
                {viewData?.status == '1' && (
                  <div className="mb-5">
                    <Button
                      buttonText="Reverse Approval Service Log"
                      onClick={() =>
                        onClickDisApprove(viewData?.id, viewData?.employee_id, dataPage?.links?.current_page)
                      }
                      className="bg-[#F9423C] text-white hover:bg-[red]"
                      type="button"
                    />
                  </div>
                )}
                {fileUrl && viewData?.status == '1' && (
                  <div>
                    <a href={fileUrl?.url} download={fileUrl?.filename}>
                      Download PDF For {fileUrl?.client_name}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          {viewData?.status == '2' && (
            <div className="mb-5">
              <div className="flex justify-end">
                <Button
                  buttonText="Discard Service Log"
                  onClick={() => onClickDiscard(viewData?.id, viewData?.employee_id)}
                  className="bg-[#F9423C] text-white hover:bg-[red]"
                  type="button"
                />
                <Button
                  buttonText="Remind To Complete "
                  onClick={() => onClickRemind(viewData?.id, viewData?.employee_id)}
                  className="ml-5 bg-[#F9423C] text-white hover:bg-[red]"
                  type="button"
                />
                <Button
                  buttonText="Approve Service Log"
                  onClick={() => onClickApprove(viewData?.id, viewData?.employee_id, dataPage?.links?.current_page)}
                  className="ml-5 bg-[#31D78D] text-white"
                  type="button"
                />
              </div>
            </div>
          )}
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
