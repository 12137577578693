import { formatDate } from '../../Requests/helpers/RequestsUtils';

export const columnsMyRequestsEmployee = (onClickDetails, onClickComplete, onClickUnComplete) => [
  {
    accessorKey: 'Request',
    header: 'Request Name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['name']}</p>,
  },
  {
    accessorKey: 'Client',
    header: 'Clients', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.school ?? ''}</p>,
  },
  {
    accessorKey: 'completion_date',
    header: 'Completion Date', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{formatDate(row?.original?.completion_date)}</p>,
  },
  {
    accessorKey: 'hours',
    header: 'Hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.hours}</p>,
  },
  {
    accessorKey: 'status',
    header: 'Status', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['status']}</p>,
  },
  {
    accessorKey: 'action',
    header: 'Action',
    size: 220, //decrease the width of this column
    // maxSize: 250,
    Cell: ({ row }) => (
      <div className="flex gap-2 ">
        {onClickDetails && (
          <button
            onClick={() => onClickDetails(row?.original?.id)}
            className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
            type="button">
            Details
          </button>
        )}
        {onClickComplete && (
          <button
            onClick={() => onClickComplete(row?.original?.id)}
            disabled={
              row?.original?.status === 'Completed' ||
              row?.original?.status === 'Approved' ||
              row?.original?.has_logs === false
            }
            className={`text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm cursor-pointer
              ${
                row?.original?.status === 'In Process' && row?.original?.has_logs === true && `hover:text-indigo-900 `
              }   
              ${
                (row?.original?.status === 'Completed' ||
                  row?.original?.status === 'Approved' ||
                  row?.original?.has_logs === false) &&
                `text-[white] bg-[#A6ADC9] border-[#A6ADC9] cursor-not-allowed`
              } `}
            type="button">
            Complete
          </button>
        )}
        {onClickUnComplete && row?.original?.status === 'Completed' && (
          <button
            onClick={() => onClickUnComplete(row?.original?.id)}
            className={`text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm cursor-pointer
            hover:text-indigo-900 `}
            type="button">
            Reverse Status
          </button>
        )}
      </div>
    ),
  },
];

export const statuses = [
  { name: 'All Statuses', id: '' },
  { name: 'Open', id: 'Open' },
  { name: 'In Process', id: 'In Process' },
  { name: 'Completed', id: 'Completed' },
  { name: 'Approved', id: 'Approved' },
];