import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from '../../components/Select/Select';
import Title from '../../components/Title/Title';
import { useEffect, useMemo, useState } from 'react';
import InputLabel from '../../components/InputLabel/InputLabel';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import { notifySuccess } from '../../utils/utils';
import { ToastContainer } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import RequestErrorCreateCase from './RequestErrorCreateCase';
import 'react-toastify/dist/ReactToastify.css';
import CreateCaseDefaultValue from './CreateCaseDefaultValue';
import casesHttp from '../../http/casesHttp/casesHttp';
import StudentsFieldArray from './StudentsFieldArray';
import CasesField from './Cases/CasesField';

export default function CreateCase() {
  const [clientsData, setClientsData] = useState([]);
  const [clientsDataCurrent, setClientsDataCurrent] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    setError,
    getValues
  } = useForm({
    defaultValues: useMemo(() => CreateCaseDefaultValue(clientsData), [clientsData]),
    resolver: yupResolver(RequestErrorCreateCase()),
  });

  const {
    fields: CasesFields,
    append: CasesAppend,
    remove: CasesRemove,
  } = useFieldArray({
    control,
    name: 'cases',
  });

  const onChangeClientsData = (e) => {
    setClientsDataCurrent(e.target.value); // Update school_id
    setValue('school_id', e.target.value); // Set the form value for school_id
    getValues('cases').forEach((elem, index) => {
      setValue(`cases.${index}.service_id`, '');
      setValue(`cases.${index}.service_type_id`, '');
      setValue(`cases.${index}.employees`, []);
    });
  };

  const afterSubmit = () => {
    notifySuccess('Assignment/Cases successfully created');
    setTimeout(() => {
      navigate('/Admin/Cases');
    }, 2000);
  };
  
  const onSubmit = data => {
    setIsLoading(true);
    casesHttp.createCases(data)
      .then(afterSubmit)
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false)
      });
  };

  const onClickCancel = () => {
    navigate('/Admin/Cases');
  };

  useEffect(() => {
    casesHttp.getClientForCases().then(e => {
      setClientsData(e?.data?.data);
      setClientsDataCurrent(e?.data?.data?.[0]?.id);
    });
  }, []);

  useEffect(() => {
    reset(CreateCaseDefaultValue(clientsData));
  }, [clientsData]);

  return (
    <div className="sm:px-8 px-4 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={`Add Assignment/Case`} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-2 sm:gap-6 gap-2">
            <div className="w-full bg-white">
              <Select
                register={register}
                title={'Client Company'}
                selectData={clientsData}
                selectClass="flex-col gap-2"
                onChange={onChangeClientsData}
                name={'school_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_id?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <Select
                register={register}
                title={'Status'}
                selectData={[
                  { name: 'Active', id: 1 },
                  { name: 'Inactive', id: 0 },
                ]}
                selectClass="flex-col gap-2"
                name={'status'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.status?.message}</p>}
            </div>
          </div>
          <CasesField
              CasesFields={CasesFields}
              register={register}
              errors={errors}
              CasesAppend={CasesAppend}
              CasesRemove={CasesRemove}
              setValue={setValue}
              clientsDataCurrent={clientsDataCurrent}
            />
          <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-2 sm:gap-6 gap-2 mt-5">
            <div>
              <InputLabel
                labelTitle={'Teacher Name'}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'Teacher Name'}
                register={register}
                name={`teacher`}
                classNameLabel={'text-[#60657B] text-xs font-normal opacity-50'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.teacher?.message}</p>}
            </div>
            <StudentsFieldArray register={register} errors={errors} control={control}  />
          </div>
         
          <div className="mt-6">
            <CancleAdd buttonText={'Add'} onClickCancel={onClickCancel} disabled={isLoading} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
