import { $authHost } from '..';

export default class myRequestsEmployeeHttp {
  static employeeRequestsLinks(id, school_id, status) {
    return $authHost.get(`/employee/get_forms?page=${id ?? ''}&school_id=${school_id ?? ''}&status=${status ?? ''}`);
  }
  static employeeRequestFormFromClientLinks(id) {
    return $authHost.get(`/employee/get_forms/${id}`);
  }
  static employeeRequestClientsLinks() {
    return $authHost.get(`/employee/getSchoolsForRequests`);
  }
}
