import * as yup from 'yup';

export const RequestErrorAssignRequestToEmployee = () => {
  const formSchemaForSelectedIds = {
    // employee_ids: isEmpty ? yup.array().min(1).required('This field is required') : yup.array(),
    deadline: yup.string().required('This field is required'),
    service_id: yup.string().required('This field is required'),
    id: yup.string().required('This field is required'),
    position_id: yup.string().required('This field is required'),
    
  };

  return yup.object().shape({
    // employee_ids: isEmpty ? yup.array().min(1).required('This field is required') : yup.array(),
    // deadline: yup.string().required('This field is required'),
    // service_id: yup.string().required('This field is required'),
    employee_ids: yup.array().of(yup.object().shape(formSchemaForSelectedIds)),
  });
};

export default RequestErrorAssignRequestToEmployee;
