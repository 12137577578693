export const columnsCompanyFormsClient = () => [
  {
    accessorKey: 'title',
    header: 'Forms Title',
    // hishem avelacnem
    size: 130,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.title}</p>,
  },
  {
    accessorKey: 'file',
    header: 'Action',
    size: 150, //decrease the width of this column
    // maxSize: 250,
    Cell: ({ row }) => (
      <div className="flex gap-2 cursor-pointer">
        <a href={row?.original?.['file']} download="Logo.svg" target="_blank" rel="noreferrer" >
          Download PDF
        </a>
      </div>
    ),
  },
];
