import { useEffect, useMemo, useState, useCallback } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useNavigate } from 'react-router-dom';
import InputLabel from '../../components/InputLabel/InputLabel';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { loadserviceSlice } from '../../redux/serviceSlice/serviceSlice';
import AddServiceDefaultValue from './AddServiceDefaultValue';
import { yupResolver } from '@hookform/resolvers/yup';
import RequestErrorAddService from './RequestErrorAddService';
import UseFieldArrayPositions from '../../components/UseFieldArray/UseFieldArrayPositions';
import UseFieldArrayActivities from '../../components/UseFieldArray/UseFieldArrayActivities';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notifySuccess, onlyLetters } from '../../utils/utils';
import Service from '../../http/seviceHttp/seviceHttp';
import UseFieldArrayServiceTypes from '../../components/UseFieldArray/UseFieldArrayServiceTypes';

export default function AddService() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: useMemo(() => AddServiceDefaultValue(), []),
    resolver: yupResolver(RequestErrorAddService()),
  });

  const afterSubmit = useCallback(() => {
    notifySuccess('Service successfully created');
    setTimeout(() => navigate('/Admin/Services'), 2000);
  }, [navigate]);

  const onSubmit = useCallback(
    (data) => {
      setIsLoading(true);
      Service.storeServiceLinks(data)
        .then(afterSubmit)
        .catch((err) => {
          const errorFromBackend = Object.keys(err?.response?.data?.errors || {});
          errorFromBackend.forEach((item) =>
            setError(item, { type: 'custom', message: err?.response?.data?.errors[item] })
          );
          setIsLoading(false);
        });
    },
    [afterSubmit, setError]
  );

  const onClickCancel = useCallback(() => {
    navigate('/Admin/Services');
  }, [navigate]);

  useEffect(() => {
    reset(AddServiceDefaultValue());
    dispatch(loadserviceSlice());
  }, [dispatch, reset]);

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title="Add Service"
            className="border-b py-1"
            classNameTitle="text-2xl font-semibold font-['Poppins']"
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="py-4 sm:px-7 px-2 w-full">
            <div className="flex w-full gap-4">
              <div className="w-full">
                <InputLabel
                  labelTitle="Service Name"
                  register={register}
                  name="name"
                  onKeyPress={onlyLetters}
                  classNameLabel="text-[#60657B] opacity-50 text-xs"
                  classNameInput="flex flex-col flex-1"
                  type="text"
                  placeholder="Name"
                  className="w-full"
                />
                {errors?.name && <p className="mb-0 text-sm text-amber-800">{errors.name.message}</p>}
              </div>
              <div className="flex flex-1">
                <InputLabel
                  labelTitle="Active"
                  register={register}
                  name="status"
                  classNameLabel="order-2 text-[#83898C] text-base"
                  classNameInput="flex gap-4 items-center text-left mt-4"
                  type="checkbox"
                  className="order-1"
                />
              </div>
            </div>

            <div className="mt-2">
              <InputLabel
                labelTitle="Service Details"
                register={register}
                name="details"
                classNameLabel="text-[#60657B] opacity-50 text-xs"
                classNameInput="flex flex-col flex-1"
                type="text"
                placeholder="Details"
                className="w-full"
              />
              {errors?.details && <p className="mb-0 text-sm text-amber-800">{errors.details.message}</p>}
            </div>

            <div className="mt-2">
              <InputLabel
                labelTitle="Service Code"
                register={register}
                name="code"
                classNameLabel="text-[#60657B] opacity-50 text-xs"
                classNameInput="flex flex-col flex-1"
                type="text"
                placeholder="Service Code"
                className="w-full"
              />
              {errors?.code && <p className="mb-0 text-sm text-amber-800">{errors.code.message}</p>}
            </div>

            <div className="flex gap-4 mt-4 w-full flex-col sm:flex-row">
              <div className="flex-1 border border-[#8CBEFF] shadow-[0_2px_4px_rgba(141,141,141,0.25)] p-4 rounded-lg">
                <h1 className="text-base font-medium">Positions List</h1>
                <UseFieldArrayPositions control={control} register={register} errors={errors} />
              </div>
              <div className="flex-1 border border-[#8CBEFF] shadow-[0_2px_4px_rgba(141,141,141,0.25)] p-4 rounded-lg">
                <h1 className="text-base font-medium">Activities List</h1>
                <UseFieldArrayActivities control={control} register={register} errors={errors} />
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-4 mt-4">
              <div className="flex-1 border border-[#8CBEFF] shadow-[0_2px_4px_rgba(141,141,141,0.25)] p-4 rounded-lg">
                <h1 className="text-base font-medium">Service Type List</h1>
                <UseFieldArrayServiceTypes control={control} register={register} errors={errors} />
              </div>
            </div>
          </div>

          <div>
            <CancleAdd buttonText="Add" onClickCancel={onClickCancel} disabled={isLoading} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
