import { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import Pagination from '../../components/Pagination/Pagination';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';
import TableClients from '../../components/DataTable/TableClients';
import { columnsCases, statuses } from './helpers/CasesUtils';
import casesHttp from '../../http/casesHttp/casesHttp';
import InfoListModal from '../../components/Modal/InfoListModal';
import TitleBlockCases from '../../components/TitleBlockCases/TitleBlockCases';
import ClientHttp from '../../http/clientsHttp/clientsHttp';
import EmployeesHttp from '../../http/employeesHttp/employeesHttp';

export default function Cases() {
  const navigate = useNavigate();
  const [casesList, setCasesList] = useState([]);
  const [casesListPage, setCasesListDataPage] = useState(null);
  const [newLoad, setNewLoad] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [companyFormId, setCompanyFormId] = useState(null);
  const [isOpenEmployee, setIsOpenEmployee] = useState(false);
  const [list, setList] = useState([]);
  const [title, setTitle] = useState('');

  const [dataEmployeeCurrent, setDataEmployeeCurrent] = useState('');
  const [AllEmployeesData, setAllEmployeesData] = useState([]);
  const [dataClientCurrent, setDataClientCurrent] = useState('');
  const [dataStatusCurrent, setDataStatusCurrent] = useState('');
  const [AllClientsData, setAllClientsData] = useState([]);

  const fetchCasesList = useCallback((pageId = '', dataEmployeeCurrent, dataClientCurrent, dataStatusCurrent) => {
    casesHttp.getCasesList(pageId, dataEmployeeCurrent, dataClientCurrent, dataStatusCurrent).then(response => {
      setCasesListDataPage(response?.data);
      setCasesList(response?.data?.data || []);
    });
    ClientHttp.getAllClientss().then(e => {
      e?.data?.data.unshift({ name: 'All Clients', id: '' });
      setAllClientsData(e?.data?.data);
    });
    EmployeesHttp.employeesAllLinks().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          id: item?.id || item?.[0]?.employee_id,
          name: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.id)) {
            uniqueIdsSet.add(item.id);
            result.push(item);
          }
          return result;
        }, []);
      employesList.unshift({ id: '', name: 'All Employees' });
      setAllEmployeesData(employesList.length ? employesList : [{ id: '', name: 'All Employees' }]);
    });
  }, []);

  const handlePaginationClick = useCallback(
    id => {
      fetchCasesList(id, dataEmployeeCurrent, dataClientCurrent, dataStatusCurrent);
    },
    [fetchCasesList, dataEmployeeCurrent, dataClientCurrent, dataStatusCurrent],
  );

  const handleEmployeeListClick = useCallback((list, title_) => {
    if (list?.length > 1) {
      setIsOpenEmployee(true);
      setList(list);
      setTitle(title_);
    }
  }, []);

  const handleRemoveClick = useCallback((id, haveLogs) => {
    if (!haveLogs) {
      setIsOpen(true);
      setCompanyFormId(id);
    }
  }, []);

  const handleConfirmDelete = useCallback(id => {
    casesHttp.deleteCase(id).then(response => {
      if (response.status === 200) {
        setNewLoad(prev => !prev);
      }
    });
    setIsOpen(false);
  }, []);

  const handleEditClick = useCallback(
    id => {
      navigate(`/Admin/EditCase/${id}`);
    },
    [navigate],
  );

  useEffect(() => {
    fetchCasesList('', dataEmployeeCurrent, dataClientCurrent, dataStatusCurrent);
  }, [fetchCasesList, newLoad, dataEmployeeCurrent, dataClientCurrent, dataStatusCurrent]);

  const memoizedColumns = useMemo(
    () => columnsCases(handleEditClick, handleRemoveClick, handleEmployeeListClick),
    [handleEditClick, handleRemoveClick, handleEmployeeListClick],
  );

  return (
    <div className="w-full sm:p-8 p-2">
      {/* Modals */}
      <CompanyFormModal
        open={isOpen}
        setOpen={setIsOpen}
        onClickYes={handleConfirmDelete}
        companyFormId={companyFormId}
        text="Are you sure you want to delete this Assignment/Case?"
      />
      <InfoListModal
        open={isOpenEmployee}
        setOpen={setIsOpenEmployee}
        List={list}
        title={title || 'For which employees is this Assignment/Case'}
      />
      <TitleBlockCases
        src={'/CompanyFormsIcon.svg'}
        textItem={'Assignment/Cases'}
        className="text-base font-bold"
        setDataEmployeeCurrent={setDataEmployeeCurrent}
        AllEmployeesData={AllEmployeesData}
        AllClientsData={AllClientsData}
        setDataClientCurrent={setDataClientCurrent}
        statuses={statuses}
        setDataStatusCurrent={setDataStatusCurrent}
      />

      {/* Table and Pagination */}
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className="w-full overflow-x-auto">
          <div className="inline-block min-w-full">
            <TableClients maxHeight="500px" tbody={casesList} columns={memoizedColumns} />
          </div>
        </div>
        <Pagination onPaginationClick={handlePaginationClick} paginationData={casesListPage?.links} />
      </div>
    </div>
  );
}
