import { useEffect, useState, useCallback } from 'react';
import DatePicker from 'react-multi-date-picker';
import Title from '../../components/Title/Title';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import InvoiceHttp from '../../http/invoiceHttp/invoiceHttp';
import TableClients from '../../components/DataTable/TableClients';
import Pagination from '../../components/Pagination/Pagination';
import { columnsInvoiceAdmin } from './helpers/ExportInvoicesUtils';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../../components/Button/Button';

export default function ExportInvoices() {
  const [error, setError] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [dataExport, setDataExport] = useState(null);
  const [dataTable, setDataTable] = useState(null);

  const navigate = useNavigate();

  const axiosData = useCallback(async () => {
    if (!dateRange) return;
    try {
      const response = await InvoiceHttp.exportInvoices(dateRange);
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      setDataExport({
        url,
        filename: `${dateRange?.dataMonth}_${dateRange?.dataYear} Invoice database copy.csv`,
      });
    } catch (error) {
      setError(error?.response?.data?.error || 'An error occurred while exporting invoices.');
    }
  }, [dateRange]);

  const fetchInvoices = useCallback(async () => {
    if (!dateRange) return;
    try {
      const response = await InvoiceHttp.getInvoicesByDate(dateRange);
      setDataTable(response.data);
    } catch (error) {
      setError(error?.response?.data?.error || 'An error occurred while fetching invoices.');
    }
  }, [dateRange]);

  const handleDateChange = useCallback((value) => {
    setDataExport(null);
    setError('');
    setDateRange({
      dataMonth: value?.format('MM'),
      dataYear: value?.format('YYYY'),
    });
  }, []);

  const onPaginationClick = useCallback((id) => {
    InvoiceHttp.getInvoicesByDate(dateRange, id).then((e) => setDataTable(e?.data));
  }, [dateRange]);

  const onClickCancel = useCallback(() => {
    navigate('/Admin/Invoices');
  }, [navigate]);

  useEffect(() => {
    axiosData();
    fetchInvoices();
  }, [axiosData, fetchInvoices]);

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={'Export Invoices'} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form className="w-full">
          <div className="py-4 sm:px-7 px-2 w-full flex flex-col gap-2">
            <p className="text-[#60657B] opacity-50 text-xs">{'Date Range'}</p>
            <div className="w-full [&>div>input]:w-full [&>div]:w-full [&>div>input]:h-[38px] [&>div>input]:border-none [&>div>input]:focus:border-hidden [&>div>input]:hover:shadow-none [&>div>input]:focus:outline-none [&>div>input]:hover:border-none border rounded [&>div>input]:focus:shadow-none">
              <DatePicker
                format="MM/YYYY"
                placeholder="MM/YYYY"
                onChange={handleDateChange}
                onlyMonthPicker
                dateFormat="MM/yyyy"
                maxDate={new Date()}
              />
            </div>
            {error && <p className="mb-0 text-sm text-amber-800">{error}</p>}
            {!dataExport?.url && !error && dateRange && (
              <p className="mb-0 text-sm text-amber-800">Please Wait, The Data Is Loading ...</p>
            )}
          </div>
          <div className="flex gap-2 justify-end border-t py-2 sm:pr-8 pr-4 mt-2">
            <Button buttonText={'Cancel'} onClick={onClickCancel} className="bg-inherit border text-[#000] px-3" type="button" />
            <a
              href={dataExport?.url}
              download={dataExport?.filename}
              className={`${
                !dataExport?.url
                  ? 'bg-[#A6ADC9] rounded-md sm:py-1.5 py-1 sm:px-3 px-1 text-sm font-semibold shadow-sm cursor-not-allowed animate-pulse'
                  : 'rounded-md bg-[#4885ED] sm:py-1.5 py-1 sm:px-3 px-2 text-sm font-semibold shadow-sm hover:bg-indigo-400 text-white cursor-pointer'
              }`}
            >
              Export Invoices
            </a>
          </div>
        </form>
        <div className={`px-8 py-1 mt-4 bg-white rounded-xl ${!dataExport?.url && 'animate-pulse'}`}>
          <div className="w-full overflow-x-auto">
            <div className="inline-block min-w-full">
              <TableClients tbody={dataExport?.url ? dataTable?.data?.reverse() : []} columns={columnsInvoiceAdmin} maxHeight="600px" />
            </div>
          </div>
          <Pagination onPaginationClick={onPaginationClick} paginationData={dataTable?.links} />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
