import classNames from '../../utils/utils';
import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';

export default function TitleBlockRequest({
  textItem,
  children,
  className,
  classNameTitleBlock,
  AllEmployeesData,
  setDataEmployeeCurrent,
  AllClientsData,
  setDataClientCurrent,
  statuses,
  setDataStatusCurrent,
  button,
}) {
  const onChangeEmployees = e => {
    setDataEmployeeCurrent(e.target.value);
  };
  const handleChangeClient = e => {
    setDataClientCurrent(e.target.value);
  };
  const handleChangeStatus = e => {
    setDataStatusCurrent(e.target.value);
  };

  const navigate = useNavigate();

  console.log(AllEmployeesData, 'AllEmployeesData');

  return (
    <div
      className={classNames(
        'flex justify-between items-center bg-white py-2 sm:px-4 px-2 rounded-lg',
        classNameTitleBlock,
      )}>
      <div
        className={
          AllEmployeesData?.length
            ? 'flex lg:flex-row flex-col items-center gap-2  w-full pr-2'
            : 'flex sm:flex-row flex-col items-center gap-2 md:w-3/5 w-4/5 w-full pr-2'
        }>
        <img src="/TitleBlokIcon.svg" alt="img" />
        <p className="w-full">
          <span className={classNames("font-['Poppins']", className)}>{textItem}</span>
        </p>

        {button && (
          <div className="w-full">
            <Button
              buttonText={'Create Request'}
              className="text-white md:text-sm w-full"
              onClick={() => navigate('/Admin/AssignFormToClient')}
            />
          </div>
        )}

        {AllEmployeesData?.length >= 0 && (
          <div className="w-full">
            <select
              id="location"
              className={
                'w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base'
              }
              // value={value}
              onChange={onChangeEmployees}>
              {AllEmployeesData?.map((e, index) => (
                <option
                  key={index}
                  // disabled={indexx > 0 ? disable?.includes(e?.id): false}
                  value={e?.id}
                  className="cursor-pointer">
                  {e?.name ?? e?.contactName}
                </option>
              ))}
            </select>
          </div>
        )}
        {AllClientsData?.length != 0 && (
          <div className="w-full">
            <select
              id="location"
              className={
                'w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base'
              }
              // value={value}
              onChange={handleChangeClient}>
              {AllClientsData?.map((e, index) => (
                <option
                  key={index}
                  // disabled={indexx > 0 ? disable?.includes(e?.id): false}
                  value={e?.id}
                  className="cursor-pointer">
                  {e?.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {statuses?.length != 0 && (
          <div className="w-full">
            <select
              id="location"
              className={
                'w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base'
              }
              // value={value}
              onChange={handleChangeStatus}>
              {statuses?.map((e, index) => (
                <option
                  key={index}
                  // disabled={indexx > 0 ? disable?.includes(e?.id): false}
                  value={e?.id}
                  className="cursor-pointer">
                  {e?.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
