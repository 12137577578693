import Button from '../Button/Button';

export default function CancleAdd({
  onClick,
  buttonText,
  onClickCancel,
  disabled,
  isDelete,
  onClickDelete,
  isUnset,
  onClickUnset,
  isSave,
  onClickSave,
}) {
  return (
    <div className="flex gap-2 justify-end border-t py-2 sm:pr-8 sm:pr-2 pr-4 mt-2">
      <Button
        buttonText={'Cancel'}
        onClick={onClickCancel}
        className="bg-inherit border text-[#000] box-border px-3"
        type="button"
      />
      {isSave && (
        <Button
          onClick={onClickSave}
          buttonText={'Save'}
          className="text-[#fff] px-3"
          type="button"
          disabled={disabled}
        />
      )}
      <Button onClick={onClick} buttonText={buttonText} className="text-[#fff] px-3" disabled={disabled} />
      {isDelete && <Button onClick={onClickDelete} buttonText={'Delete'} className="text-[#fff] px-3" type="button" />}
      {isUnset && (
        <Button
          onClick={onClickUnset}
          buttonText={'Unset'}
          className="text-[#fff] px-3"
          disabled={disabled}
          type="button"
        />
      )}
    </div>
  );
}
