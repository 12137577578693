import { useNavigate } from 'react-router-dom';
import classNames, { onlyNumbers } from '../../utils/utils';
import WeekDataPicker from '../WeekDataPicker/WeekDataPicker';
import debounce from 'debounce';
import Button from '../Button/Button';

export default function TitleBlockServiceLog({
  textItem,
  children,
  className,
  classNameTitleBlock,
  setDateRange,
  setDateId,
  setDataEmployeeCurrent,
  AllEmployeesData,
  unbillableHoursAdmin,
  dataEmployeeCurrent,
  dontShow,
  setCurrentPage,
  AllClientsData,
  setDataClientCurrent,
  Parent,
  setDataStatusCurrent,
  statuses,
  setDataPublishCurrent,
  setIsOpeCreateModal,
  dataStatusCurrent,
}) {
  const debouncedOnChange = debounce(e => {
    setDateId(e?.target?.value);
    setCurrentPage && setCurrentPage(1);
  }, 500);

  const onChangeEmployees = e => {
    setDataEmployeeCurrent(e.target.value);
    setCurrentPage && setCurrentPage(1);
  };
  const onChangeStatuses = e => {
    setDataStatusCurrent(e.target.value);
    setCurrentPage && setCurrentPage(1);
  };
  const onChangePublish = e => {
    setDataPublishCurrent(e.target.value);
    setCurrentPage && setCurrentPage(1);
  };
  const onChangeClients = e => {
    setDataClientCurrent(e.target.value);
    setCurrentPage && setCurrentPage(1);
  };

  const navigate = useNavigate();

  const PublishedData = [
    { name: 'All ', id: '' },
    { name: 'Published', id: 1 },
    { name: 'Unpublished', id: 0 },
  ];

  return (
    <div
      className={classNames(
        'flex justify-between items-center bg-white py-2 sm:px-4 px-2 rounded-lg',
        classNameTitleBlock,
      )}>
      <div
        className={
          AllEmployeesData?.length
            ? 'flex lg:flex-row flex-col items-center gap-2  w-full pr-2'
            : 'flex sm:flex-row flex-col items-center gap-2 md:w-3/5 w-4/5 w-full pr-2'
        }>
        <img src="/TitleBlokIcon.svg" alt="img" />
        <p className="w-full">
          <span className={classNames("font-['Poppins']", className)}>{textItem}</span>
        </p>
        {!unbillableHoursAdmin && (
          <div className="w-full">
            <Button
              buttonText={'Publish service logs'}
              className="text-white md:text-sm w-full"
              onClick={() => navigate('/Admin/PublishServiceLogs')}
            />
          </div>
        )}
        {!unbillableHoursAdmin && (
          <div className="w-full">
            <Button
              buttonText={'Send service logs'}
              className="text-white md:text-sm w-full"
              onClick={() => navigate('/Admin/SendServiceLogs')}
            />
          </div>
        )}
        {!unbillableHoursAdmin && (
          <div className="w-full">
            <Button
              buttonText={'Add Service Log'}
              className="text-white md:text-sm w-full"
              // onClick={() => navigate(`/Admin/AddServiceLog/${dataEmployeeCurrent}`)}
              onClick={() => setIsOpeCreateModal(true)}
              disabled={!dataEmployeeCurrent}
            />
          </div>
        )}
        <WeekDataPicker setDateRange={setDateRange} setCurrentPage={setCurrentPage} />
        {!dontShow && (
          <div className="relative w-full">
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <img src="/search.svg" alt="img" />
            </div>
            <input
              onKeyPress={onlyNumbers}
              placeholder="search by id"
              onChange={debouncedOnChange}
              className="w-full outline-none border rounded p-1 text-xs sm:text-base h-[34px]"
            />
          </div>
        )}
        {AllEmployeesData?.length != 0 && (
          <div className="w-full">
            <select
              id="location"
              className={
                'w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base'
              }
              // value={value}
              onChange={onChangeEmployees}>
              {AllEmployeesData?.map((e, index) => (
                <option
                  key={index}
                  // disabled={indexx > 0 ? disable?.includes(e?.id): false}
                  value={e?.id}
                  className="cursor-pointer">
                  {e?.name ?? e?.contactName}
                </option>
              ))}
            </select>
          </div>
        )}
        {!unbillableHoursAdmin && statuses?.length != 0 && (
          <div className="w-full">
            <select
              id="location"
              className={
                'w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base'
              }
              value={dataStatusCurrent}
              onChange={onChangeStatuses}
              // defaultValue={dataStatusCurrent??''}
            >
              {statuses?.map((e, index) => (
                <option
                  key={index}
                  // disabled={indexx > 0 ? disable?.includes(e?.id): false}
                  value={e?.id}
                  className="cursor-pointer">
                  {e?.name ?? e?.contactName}
                </option>
              ))}
            </select>
          </div>
        )}
        {!unbillableHoursAdmin && PublishedData?.length != 0 && (
          <div className="w-full">
            <select
              id="location"
              className={
                'w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base'
              }
              // value={value}
              onChange={onChangePublish}>
              {PublishedData?.map((e, index) => (
                <option
                  key={index}
                  // disabled={indexx > 0 ? disable?.includes(e?.id): false}
                  value={e?.id}
                  className="cursor-pointer">
                  {e?.name ?? e?.contactName}
                </option>
              ))}
            </select>
          </div>
        )}

        {Parent && AllClientsData?.length != 0 && (
          <div className="w-full">
            <select
              id="location"
              className={
                'w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base'
              }
              // value={value}
              onChange={onChangeClients}>
              {AllClientsData?.map((e, index) => (
                <option
                  key={index}
                  // disabled={indexx > 0 ? disable?.includes(e?.id): false}
                  value={e?.id}
                  className="cursor-pointer">
                  {e?.name ?? e?.contactName}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
