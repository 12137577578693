import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import classNames from '../../utils/utils';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import Logo from '../../pages/MainPage/img/logoColor.svg';
import { useMemo } from 'react';
import { getNavigation } from './helpers/SidebarUtils';

const SidebarBaseClasses = {
  main: 'flex flex-grow min-w-[190px] flex-col min-h-screen h-full border-r border-gray-200 bg-white pb-4',
  isActive:
    "bg-[#344C8C] font-['Poppins'] mt-6 font-bold text-[#FFFFFF] group flex w-full items-center rounded-md py-2 pl-2 text-sm [&>div>div]:bg-[image:var(--image-url1)] font-bold",
  isIncative:
    'bg-white text-[#344C8C] hover:bg-gray-50 [&>div>div]:bg-[image:var(--image-url)] bg-no-repeat  font-["Poppins"] mt-6  hover:text-gray-900 group flex w-full items-center rounded-md py-2 pl-2 text-sm font-normal hover:bg-gray-50',
};
export default function Sidebar({ newClass, onClick }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const OnClickLogo = () => {
    if (role === 'admin') {
      navigate('/Admin/Dashboard');
    } else if (role === 'employee') {
      navigate('/Employee/Dashboard');
    } else if (role === 'superAdmin') {
      navigate('/SuperAdmin/Dashboard');
    } else if (role === 'client') {
      navigate('/Client/ServiceLog');
    }
  };

  const role = useMemo(() => {
    if (pathname?.startsWith('/Employee')) return 'employee';
    if (pathname?.startsWith('/Client')) return 'client';
    if (pathname?.startsWith('/SuperAdmin')) return 'superAdmin';
    return 'admin';
  }, [pathname]);

  const employeeData = useSelector(state => state?.adminProfile?.employeeProfiledata);
  const navigation = useMemo(() => getNavigation(role, employeeData), [role, employeeData]);

  return (
    <div className={SidebarBaseClasses?.main + newClass}>
      <div className="relative z-10 flex py-2 lg:px-0">
        <div className="flex items-center cursor-pointer ml-[20px]">
          <img className="sm:h-[53px] h-[28px]" src={Logo} alt="Your Company" onClick={OnClickLogo} />
        </div>
      </div>
      <div className="flex flex-grow flex-col">
        <nav className="flex-1 space-y-1 bg-white px-2 z-50 h-full" aria-label="Sidebar">
          <ul>
            {navigation?.map(item =>
              !item.children ? (
                <NavLink
                  key={item?.name}
                  to={item?.href}
                  onClick={onClick}
                  style={{ '--image-url': `url(${item?.icon})`, '--image-url1': `url(${item?.icon_white})` }}
                  className={({ isActive }) =>
                    isActive ? SidebarBaseClasses?.isActive : SidebarBaseClasses?.isIncative
                  }>
                  <div className={`flex gap-4`}>
                    <div className="w-6 h-6 bg-no-repeat"></div>
                    {item?.count ? (
                      <li className="flex gap-1">
                        {item?.name}{' '}
                        <span className="text-[#FFFFFF] p-1 bg-[#FF6B00] text-[8px] block w-4 h-4 flex items-center float-right rounded-full justify-center">
                          {item?.count}
                        </span>
                      </li>
                    ) : (
                      <li className="text-sm main:text-base font-poppins">{item?.name}</li>
                    )}
                  </div>
                </NavLink>
              ) : (
                <Disclosure as="div" key={item?.name}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        style={{ '--image-url': `url(${item?.icon})`, '--image-url1': `url(${item?.icon_white})` }}
                        className={classNames(
                          item?.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                          'bg-white text-[#344C8C] hover:bg-gray-50 [&>div>div]:bg-[image:var(--image-url)] bg-no-repeat  font-["Poppins"] mt-6  hover:text-gray-900 group flex w-full items-center rounded-md py-2 pl-2 text-sm font-medium hover:bg-gray-50',
                        )}>
                        <div className="flex gap-4">
                          <div className="w-6 h-6 bg-no-repeat"></div>
                          <li className="flex gap-1 text-sm main:text-base font-poppins">{item?.name}</li>
                        </div>
                        {/* </NavLink> */}
                        <ChevronRightIcon
                          className={classNames(
                            open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                            'ml-auto h-5 w-5 shrink-0',
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel as="ul" className="mt-1 px-2">
                        {item.children.map(item => (
                          <NavLink
                            key={item?.name}
                            to={item?.href}
                            onClick={onClick}
                            style={{ '--image-url': `url(${item?.icon})`, '--image-url1': `url(${item?.icon_white})` }}
                            className={({ isActive }) =>
                              isActive ? SidebarBaseClasses?.isActive : SidebarBaseClasses?.isIncative
                            }>
                            <div className="flex gap-3 items-center">
                              <div className="w-6 h-6 bg-no-repeat"></div>
                              {item?.count ? (
                                <li className="flex gap-1">
                                  {item?.name}{' '}
                                  <span className="text-[#FFFFFF] p-1 bg-[#FF6B00] text-[8px] block w-4 h-4 flex items-center float-right rounded-full justify-center">
                                    {item?.count}
                                  </span>
                                </li>
                              ) : (
                                <li>{item?.name}</li>
                              )}
                            </div>
                          </NavLink>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ),
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
}
