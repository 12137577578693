import AdditionalFieldWithPassword from './AdditionalFieldWithPassword';

export default function AddEmailsField({
  AddEmailsFields,
  register,
  errors,
  control,
  AddEmailsAppend,
  AddEmailsRemove,
}) {
  const onClickAddNewAdditionalEmail = () => {
    AddEmailsAppend({ access_for_service_logs: true, access_for_invoices: true, access_for_request: true });
  };

  return (
    <>
      {AddEmailsFields.map((elem, index) => (
        <AdditionalFieldWithPassword
          key={elem.id}
          errors={errors}
          index={index}
          register={register}
          AddEmailsRemove={AddEmailsRemove}
        />
      ))}
      <div className="grid grid-cols-1 sm:gap-6 gap-2 mt-5">
        <section className="text-end">
          <button
            className={`text-[#4885ED] px-3 py-1 border rounded text-base min-w-[204px] max-w-[204px] ${
              AddEmailsFields.length >= 4 && 'bg-[#A6ADC9] text-[white]'
            } `}
            type="button"
            onClick={onClickAddNewAdditionalEmail}
            disabled={AddEmailsFields.length >= 4}>
            Add Additional Email
          </button>
        </section>
      </div>
    </>
  );
}
