import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../components/Pagination/Pagination';
import Tub from '../../components/Tab/Tab';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import { adminProfileDataSlice, employeeProfileDataSlice } from '../../redux/adminProfileSlice/adminProfileSlice';
import { loadPaginationClientSlice, loadPaginationParentsSlice } from '../../redux/clientsSlice/clientsSlice';
import { useNavigate } from 'react-router-dom';
import TableClients from '../../components/DataTable/TableClients';
import TableParent from '../../components/DataTable/TableParent';
import { ToastContainer } from 'react-toastify';
import ChartDonut from '../../components/Chart/Chart';
import CardTwo from '../../components/CardTwo/CardTwo';
import ReportsHttp from '../../http/reportsHttp/reportsHttp';
import { cardThree, columnsClient, columnsParent, dataChartDonut } from './helpers/DashboardUtils';
import DatePicker from 'react-multi-date-picker';
import { getCurrentMonthDates } from '../../utils/utils';

export default function Dashboard() {
  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dateRangeCard, setDateRangeCard] = useState(null);
  const clientData = useSelector(data => data.clients.clientSliceData);
  const parentsData = useSelector(data => data?.clients?.parentClientSliceData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');

  const cardTwo = useMemo(() => {
    return [
      {
        title: data?.[0]?.schools?.total ?? '',
        subTitle: 'Total Clients',
        text: `${data?.[0]?.schools?.new_this_month ?? ''} new Clients added!`,
        iconBig: '/TotalClientsRoundIcon.svg',
        iconSmall: '/RoundPlusIcon.svg',
      },
      {
        title: data?.[0]?.employees?.total ?? '',
        subTitle: 'Total Employees',
        text: `${data?.[0]?.employees?.new_this_month ?? ''} new Emplyees added!`,
        iconBig: '/TotalEmployeesIcon.svg',
        iconSmall: '/RoundPlusIcon.svg',
      },
      {
        title: data?.[0]?.gross_profit ?? '',
        subTitle: 'Gross Profit',
        text: '+10% more than last month',
        iconBig: '/GrossProfitIcon.svg',
        iconSmall: '/GrossProfitIconSmall.svg',
      },
    ];
  }, [data]);

  const giveAccessParent = id => {
    navigate(`/Admin/GiveAccessParent/${id}`);
  };

  const editParentCompany = id => {
    navigate(`/Admin/EditParentCompany/${id}`);
  };
  const editClient = id => {
    navigate(`/Admin/EditClient/${id}`);
  };

  const giveAccess = id => {
    navigate(`/Admin/GiveAccessClient/${id}`);
  };

  const onPaginationClick = id => {
    active ? dispatch(loadPaginationParentsSlice(id)) : dispatch(loadPaginationClientSlice(id));
  };

  useEffect(() => {
    dispatch(loadPaginationParentsSlice(''));
    dispatch(loadPaginationClientSlice(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  useEffect(() => {
    if (token) {
      if (role === 'admin') {
        dispatch(adminProfileDataSlice());
      } else {
        dispatch(employeeProfileDataSlice());
      }
    }
    setDateRangeCard(getCurrentMonthDates());
  }, []);

  useEffect(() => {
    ReportsHttp.DashboardDataLinks(dateRangeCard)
      .then(e => setData(e?.data?.data))
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeCard]);

  const handleDateChangeCard = value => {
    const date = {
      startDate: value?.[0]?.format('MM/DD/YYYY'),
      endDate: value?.[1]?.format('MM/DD/YYYY'),
    };
    setDateRangeCard(date);
  };

  return (
    <>
      {
        <div className="w-full pb-12 overflow-hidden">
          <TitleBlok
            textItem={'General'}
            className={'text-base font-semibold'}
            src="/TitleBlokIcon.svg"
            classNameTitleBlock="sm:m-8 mx-4 my-6"
          />
          <div className={`mx-auto px-4 sm:px-8 ${loading && `animate-pulse`}`}>
            <div className="w-[32.333333%] mt-2">
              <div
                className={`w-full [&>div>input]:w-full [&>div]:w-full [&>div>input]:h-[50px] [&>div>input]:border-none [&>div>input]:focus:border-hidden [&>div>input]:hover:shadow-none [&>div>input]:focus:outline-none [&>div>input]:hover:border-none border rounded-xl [&>div>input]:focus:shadow-none sm:text-base text-xs`}>
                <DatePicker
                  range
                  // className="h-40px"
                  numberOfMonths={2}
                  format="MM/DD/YYYY"
                  placeholder="MM/DD/YYYY - MM/DD/YYYY"
                  value={[dateRangeCard?.startDate, dateRangeCard?.endDate]}
                  onChange={handleDateChangeCard}
                />
              </div>
            </div>
            <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
              <div className="flex flex-col gap-5">
                {cardTwo.map(({ title, subTitle, text, iconBig, iconSmall }, index) => (
                  <Fragment key={index}>
                    <CardTwo title={title} subTitle={subTitle} text={text} iconBig={iconBig} iconSmall={iconSmall} />
                  </Fragment>
                ))}
              </div>
              <div className='rounded-[15px] bg-white pt-6'>
                <div className='flex justify-between items-center px-4'>
                  <p className="font-['Poppins'] text-xl font-medium">Daily Gross Profit</p>
                  <img src='/dollarIconCard.png' alt='ass'/>
                </div>
                <div className='flex items-center justify-center mt-3'>
                <div className='rounded-full bg-[white] w-[192px] h-[192px] px-5 py-16 border-[#4885ED]' style={{"borderWidth": "10px"}}>
                  <div className='flex flex-col items-center jusitfy-center gap-5'>
                    <p className="text-[#2B2D38] font-['Poppins'] text-4xl font-light">$7,900</p>
                    <div className='w-[80px] h-[24px] bg-[#DCFCE7] rounded-full flex justify-between px-4 items-center gap-1'>
                      <img src='/GreenArrow.png' alt='GreenArrow' className='w-[12px] h-[14px]'/>
                      <p className='text-sm	text-[#166534] font-medium'>12%</p>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div className='rounded-[15px] bg-white pt-6'>
                <div className='flex justify-between items-center px-4'>
                  <p className="font-['Poppins'] text-xl font-medium">Daily Gross Profit</p>
                  <img src='/dollarIconCard.png' alt='ass'/>
                </div>
                <div className='flex items-center justify-center mt-3'>
                <div className='rounded-full bg-[white] w-[192px] h-[192px] px-5 py-16 border-[#00B1B280]' style={{"borderWidth": "10px"}}>
                  <div className='flex flex-col items-center jusitfy-center gap-5'>
                    <p className="text-[#2B2D38] font-['Poppins'] text-4xl font-light">$54,460</p>
                    <div className='w-[80px] h-[24px] bg-[#FEE2E2] rounded-full flex justify-between px-4 items-center gap-1'>
                      <img src='/RedArrow.png' alt='RedArrow' className='w-[12px] h-[14px]'/>
                      <p className='text-sm	text-[#991B1B] font-medium'>4.05%</p>
                    </div>
                  </div>
                </div>
                </div>
              </div>
               {/* <ChartBar Title={'Daily Gross Profit'} data={ChartBarData} />
              <ChartBar Title={'Daily Gross Profit'} data={ChartBarData} />  */}
            </div>
          </div>
          <div className={`mx-auto px-4 sm:px-8 ${loading && `animate-pulse`}`}>
            <div className="mt-5 grid grid-cols-1 gap-6 sm:grid-cols-2 ">
              <div className="rounded-xl bg-white shadow">
                <div className="p-5 ">
                  <div className="flex items-center gap-4">
                    <div className="px-4 flex flex-col gap-3 w-full">
                      <p className="text-lg font-['Poppins'] text-[#2B2D38] font-medium px-8 text-center">
                        {'Invoice Summary'}
                      </p>
                      <div className="flex gap-2 items-center">
                        <ChartDonut data={dataChartDonut(data)} />
                        <div className="flex flex-col h-[60px] w-full pr-2">
                          <div className="flex items-center justify-between">
                            <div className="flex gap-2 items-center">
                              <div className="bg-[#344C8C] rounded-full w-[10px] h-[10px]"></div>
                              <p className="text-base font-['Poppins'] font-light text-[#252C58]">Total outstanding</p>
                            </div>
                            <p className="ml-2 text-2xl font-['Poppins'] font-medium text-[#2B2D38]">
                              {data?.[0]?.totalInvoices?.totalOutstanding ?? ''}
                            </p>
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="flex gap-2 items-center">
                              <div className="bg-[#A1BFF2] rounded-full w-[10px] h-[10px]"></div>
                              <p className="text-base font-['Poppins'] font-light text-[#252C58]">Total overdue</p>
                            </div>
                            <p className="ml-2 text-2xl font-['Poppins'] font-medium text-[#2B2D38]">
                              {data?.[0]?.totalInvoices?.totalOverdue ?? ''}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 ">
                {cardThree(data?.[0])?.map((elem, index) => (
                  <div className="rounded-xl bg-white shadow" key={index}>
                    <div className="py-4 px-4">
                      <div className="flex items-center gap-4">
                        <div className="px-4 flex flex-col gap-3 w-full">
                          <p className="text-lg font-poppins text-[#2B2D38] font-medium px-8 text-center">
                            {elem.title}
                          </p>
                          <div className='flex gap-5 flex-col'>
                            {elem?.data.map((item, ind) => (
                              <div className="flex gap-5 items-center" key={ind}>
                                <img src="/LilAvatar.svg" className="w-8" alt="logo" />
                                <p className="text-[#252C58] font-['Poppins'] font-light">
                                  {ind + 1}. {item?.name}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-5">
            <Tub
              active={active}
              clickParent={() => setActive(true)}
              clickClient={() => setActive(false)}
              titleParent={'Parent Client'}
              titleClient={'Client'}
              priceParent={parentsData?.links?.total ?? 0}
              priceClient={clientData?.links?.total ?? 0}
            />

            <div className="sm:px-8 px-4">
              <div className="px-4 py-1 mt-4 bg-white rounded-xl ">
                <div className="overflow-x-auto">
                  {active ? (
                    <TableParent
                      maxHeight="600px"
                      tbody={parentsData?.data ?? []}
                      columns={columnsParent(editParentCompany, giveAccessParent, navigate)}
                    />
                  ) : (
                    <TableClients
                      tbody={clientData?.data ?? []}
                      columns={columnsClient(editClient, giveAccess, navigate)}
                      maxHeight="600px"
                    />
                  )}
                  <div>
                    <Pagination
                      paginationData={active ? parentsData.links : clientData?.links}
                      onPaginationClick={onPaginationClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      }
    </>
  );
}
