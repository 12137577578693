import { $authHost } from "..";
import { $authHostt } from "..";

export default class Payments {
    static getInvoicePagination(id) {
        return $authHost.get(`/admin/invoice?id=${id ? id : ""}`);
    };
    static getInvoicePaginationPage(id) {
        return $authHost.get(`/admin/invoice?page=${id}`);
    };
    static getUnpaidInvoices(school_id, parent_id,status) {
        return $authHost.get(`/admin/getUnpaidInvoices?school_id=${school_id??""}&group_school_id=${parent_id??""}&status=${status??""}`);
    };
    static createInvoice(data) {
        return $authHost.post(`/admin/invoice`, data);
    };
    static remindInvoices(data) {
        return $authHost.post(`/admin/remindInvoices`, data);
    };
    static getInvoice(id) {
        return $authHost.get(`/admin/invoice/${id}`);
    };
    static deleteInvoice(id) {
        return $authHost.post(`/admin/invoice/${id}`);
    };
    static getInvoicePdf(id) {
        return $authHostt.get(`/admin/invoice/pdf/${id}`);
    };
    static getInvoicesByClientId(id,dateRange) {
        return $authHost.get(`/admin/invoiceByClient/${id}?startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}`);
    };
    static updatePayment(id,status) {
        return $authHost.post(`admin/updatePayment/${id}`,status);
    };
    static parital(id,data) {
        return $authHost.post(`admin/invoice/partial/${id}`,data);
    };
    static remindPayment(id) {
        return $authHost.post(`admin/remindPay/invoice/${id}`);
    };
    static sendInvoices(data) {
        return $authHost.post(`admin/invoice/sendPdfs`,data);
    };
    // static getPaymentsByIdAndDate(id,dateRange) {
    //     return $authHost.get(`/admin/PaymentInvoices?id=${id ? id : ""}&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}`);
    // };
    static getPaymentsByIdAndDate(id,dateRange,status,client,page) {
        return $authHost.get(`/admin/PaymentInvoices?startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&id=${id ?? ""}&payment_status=${status ?? ""}&client=${client ?? ""}&page=${page ?? ""}`)
    };
    static getInvoiceStatuses() {
        return $authHost.get(`/admin/invoiceStatuses`);
    };
}
