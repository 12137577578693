import { $authHost } from '..';
import { $authHostt } from '..';

export default class InvoiceHttp {
  static getInvoicePagination(id, dataEmployeeCurrent, page, dataClientCurrent, dataPublishCurrent, dataSentCurrent) {
    return $authHost.get(
      `/admin/invoice?id=${id ? id : ''}&employee_id=${dataEmployeeCurrent ?? ''}&school_id=${
        dataClientCurrent ?? ''
      }&page=${page ?? ''}&publish=${dataPublishCurrent ?? ''}&send=${dataSentCurrent ?? ''}`,
    );
  }
  static getInvoicePaginationClient(id) {
    return $authHost.get(`/client/invoice?id=${id ? id : ''}`);
  }
  static editInvoice(id, data) {
    return $authHost.post(`/admin/invoice/edit/${id}`, data);
  }
  static getInvoicePaginationPage(id, dataEmployeeCurrent, dataClientCurrent, dataPublishCurrent, dataSentCurrent) {
    return $authHost.get(
      `/admin/invoice?employee_id=${dataEmployeeCurrent ?? ''}&school_id=${dataClientCurrent ?? ''}&page=${
        id ?? ''
      }&publish=${dataPublishCurrent ?? ''}&send=${dataSentCurrent ?? ''}`,
    );
  }
  static getInvoicePaginationPageClient(dataClientCurrent, status, isDesc, id) {
    return $authHost.get(
      `/client/invoice?page=${id ?? ''}&school_id=${dataClientCurrent ?? ''}&payment_status=${
        status ?? ''
      }&sort_direction=${isDesc ?? 'desc'}`,
    );
  }
  static getEmployeesBySchoolId(id, dateRange, currentParentId, currentRequest,form_submission_id) {
    return $authHost.get(
      `/admin/getEmployees?school_id=${id ?? ''}&group_school_id=${currentParentId ?? ''}&startDate=${
        dateRange?.startDate ?? ''
      }&endDate=${dateRange?.endDate ?? ''}&included_request_log=${currentRequest ?? ''}&form_submission_id=${
        form_submission_id ?? ''
      }`,
    );
  }
  static getCasesBySchoolId(id, dateRange, currentParentId) {
    return $authHost.get(
      `/admin/getCases?school_id=${id ?? ''}&group_school_id=${currentParentId ?? ''}&startDate=${
        dateRange?.startDate ?? ''
      }&endDate=${dateRange?.endDate ?? ''}`,
    );
  }
  static createInvoice(data) {
    return $authHost.post(`/admin/invoice`, data);
  }
  static createRequestInvoice(data) {
    return $authHost.post(`/admin/storeInvoiceWithRequest`, data);
  }
  static createCaseInvoice(data) {
    return $authHost.post(`/admin/perCaseInvoice`, data);
  }
  static getInvoice(id) {
    return $authHost.get(`/admin/invoice/${id}`);
  }
  static getInvoiceForEdit(id) {
    return $authHost.get(`/admin/invoiceForEdit/${id}`);
  }
  static deleteInvoice(id) {
    return $authHost.post(`/admin/invoice/${id}`);
  }
  static RegenerateInvoice(id, data) {
    return $authHost.post(`/admin/invoice/${id}`, data);
  }
  static getInvoicePdf(id) {
    return $authHostt.get(`/admin/invoice/pdf/${id}`);
  }
  static getInvoicePdfClient(id) {
    return $authHostt.get(`/client/invoice/pdf/${id}`);
  }
  static getInvoicesByClientId(school_id, dateRange, parent_id) {
    return $authHost.get(
      `/admin/invoiceByClient?school_id=${school_id ?? ''}&group_school_id=${parent_id ?? ''}&startDate=${
        dateRange?.startDate ?? ''
      }&endDate=${dateRange?.endDate ?? ''}`,
    );
  }
  static getInvoicesRequestForSendByClientId(school_id, dateRange, parent_id) {
    return $authHost.get(
      `/admin/invoiceByClient?school_id=${school_id ?? ''}&group_school_id=${parent_id ?? ''}&startDate=${
        dateRange?.startDate ?? ''
      }&endDate=${dateRange?.endDate ?? ''}&only_request=${1}`,
    );
  }
  static getInvoicesByClientIdCase(school_id, dateRange, parent_id) {
    return $authHost.get(
      `/admin/getInvoicesByClientWithCase?school_id=${school_id ?? ''}&group_school_id=${parent_id ?? ''}&startDate=${
        dateRange?.startDate ?? ''
      }&endDate=${dateRange?.endDate ?? ''}`,
    );
  }
  static getInvoicesByClient(school_id, dateRange, parent_id) {
    return $authHost.get(
      `/admin/getInvoicesForPublish?school_id=${school_id ?? ''}&group_school_id=${parent_id ?? ''}&startDate=${
        dateRange?.startDate ?? ''
      }&endDate=${dateRange?.endDate ?? ''}`,
    );
  }
  static getInvoicesRequestByClient(school_id, dateRange, parent_id) {
    return $authHost.get(
      `/admin/getInvoicesForPublish?school_id=${school_id ?? ''}&group_school_id=${parent_id ?? ''}&startDate=${
        dateRange?.startDate ?? ''
      }&endDate=${dateRange?.endDate ?? ''}&only_request=${1}`,
    );
  }
  static getInvoicesByClientCase(school_id, dateRange, parent_id) {
    return $authHost.get(
      `/admin/getInvoicesByClientForPublishWith?school_id=${school_id ?? ''}&group_school_id=${
        parent_id ?? ''
      }&startDate=${dateRange?.startDate ?? ''}&endDate=${dateRange?.endDate ?? ''}`,
    );
  }
  static sendInvoice(id) {
    return $authHostt.post(`admin/sendInvoice/pdf/${id}`);
  }
  static moveInvoice(id) {
    return $authHost.post(`admin/move/invoice/${id}`);
  }
  static unMoveInvoice(id) {
    return $authHost.post(`admin/unMove/invoice/${id}`);
  }
  static sendInvoices(data) {
    return $authHost.post(`admin/send-pdfs`, data);
  }
  static sendInvoicesRequest(data) {
    return $authHost.post(`admin/send-pdfs`, data);
  }
  static invoicesPublish(data) {
    return $authHost.post(`/admin/move/invoices`, data);
  }
  static invoicesRequestPublish(data) {
    return $authHost.post(`/admin/move/invoices`, data);
  }
  static exportInvoices(data) {
    return $authHost.get(`admin/exportInvoices/${data.dataMonth}/${data.dataYear}`);
  }
  static getInvoicesByDate(data, id) {
    return $authHost.get(`admin/getInvoicesByDate/${data.dataMonth}/${data.dataYear}?page=${id ?? ''}`);
  }
}
