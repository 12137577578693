import React, { useEffect, useRef, useState } from 'react';

import ServiceLogCard from '../serviceLogCard';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FullCalendarY from './FullCalendarAdmin';
import AdminServiceLogHttp from '../../../http/adminServiceLogHttp/adminServiceLogHttp';
import Button from '../../Button/Button';
import { notifySuccess } from '../../../utils/utils';
import { ToastContainer } from 'react-toastify';

export default function ScheduleAdmin() {
  const { id1, id2 } = useParams();
  const navigate = useNavigate();
  const [calendarData, setCalendarData] = useState();
  const [status, setStatus] = useState();
  const [employee, setEmployee] = useState();
  const [approve, setApprove] = useState(false);
  console.log(status, 'status');

  const { state } = useLocation();
  const navigateEdit = (id, id2) => {
    navigate(`/Admin/EditServiceLog/${id}/${id2}`, { state: { id1: id1, id2: id2 } });
  };
  const navigateEditRequest = id => {
    navigate(`/Admin/EditServiceLogRequest/${id}/${id2}`, { state: { id1: id1, id2: id2 } });
  };
  const arr = calendarData?.[0]?.day?.split('-');
  const [h, setH] = useState();
  let newData;

  const calendarRef = useRef();
  const prev = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev();

    const startOptions = { month: 'short', day: 'numeric' };
    const endOptions = { month: 'short', day: 'numeric' };
    const options = { month: 'numeric', day: 'numeric', year: 'numeric' };

    const startDate = calendarApi.getDate();
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6); // Add 6 days to the start date

    const startFormat = startDate.toLocaleDateString(undefined, startOptions);
    const endFormat = endDate.toLocaleDateString(undefined, endOptions);
    const weekRangeFormat = `${startFormat} – ${endFormat}, ${startDate.getFullYear()}`;
    const startFormatForSend = startDate.toLocaleDateString(undefined, options);
    const endFormatForSend = endDate.toLocaleDateString(undefined, options);
    const newWeek = {
      startDate: `${startFormatForSend}`,
      endDate: `${endFormatForSend}`,
    };
    AdminServiceLogHttp.serviceLogDataLinks(newWeek, '', id2).then(e => {
      const flatArray = [].concat(...e?.data?.data);
      navigate(`/Admin/ServiceLog/Employee/${id2}/Calendar/${flatArray?.[0]?.id}`);
      window.location.reload();
    });
    setH(weekRangeFormat);
  };

  const next = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.next();

    const startOptions = { month: 'short', day: 'numeric' };
    const endOptions = { month: 'short', day: 'numeric' };
    const options = { month: 'numeric', day: 'numeric', year: 'numeric' };

    const startDate = calendarApi.getDate();
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6); // Add 6 days to the start date

    const startFormat = startDate.toLocaleDateString(undefined, startOptions);
    const endFormat = endDate.toLocaleDateString(undefined, endOptions);
    const weekRangeFormat = `${startFormat} – ${endFormat}, ${startDate.getFullYear()}`;
    const startFormatForSend = startDate.toLocaleDateString(undefined, options);
    const endFormatForSend = endDate.toLocaleDateString(undefined, options);
    const newWeek = {
      startDate: `${startFormatForSend}`,
      endDate: `${endFormatForSend}`,
    };
    AdminServiceLogHttp.serviceLogDataLinks(newWeek, '', id2).then(e => {
      const flatArray = [].concat(...e?.data?.data);
      navigate(`/Admin/ServiceLog/Employee/${id2}/Calendar/${flatArray?.[0]?.id}`);
      window.location.reload();
    });
    setH(weekRangeFormat);
  };

  newData = calendarData
    ?.map(e =>
      e?.['service logs']?.map(item => ({
        id: item?.id,
        description: item?.Description,
        positions: item?.Positions,
        title: item?.client,
        start: item?.['Start Time'].includes('pm')
          ? e?.day +
            'T' +
            (item?.['Start Time']?.match(/\d+/)?.[0] == 12
              ? item?.['Start Time']?.match(/\d+/)?.[0]
              : +item?.['Start Time']?.match(/\d+/)?.[0] + 12) +
            item?.['Start Time']?.match(/:(\d+)/)?.[0]
          : item?.['Start Time']?.slice(0, 5)?.match(/\d+/)?.[0] < 10
          ? e?.day + 'T' + '0' + item?.['Start Time']?.slice(0, 4)
          : e?.day + 'T' + item?.['Start Time']?.slice(0, 5),

        end: item?.['End Time'].includes('pm')
          ? e?.day +
            'T' +
            (item?.['End Time']?.match(/\d+/)?.[0] == 12
              ? item?.['End Time']?.match(/\d+/)?.[0]
              : +item?.['End Time']?.match(/\d+/)?.[0] + 12) +
            item?.['End Time']?.match(/:(\d+)/)?.[0]
          : item?.['End Time']?.slice(0, 5)?.match(/\d+/)?.[0] < 10
          ? e?.day + 'T' + '0' + item?.['End Time']?.slice(0, 4)
          : e?.day + 'T' + item?.['End Time']?.slice(0, 5),
      })),
    )
    ?.flatMap(inn => inn);

  let newDataCard = calendarData?.map(e =>
    e?.['service logs']?.map(item => ({
      id: item?.service_log_id,
      weekId: e?.id,
      description: item?.Description,
      positions: item?.Positions,
      title: item?.client,
      day: e?.day,
      start: item?.['Start Time'],
      end: item?.['End Time'],
      status: status,
      is_request_log: item?.['is_request_log'],
      request_status: item?.['request_status'],
    })),
  );
  newDataCard = newDataCard?.flatMap(innerArr => innerArr);

  useEffect(() => {
    const startOptions = { month: 'short', day: 'numeric' };
    const endOptions = { month: 'short', day: 'numeric' };
    const startedYear = { year: 'numeric' };

    const startDate = new Date(calendarData?.[0]?.day);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6); // Add 6 days to the start date

    const startFormat = startDate.toLocaleDateString(undefined, startOptions);
    const endFormat = endDate.toLocaleDateString(undefined, endOptions);
    const startedYearFormat = startDate.toLocaleDateString(undefined, startedYear);

    const weekRangeFormat = `${startFormat} – ${endFormat}, ${startedYearFormat}`;
    setH(weekRangeFormat);
  }, [calendarData]);

  useEffect(() => {
    AdminServiceLogHttp.serviceLogWeekAdminLinks(id1, id2)
      .then(y => {
        setCalendarData(y?.data?.data?.days);
        setStatus(y?.data?.data?.status);
        setEmployee(y?.data?.data?.employee);
      })
      .catch(error => error?.response?.status == 500 && navigate('/Admin/ServiceLog'));
  }, [id1, id2, approve]);

  const onClickBack = () => navigate('/Admin/ServiceLog', { state: { status: '2', page: state?.page } });

  const afterRequest = title => {
    notifySuccess(title);
  };

  const onClickDiscard = (id, employeId) => {
    AdminServiceLogHttp.serviceLogDiscardAdminLinks(id, employeId)
      // .then(() => setViewData([]))
      .then(() => afterRequest('Service Log successfully Discarded'));
  };

  const onClickRemind = (id, employeId) => {
    // setIsLoading(true);
    AdminServiceLogHttp.serviceLogRemaindAdminLinks(id, employeId)
      .then(() => afterRequest('Message successfully sent'))
      .catch(err => {
        console.log(err, 'err');
        // setIsLoading(false);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };
  const onClickApprove = (id, employeId) => {
    AdminServiceLogHttp.serviceLogApporveAdminLinks(id, employeId)
      .then(() => setApprove(prev => !prev))
      .then(() => afterRequest('Service Log successfully Aprroved'));
  };

  return (
    <div className="p-10 w-full">
      <div className=" top-20 z-50 bg-white border">
        <div onClick={onClickBack} className="cursor-pointer text-right m-4 text-lg font-bold text-[#95969B]">
          X
        </div>
        <div className="flex md:flex-row flex-col bg-white p-2 pb-6 gap-2">
          {arr && (
            <div className="schedule-control-section md:w-[72%]">
              <div className="flex justify-between px-4">
                <button className="text-2xl font-black" onClick={prev}>
                  {'<'}
                </button>
                <p className="text-lg font-bold">{h ?? ' '}</p>
                <button className="text-2xl font-black" onClick={next}>
                  {'>'}
                </button>
              </div>
              <div className="mt-6" style={{ overflowX: 'auto' }}>
                <div style={{ width: '100%', minWidth: '480px' }}>
                  <FullCalendarY calendarRef={calendarRef} arr={arr} transformedArray={newData} />
                </div>
              </div>
            </div>
          )}
          {arr && (
            <div className="grow">
              <h1 className="text-base font-extrabold">{`${employee}'s Assignments`}</h1>
              {status == '2' && (
                <div className="mt-8 mb-5">
                  <div className="flex justify-end">
                    <Button
                      buttonText="Discard Service Log"
                      onClick={() => onClickDiscard(id1, id2)}
                      className="bg-[#F9423C] text-white hover:bg-[red]"
                      type="button"
                    />
                    <Button
                      buttonText="Remind To Complete "
                      onClick={() => onClickRemind(id1, id2)}
                      className="ml-3 bg-[#F9423C] text-white hover:bg-[red]"
                      type="button"
                    />
                    <Button
                      buttonText="Approve Service Log"
                      onClick={() => onClickApprove(id1, id2)}
                      className="ml-3 bg-[#31D78D] text-white"
                      type="button"
                    />
                  </div>
                </div>
              )}
              <div className="mt-8">
                <ServiceLogCard
                  data={newDataCard}
                  navigateEdit={navigateEdit}
                  Admin={true}
                  navigateEditRequest={navigateEditRequest}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
