import { useEffect, useMemo, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RequestErrorAssignFormToClient from './RequestErrorAssignFormToClient';
import requestHttp from '../../http/requestHttp/requestHttp';
import AssignFormToClientDefaultValue from './AssignFormToClientDefaultValue';
import Select from '../../components/Select/Select';
import ClientHttp from '../../http/clientsHttp/clientsHttp';

export default function AssignFormToClient() {
  const [allClientsData, setAllClientsData] = useState([]);
  const [allRequestForms, setRequestFroms] = useState([]);

  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm({
    defaultValues: useMemo(
      () => AssignFormToClientDefaultValue(allClientsData, allRequestForms),
      [allClientsData, allRequestForms],
    ),
    resolver: yupResolver(useMemo(() => RequestErrorAssignFormToClient(), [])),
  });

  const navigate = useNavigate();

  useEffect(() => {
    ClientHttp.getAllClientss().then(e => {
      setAllClientsData(e?.data?.data);
    });
    requestHttp.getRequestForms('').then(e => {
      const forms = e?.data?.data?.map(elem => ({
        id: elem?.id,
        name: elem?.title,
      }));
      setRequestFroms(forms);
    });
  }, []);

  useEffect(() => {
    reset(AssignFormToClientDefaultValue(allClientsData, allRequestForms));
  }, [allClientsData, allRequestForms]);

  const onSubmit = async data => {
    navigate(`/Admin/FillForm/${data?.form_id}`, { state: { data: data } });
  };

  const onClickCancel = () => {
    navigate('/Admin/Requests');
  };

  return (
    <div className="sm:px-8 px-2 py-8 w-full ">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={`Assign Form`} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="w-full bg-white p-4 rounded-md">
            <div className="grid md:grid-cols-2 gap-2 items-end">
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Select Client Company'}
                  selectData={allClientsData}
                  selectClass="flex-col gap-2"
                  name={'school_id'}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_id?.message}</p>}
              </div>
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Select Request Form'}
                  selectData={allRequestForms}
                  selectClass="flex-col gap-2"
                  name={'form_id'}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.form_id?.message}</p>}
              </div>
            </div>
          </div>
          <div className="mt-40 z-50">
            <CancleAdd buttonText={`Next`} onClickCancel={onClickCancel} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
