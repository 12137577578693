import Button from '../../components/Button/Button';
import ServicesCard from '../../components/ServicesCard/ServicesCard';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { editServiceSlice } from '../../redux/serviceSlice/serviceSlice';
import Pagination from '../../components/Pagination/Pagination';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import Service from '../../http/seviceHttp/seviceHttp';
import { columnsService, onClickAddService } from './helpers/ServicesUtils';

export default function Services() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [paginationLinks, setPaginationLinks] = useState([]);

  // Memoized handler for editing a service
  const handleEditClick = useCallback((id) => {
    dispatch(editServiceSlice(id));
    navigate(`/Admin/EditService/${id}`);
  }, [dispatch, navigate]);

  // Memoized handler for pagination
  const handlePaginationClick = useCallback((pageId) => {
    Service.servicePaginationLinks(pageId)
      .then((response) => {
        setData(response?.data?.data || []);
        setPaginationLinks(response?.data?.links || []);
      })
      .catch((error) => console.error('Error fetching paginated data:', error));
  }, []);

  // Fetch services on initial render
  useEffect(() => {
    Service.serviceLinks()
      .then((response) => {
        setData(response?.data?.data || []);
        setPaginationLinks(response?.data?.links || []);
      })
      .catch((error) => console.error('Error fetching services:', error));
  }, []);

  // Memoized columns for the table
  const memoizedColumns = useMemo(() => columnsService(handleEditClick), [handleEditClick]);

  return (
    <div className="w-full sm:p-8 p-2">
      {/* Header Section */}
      <TitleBlok
        src="/TitleBlokIcon.svg"
        textItem="services"
        classNameTitleBlock="mb-8"
        className="text-base capitalize font-semibold"
      >
        <Button
          onClick={() => onClickAddService(navigate)}
          buttonText="+ Add Service"
          className="text-[#fff]"
        />
      </TitleBlok>

      {/* Services Grid */}
      <div className="grid md:grid-cols-2 sm:gap-6 gap-2 overflow-x-auto max-h-[400px]">
        {data?.map((service) => (
          <div key={service.id} className="grow">
            <ServicesCard
              image={service.image}
              onClick={() => handleEditClick(service.id)}
              employees={service.employees}
              title={service.name}
              servicesCardItem={service?.servicesCardItem}
              text={service?.details}
              clients={service.clients}
              id={service?.id}
            />
          </div>
        ))}
      </div>

      {/* Service List Section */}
      <div className="mt-8">
        <TitleBlok
          src="/TitleBlokIcon.svg"
          textItem="Service List"
          className="capitalize font-semibold"
          classNameTitleBlock="border"
        />
      </div>

      {/* Table and Pagination */}
      <div className="mt-4">
        <div className="px-4 py-1 bg-white rounded-xl">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full">
              <TableEmployeesAdmin
                tbody={data}
                columns={memoizedColumns}
                maxHeight="400px"
              />
            </div>
          </div>
          <div>
            <Pagination paginationData={paginationLinks} onPaginationClick={handlePaginationClick} />
          </div>
        </div>
      </div>
    </div>
  );
}
