import { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import companyFormsHttp from '../../http/companyFormsHttp/companyFormsHttp';
import Pagination from '../../components/Pagination/Pagination';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';
import InfoListModal from '../../components/Modal/InfoListModal';
import TableClients from '../../components/DataTable/TableClients';
import { columnsCompanyFormsAdmin } from './helpers/CompanyFormsUtils';

export default function CompanyForms() {
  const navigate = useNavigate();

  // State management
  const [companyForms, setCompanyForms] = useState([]);
  const [paginationData, setPaginationData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [isEmployeeListModalOpen, setIsEmployeeListModalOpen] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);

  // Fetch company forms data
  const fetchCompanyForms = useCallback(async (page = '') => {
    try {
      const response = await companyFormsHttp.getAllCompanyForms(page);
      setPaginationData(response?.data);
      setCompanyForms(response?.data?.data || []);
    } catch (error) {
      console.error('Error fetching company forms:', error);
    }
  }, []);

  useEffect(() => {
    fetchCompanyForms();
  }, [fetchCompanyForms, isLoading]);

  // Handle pagination click
  const handlePaginationClick = useCallback((pageId) => {
    fetchCompanyForms(pageId);
  }, [fetchCompanyForms]);

  // Handle delete confirmation
  const handleDeleteConfirmation = useCallback((formId) => {
    setIsDeleteModalOpen(true);
    setSelectedFormId(formId);
  }, []);

  // Confirm deletion of a company form
  const handleConfirmDelete = useCallback(async () => {
    try {
      const response = await companyFormsHttp.deleteCompanyForms(selectedFormId);
      if (response.status === 200) {
        setIsLoading((prev) => !prev);
      }
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting company form:', error);
    }
  }, [selectedFormId]);

  // Handle viewing employee list
  const handleViewEmployeeList = useCallback((list) => {
    if (list?.length > 1) {
      setEmployeeList(list);
      setIsEmployeeListModalOpen(true);
    }
  }, []);

  // Handle edit company form
  const handleEditForm = useCallback((formId) => {
    navigate(`/Admin/EditCompanyForm/${formId}`);
  }, [navigate]);

  // Memoize table columns to avoid unnecessary re-renders
  const tableColumns = useMemo(() => 
    columnsCompanyFormsAdmin(handleViewEmployeeList, handleEditForm, handleDeleteConfirmation), 
    [handleViewEmployeeList, handleEditForm, handleDeleteConfirmation]
  );

  // Render component
  return (
    <div className="w-full sm:p-8 p-2">
      {/* Delete Confirmation Modal */}
      <CompanyFormModal
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        onClickYes={handleConfirmDelete}
        companyFormId={selectedFormId}
      />

      {/* Employee List Modal */}
      <InfoListModal
        open={isEmployeeListModalOpen}
        setOpen={setIsEmployeeListModalOpen}
        List={employeeList}
      />

      {/* Page Title */}
      <TitleBlok
        src="/CompanyFormsIcon.svg"
        textItem="Company Forms Employees"
        className="text-base font-bold"
      >
        <div className="flex sm:gap-4 gap-2">
          <Button
            onClick={() => navigate('/Admin/CreateCompanyForm')}
            buttonText="Create Company Form"
            className="text-[#fff] sm:text-sm text-xs"
          />
        </div>
      </TitleBlok>

      {/* Table and Pagination */}
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className="w-full overflow-x-auto">
          <div className="inline-block min-w-full">
            <TableClients
              maxHeight="500px"
              tbody={companyForms}
              columns={tableColumns}
            />
          </div>
        </div>
        <Pagination
          onPaginationClick={handlePaginationClick}
          paginationData={paginationData?.links}
        />
      </div>
    </div>
  );
}
