import { Fragment, useEffect, useMemo, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useNavigate } from 'react-router-dom';
import { formatStringForName, notifySuccess } from '../../utils/utils';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer } from 'react-toastify';
import InputLabel from '../../components/InputLabel/InputLabel';
import requestHttp from '../../http/requestHttp/requestHttp';
import FormFields from './components/FormFields';
import RequestErrorCreateRequestForm from './RequestErrorCreateRequestForm';
import CreateRequestFormDefaultValue from './CreateRequestFormDefaultValue';

import 'react-toastify/dist/ReactToastify.css';

export default function CreateRequestForm() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
    reset,
  } = useForm({
    defaultValues: useMemo(() => CreateRequestFormDefaultValue(), []),
    resolver: yupResolver(RequestErrorCreateRequestForm()),
  });
  const navigate = useNavigate();

  const afterSubmit = () => {
    notifySuccess('Request Form successfully created');
    setTimeout(() => {
      navigate('/Admin/RequestForms');
    }, 2000);
  };

  const onSubmit = async data => {
    const SendData = {
      title: data?.title,
      included_file: data?.included_file ? true : false,
      included_email: data?.included_email ? true : false,
      fields: data?.fields?.map((elem, index) => ({
        // id: elem?.id,
        label: elem?.label,
        name: formatStringForName(elem?.label),
        is_required: true,
        type: elem?.type,
        options:
          elem?.type === 'checkbox'
            ? elem?.options?.map((item, indexOpt) => ({
                name: item?.name,
              }))
            : null,
      })),
    };
    setIsLoading(true);
    requestHttp
      ?.createRequestForm(SendData)
      .then(() => afterSubmit())
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false);
      });
  };

  const onClickCancel = () => {
    navigate('/Admin/RequestFroms');
  };

  const {
    fields: Fields,
    append: FieldsAppend,
    remove: FieldsRemove,
  } = useFieldArray({
    control,
    name: 'fields',
  });

  const onClickAddNewField = () => {
    FieldsAppend({
      type: '',
      label: '',
    });
  };

  useEffect(() => {
    reset(CreateRequestFormDefaultValue());
  }, []);

  return (
    <div className="sm:px-8 px-2 py-8 w-full ">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={'Create Request Form'} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="w-full bg-white p-4 rounded-md">
            <div className="grid md:grid-cols-2 sm:gap-6 gap-2 items-end">
              <div className="mt-5">
                <InputLabel
                  classNameInput="flex flex-col"
                  classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                  className="w-full border p-[7px]"
                  labelTitle={'Request Form Title'}
                  placeholder={'Request Title'}
                  register={register}
                  name={`title`}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.title?.message}</p>}
              </div>
              <div className="grid md:grid-cols-2 sm:gap-6 gap-2 items-end">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id={`included_file`}
                      aria-describedby="comments-description"
                      name={`included_file`}
                      {...register(`included_file`)}
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor={`included_file`} className="font-medium text-gray-900">
                      Allow to upload documents
                    </label>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id={`included_email`}
                      aria-describedby="comments-description"
                      name={`included_email`}
                      {...register(`included_email`)}
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor={`included_email`} className="font-medium text-gray-900">
                      Additional Email ability
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {Fields.map((elem, index) => (
                <Fragment key={elem?.id}>
                  <FormFields
                    register={register}
                    errors={errors}
                    index={index}
                    FieldsRemove={FieldsRemove}
                    onClickAddNewField={onClickAddNewField}
                    control={control}
                    setValue={setValue}
                  />
                </Fragment>
              ))}
            </div>
          </div>

          <div className="mt-40 z-50">
            <CancleAdd disabled={isLoading} buttonText={`Create`} onClickCancel={onClickCancel} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
