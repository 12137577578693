import { useEffect, useState, useCallback } from 'react';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import companyFormsHttp from '../../http/companyFormsHttp/companyFormsHttp';
import Pagination from '../../components/Pagination/Pagination';
import TableClients from '../../components/DataTable/TableClients';
import { columnsCompanyFormsClient } from './helpers/CompanyFormsClientUtils';

export default function CompanyFormsClient() {
  const [companyFormList, setCompanyFormList] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch company forms data
  const fetchCompanyForms = useCallback(async (pageId = '') => {
    setLoading(true);
    setError(null);
    try {
      const response = await companyFormsHttp.getAllCompanyFormsClient(pageId);
      setCompanyFormList(response?.data?.data || []);
      setPaginationData(response?.data || {});
    } catch (err) {
      setError('Failed to fetch company forms');
      console.error('Error fetching company forms:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCompanyForms();
  }, [fetchCompanyForms]);

  // Handle pagination click
  const handlePaginationClick = useCallback((pageId) => {
    fetchCompanyForms(pageId);
  }, [fetchCompanyForms]);

  return (
    <div className="sm:px-8 px-4 py-8 w-full">
      <TitleBlok textItem="My Forms" className="text-base font-semibold" src="/CompanyFormsIcon.svg" />
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        {error && <div className="text-red-500">{error}</div>}
        <div className={`w-full overflow-x-auto ${loading ? 'animate-pulse' : ''}`}>
          <div className="inline-block min-w-full">
            <TableClients
              tbody={companyFormList}
              columns={columnsCompanyFormsClient()}
              maxHeight="500px"
            />
          </div>
        </div>
        <Pagination
          onPaginationClick={handlePaginationClick}
          paginationData={paginationData?.links}
        />
      </div>
    </div>
  );
}
