
import { FaArrowUpLong,FaArrowDownLong } from "react-icons/fa6";

export function formatDate(dateString) {
  if (dateString) {
    const [year, month, day] = dateString?.split('-');

    return `${month}/${day}/${year}`;
  } else return '';
}

export const statuses = [
    { name: 'All Statuses', id: '' },
    { name: 'Open', id: 'Open' },
    { name: 'In Process', id: 'In Process' },
    { name: 'Completed', id: 'Completed' },
    { name: 'Approved', id: 'Approved' },
  ];

export const columnsRequest = (
  onClickEmployeeList,
  onClickEdit,
  onClickRemove,
  onClickAssignEmployees,
  onClickAccept,
  onClickUnAccept,
  setIsDesc,
  isDesc,
) => [
  {
    accessorKey: 'id',
    header: 'Id', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 50,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.id}</p>,
  },
  {
    accessorKey: 'name',
    header: 'name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 130,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.name}</p>,
  },
  {
    accessorKey: 'form_type',
    header: 'Form Type', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 130,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.title}</p>,
  },
  {
    accessorKey: 'employees',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 140,
    Cell: ({ row }) => (
      <p
        className="whitespace-nowrap cursor-pointer"
        onClick={() =>
          onClickEmployeeList({
            showStatus: true,
            array: row?.original?.['employees']?.map(elem => {
              return {
                name: elem?.name,
                status: elem?.status,
              };
            }),
          })
        }>
        {row?.original?.['employees']?.map(
          ({ name }, index, array) => `${name + `${index !== array?.length - 1 ? `,` : ' '} `}`,
        )}
      </p>
    ),
  },
  {
    accessorKey: 'client',
    header: 'Client', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 120,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.school}</p>,
  },
  {
    accessorKey: 'Date',
      Header: ({ row }) => (
         <div className="relative flex items-start " onClick={() => setIsDesc(prev => (prev === 'desc' ? 'asc' : 'desc'))}>
           <div className=" text-sm leading-6 ">
             <label htmlFor={'masterCheckbox'} className="text-[#495057] font-bold cursor-pointer">
               {'Date'}
             </label>
           </div>
           <div className="flex h-6 items-center ml-1">{isDesc === 'desc' ? <FaArrowDownLong /> :  <FaArrowUpLong />}</div>
         </div>
       ),
    // hishem avelacnem
    size: 80,
    Cell: ({ row }) => <p className="whitespace-nowrap">{formatDate(row?.original?.created_at)}</p>,
  },
  {
    accessorKey: 'completion_date',
    header: 'Completion Date', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 80,
    Cell: ({ row }) => (
      <p className="whitespace-nowrap cursor-pointer">
        {/* {row?.original?.['employees']?.map(
          ({ deadline }, index, array) => `${formatDate(deadline) + `${index !== array?.length - 1 ? `,` : ' '} `}`,
        )} */}
        {formatDate(row?.original?.completion_date)}
      </p>
    ),
    // <p className="whitespace-nowrap">
    //   {formatDate(row?.original?.deadline)}
    // </p>,
  },
  {
    accessorKey: 'status',
    header: 'Status', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 60,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.status}</p>,
  },

  {
    accessorKey: 'action',
    header: 'Action',
    size: 360, //decrease the width of this column
    // maxSize: 250,
    Cell: ({ row }) => (
      <div className="flex gap-2 cursor-pointer">
        {onClickEdit && (
          <button
            onClick={() => onClickEdit(row?.original?.id)}
            className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
            type="button">
            {row?.original?.status === 'Open' || row?.original?.status === 'In Process' ? 'Edit' : 'Review'}
          </button>
        )}
        {((onClickAccept && row?.original?.status === 'Open') ||
          (onClickAccept && row?.original?.status === 'In Process')) &&
          onClickAssignEmployees &&
          row?.original?.name && (
            <button
              onClick={() =>
                onClickAssignEmployees(row?.original?.id, row?.original?.title, row?.original?.['employees'],row?.original?.['employees']?.length > 0)
              }
              className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
              type="button">
              {row?.original?.['employees']?.length > 0 ? 'Edit Assigned Employees' : 'Assign Employees'}
            </button>
          )}
        {onClickUnAccept && row?.original?.status === 'Approved' && (
          <button
            onClick={() => onClickUnAccept(row?.original?.id)}
            className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
            type="button">
            Reverse Approval
          </button>
        )}
        {onClickRemove && (
          <img
            src="/RemoveSVG.svg"
            alt="Remove"
            onClick={() => onClickRemove(row?.original?.['id'])}
            className="cursor-pointer"
          />
        )}
      </div>
    ),
  },
];
