export const EditFormAdminDefaultValue = form => {
  return {
    email:form?.email,
    name: form?.name,
    acceptable_hours: form?.acceptable_hours,
    // hourly_rate: form?.hourly_rate,
    deadline: form?.deadline,
    completion_date: form?.completion_date,
    review: form?.review,
    formFields: form?.formFields?.map(({ id, name, label, type, value, options_selected }) => ({
      id: id,
      name: name,
      label: label,
      type: type,
      value: value,
      options:
        type === 'checkbox'
          ? options_selected?.map(elem => ({
              name: elem?.name,
              [elem?.name]: elem?.[elem?.name] === "false" ? false : elem?.[elem?.name],
              // a:true
            }))
          : null,
    })),
    activities:
      form?.activities?.length >= 1
        ? form?.activities?.map(elem => ({
            id: elem?.id,
            hours: elem?.hours,
            activity: elem?.activity,
          }))
        : [{}],
  };
};

export default EditFormAdminDefaultValue;
