import { useEffect, useMemo, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { notifySuccess } from '../../utils/utils';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import RequestErrorAssignRequestToEmployee from './RequestErrorAssignRequestToEmployee';
import requestHttp from '../../http/requestHttp/requestHttp';
import AssignRequestToEmployeeDefaultValue from './AssignRequestToEmployeeDefaultValue';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InnerFields from './components/InnerFields';

export default function AssignRequestToEmployee() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const params = useParams();
  const { state } = useLocation();

  const isEmpty = state?.employees?.length == 0;

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    register,
    reset,
    control,
  } = useForm({
    defaultValues: useMemo(() => AssignRequestToEmployeeDefaultValue(), []),
    resolver: yupResolver(useMemo(() => RequestErrorAssignRequestToEmployee(isEmpty), [isEmpty])),
  });

  const navigate = useNavigate();

  // employee_ids

  const afterSubmit = () => {
    notifySuccess('Request Successfully Assigned');
    setTimeout(() => {
      navigate('/Admin/Requests');
    }, 2000);
  };

  const onSubmit = async data => {
    setIsLoading(true);
    const sendData = {
      employee_ids: data?.employee_ids?.map(elem => {
        return {
          service_id: elem?.service_id,
          deadline: elem?.deadline,
          id: elem?.id,
          position_id: elem?.position_id,
          hourly_rate: elem?.hourly_rate
        };
      }),
    };
    requestHttp
      ?.assignRequestFormToEmployees(params?.id, sendData)
      .then(() => afterSubmit())
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false);
      });
  };

  const onClickCancel = () => {
    navigate('/Admin/Requests');
  };

  useEffect(() => {
    requestHttp?.getSubmittedEmployeesAdmin(params?.id).then(e => setData(e?.data?.data));
  }, []);

  useEffect(() => {
    reset(AssignRequestToEmployeeDefaultValue());
  }, []);

  const {
    fields: Fields,
    append: Append,
    remove: Remove,
  } = useFieldArray({
    control,
    name: `employee_ids`,
  });

  const onClickAppend = () => {
    Append({});
  };

  return (
    <div className="sm:px-8 px-2 py-8 w-full ">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title={`Assign employees to ${data?.name}`}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          {Fields.map((item, index) => {
            return (
              <InnerFields
                key={item?.id}
                index={index}
                setValue={setValue}
                errors={errors}
                register={register}
                Remove={Remove}
                onClickAppend={onClickAppend}
                formId={params?.id}
              />
            );
          })}
          <div className="mt-40 z-50">
            <CancleAdd disabled={isLoading} buttonText={`Assign`} onClickCancel={onClickCancel} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
