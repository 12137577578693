export const GiveAccessClientDefaultValue = () => {
  return {
    email: '',
    access_for_invoices: true,
    access_for_service_logs: true,
    access_for_request: true,
  };
};

export default GiveAccessClientDefaultValue;
