import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Dashboard from '../Dashboard/Dashboard';
import Layout from '../../components/Layout/Layout';
import Employees from '../Employees/Employees';
import Reports from '../Reports/Reports';
import Clients from '../Clients/Clients';
import Services from '../Services/Services';
import AddEmployee from '../AddEmployee/AddEmployee';
import AddService from '../AddService/AddService';
import LoginPage from '../LoginPage/LoginPage';
import EditService from '../EditService/EditService';
import LoginEmployee from '../LoginEmployee/LoginEmployee';
import AddParentCompany from '../AddParentCompany/AddParentCompany';
import AddNewClient from '../AddNewClient/AddNewClient';
import EditParentCompany from '../EditParentCompany/EditParentCompany';
import EditClient from '../EditClient/EditClient';
import EditEmployees from '../EditEmployees/EditEmployees';
import OTPVerification from '../OTPVerification/OTPVerification';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import ResetPassword from '../ResetPassword/ResetPassword';
import PasswordChanged from '../PasswordChanged/PasswordChanged';
import AdminProfile from '../AdminProfile/AdminProfile';
import SetNewPasswordEmployee from '../SetNewPasswordEmployee/SetNewPasswordEmployee';
import ForgotPasswordEmployee from '../ForgotPasswordEmployee/ForgotPasswordEmployee';
import OTPVerificationEmployee from '../OTPVerificationEmployee/OTPVerificationEmployee';
import OTPVerificationClient from '../OTPVerificationClient/OTPVerificationClient';
import ResetPasswordEmployee from '../ResetPasswordEmployee/ResetPasswordEmployee';
import ServiceLogEmployee from '../ServiceLogEmployee/ServiceLogEmployee';
import CompanyFormsEmployee from '../CompanyFormsEmployee/CompanyFormsEmployee';
import ServiceLog from '../ServiceLog/ServiceLog';
import Invoices from '../Invoices/Invoices';
import CompanyForms from '../CompanyForms/CompanyForms';
import AddServiceLog from '../AddServiceLog/AddServiceLog';
import EditServiceLog from '../EditServiceLog/EditServiceLog';
import DashboardEmployee from '../DashboardEmployee/DashboardEmployee';
import Error from '../Error/Error';
import CreateInvoice from '../CreateInvoice/CreateInvoice';
import CompanyFormsClient from '../CompanyFormsClient/CompanyFormsClient';
import {
  Admin,
  Protected,
  Employee,
  ProtectedEmployee,
  ProtectedNotLogin,
  ProtectedEmployeeNotLogin,
  Client,
  ProtectedClientNotLogin,
  ProtectedClient,
  SuperAdmin,
  ProtectedSuperAdminNotLogin,
  ProtectedSuperAdmin,
} from '../Protected/Protected';
import SendInvoices from '../SendInvoices/SendInvoices';
import UnbillableHoursEmployee from '../UnbillableHoursEmployee/UnbillableHoursEmployee';
import AddUnbillableHours from '../AddUnbillableHours/AddUnbillableHours';
import EditUnbillableHours from '../EditUnbillableHours/EditUnbillableHours';
import UnbillableHoursAdmin from '../UnbillableHoursAdmin/UnbillableHoursAdmin';
import EditCompanyForm from '../EditCompanyForm/EditCompanyForm';
import Schedule from '../../components/Calendar/Scheduler/Scheduler';
import ScheduleUnbillableHours from '../../components/Calendar/SchedulerUnbillableHours/SchedulerUnbillableHours';
import ScheduleAdmin from '../../components/Calendar/SchedulerAdmin/SchedulerAdmin';
import EditServiceLogAdmin from '../EditServiceLogAdmin/EditServiceLogAdmin';
import SchedulerAdminUnbillableHours from '../../components/Calendar/SchedulerAdminUnbillableHours/SchedulerAdminUnbillableHours';
import EditUnbillableHoursAdmin from '../EditUnbillableHoursAdmin/EditUnbillableHoursAdmin';
import RegenerateInvoice from '../RegenerateInvoice/RegenerateInvoice';
import Payments from '../Payments/Payments';
import LoginClient from '../LoginClient/LoginClient';
import SetNewPasswordClient from '../SetNewPasswordClient/SetNewPasswordClient';
import ForgotPasswordClient from '../ForgotPasswordClient/ForgotPasswordClient';
import ResetPasswordClient from '../ResetPasswordClient/ResetPasswordClient';
import ServiceLogClient from '../ServiceLogClient/ServiceLogClient';
import GiveAccessClient from '../GiveAccessClient/GiveAccessClient';
import ClientProfile from '../ClientProfile/ClientProfile';
import InvoicesClient from '../InvoicesClient/InvoicesClient';
import EmployeeActions from '../EmployeeActions/EmployeeActions';
import MainPage from '../MainPage/MainPage';
import AddServiceLogAdmin from '../AddServiceLogAdmin/AddServiceLogAdmin';
import LoginSuperAdmin from '../LoginSuperAdmin/LoginSuperAdmin';
import ForgotPasswordSuperAdmin from '../ForgotPasswordSuperAdmin/ForgotPasswordSuperAdmin';
import OTPVerificationSuperAdmin from '../OTPVerificationSuperAdmin/OTPVerificationSuperAdmin';
import ResetPasswordSuperAdmin from '../ResetPasswordSuperAdmin/ResetPasswordSuperAdmin';
import SuperAdminClient from '../SuperAdminClient/SuperAdminClient';
import EmployeesResendAccountInvite from '../EmployeesResendAccountInvite/EmployeesResendAccountInvite';
import EmployeesBlockDays from '../EmployeesBlockDays/EmployeesBlockDays';
import SetBlockDays from '../SetBlockDays/SetBlockDays';
import TermsAndConditions from '../MainPage/pages/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../MainPage/pages/PrivacyPolicy/PrivacyPolicy';
import ExportInvoices from '../ExportInvoices/ExportInvoices';
import CreateCusomLog from '../CreateCusomLog/CreateCusomLog';
import RegenerateCustomLog from '../RegenerateCustomLog/RegenerateCustomLog';
import SendCustomLogs from '../SendCustomLogs/SendCustomLogs';
import PublishServiceLogs from '../PublishServiceLogs/PublishServiceLogs';
import PublishCustomLogs from '../PublishCustomLogs/PublishCustomLogs';
import AddInternalEmployee from '../AddInternalEmployee/AddInternalEmployee';
import EditNonbillableEmployees from '../EditNonbillableEmployees/EditNonbillableEmployees';
import RemindPayment from '../RemindPayment/RemindPayment';
import GiveAccessParent from '../GiveAccessParent/GiveAccessParent';
import SendServiceLogs from '../SendServiceLogs/SendServiceLogs';
import SendNotificationDashboard from '../SendNotificationDashboard/SendNotificationDashboard';
import SendNotification from '../SendNotification/SendNotification';
import EditAccessClient from '../EditAccessClient/EditAccessClient';
import EditAccessParent from '../EditAccessParent/EditAccessParent';
import PublishInvoices from '../PublishInvoices/PublishInvoices';
import EditInvoice from '../EditInvoice/EditInvoice';
import CompanyFormsClientAdmin from '../CompanyFormsClientAdmin/CompanyFormsClientAdmin';
import CreateCompanyFormClients from '../CreateCompanyFormClients/CreateCompanyFormClients';
import EditCompanyFormClient from '../EditCompanyFormClient/EditCompanyFormClient';
import Requests from '../Requests/Requests';
import Cases from '../Cases/Cases';
import CreateCase from '../CreateCase/CreateCase';
import EditCase from '../EditCase/EditCase';
import CreateCompanyFormNew from '../CreateCompanyFormNew/CreateCompanyFormNew';
import CreateCaseInvoice from '../CreateCaseInvoice/CreateCaseInvoice';
import CreateCustomLogCase from '../CreateCusomLogCase/CreateCusomLogCase';
import SendInvoicesCase from '../SendInvoicesCase/SendInvoicesCase';
import PublishInvoicesCase from '../PublishInvoicesCase/PublishInvoicesCase';
import SendCustomLogsCase from '../SendCustomLogsCase/SendCustomLogsCase';
import PublishCustomLogsCase from '../PublishCustomLogsCase/PublishCustomLogsCase';
import RequestForms from '../RequestForms/RequestForms';
import CreateRequestForm from '../CreateRequestForm/CreateRequestForm';
import EditRequestForm from '../EditRequestForm/EditRequestForm';
import AssignRequestFormToClients from '../AssignRequestFormToClients/AssignRequestFormToClients';
import RequestTypesClient from '../RequestTypesClient/RequestTypesClient';
import FillFormClient from '../FillFormClient/FillFormClient';
import EditFormClient from '../EditFormClient/EditFormClient';
import MyRequestsClient from '../MyRequestsClient/MyRequestsClient';
import EditServiceLogCustom from '../EditServiceLogCustom/EditServiceLogCustom';
import EditFormAdmin from '../EditFormAdmin/EditFormAdmin';
import AssignRequestToEmployee from '../AssignRequestToEmployee/AssignRequestToEmployee';
import MyRequestsEmployee from '../MyRequestsEmployee/MyRequestsEmployee';
import ViewRequestFormEmployee from '../ViewRequestFormEmployee/ViewRequestFormEmployee';
import AddServiceLogRequest from '../AddServiceLogRequest/AddServiceLogRequest';
import EditServiceLogRequest from '../EditServiceLogRequest/EditServiceLogRequest';
import AddServiceLogRequestAdmin from '../AddServiceLogRequestAdmin/AddServiceLogRequestAdmin';
import EditServiceLogRequestAdmin from '../EditServiceLogRequestAdmin/EditServiceLogRequestAdmin';
import AssignFormToClient from '../AssignFormToClient/AssignFormToClient';
import FillFormAdmin from '../FillFormAdmin/FillFormAdmin';
import RequestServiceLogs from '../RequestServiceLogs/RequestServiceLogs';
import CreateRequestLog from '../CreateRequestLog/CreateRequestLog';
import SendRequestLogAdmin from '../SendRequestLogAdmin/SendRequestLogAdmin';
import PublishRequestLogAdmin from '../PublishRequestLogAdmin/PublishRequestLogAdmin';
import RequestServiceLogsClient from '../RequestServiceLogsClient/RequestServiceLogsClient';
import ViewFormClient from '../ViewFormClient/ViewFormClient';
import CreateInvoiceRequest from '../CreateInvoiceRequest/CreateInvoiceRequest';
import EditRequestToEmployees from '../EditRequestToEmployee/EditRequestToEmployee';
import SendInvoicesRequest from '../SendInvoicesRequest/SendInvoicesRequest';
import PublishInvoicesRequest from '../PublishInvoicesRequest/PublishInvoicesRequest';

const RootContainer = () => {
  return (
    <Router>
      <Routes>
        <Route element={<Employee />}>
          <Route element={<ProtectedEmployeeNotLogin />}>
            <Route path="Employee/LoginPage" element={<LoginEmployee />} />
            <Route path="Employee/NewPasswordEmployee" element={<SetNewPasswordEmployee />} />
            <Route path="Employee/OTPVerification" element={<OTPVerificationEmployee />} />
            <Route path="Employee/ForgotPassword" element={<ForgotPasswordEmployee />} />
            <Route path="Employee/ResetPassword" element={<ResetPasswordEmployee />} />
            <Route path="Employee/PasswordChanged" element={<PasswordChanged />} />
          </Route>
          <Route element={<ProtectedEmployee />}>
            <Route path="/Employee" element={<Layout />}>
              <Route path="Dashboard" element={<DashboardEmployee />} />
              <Route path="Profile" element={<AdminProfile />} />
              <Route path="CompanyForms" element={<CompanyFormsEmployee />} />
              <Route path="MyRequests" element={<MyRequestsEmployee />} />
              <Route path="ServiceLog" element={<ServiceLogEmployee />} />
              <Route path="UnbillableHours" element={<UnbillableHoursEmployee />} />
              <Route path="AddServiceLog" element={<AddServiceLog />} />
              <Route path="AddUnbillableHours" element={<AddUnbillableHours />} />
              <Route path="EditServiceLog/:id" element={<EditServiceLog />} />
              <Route path="EditServiceLogRequest/:id" element={<EditServiceLogRequest />} />
              <Route path="AddServiceLogRequest" element={<AddServiceLogRequest />} />
              <Route path="EditUnbillableHours/:id" element={<EditUnbillableHours />} />
              <Route path="ServiceLog/Calendar/:id1" element={<Schedule />} />
              <Route path="UnbillableHours/Calendar/:id1" element={<ScheduleUnbillableHours />} />
              <Route path="ViewRequestForm/:id" element={<ViewRequestFormEmployee />} />
            </Route>
          </Route>
          <Route path={'/Employee/*'} element={<Error />} />
        </Route>
        <Route element={<Client />}>
          <Route element={<ProtectedClientNotLogin />}>
            <Route path="Client/LoginPage" element={<LoginClient />} />
            <Route path="Client/NewPasswordClient" element={<SetNewPasswordClient />} />
            <Route path="Client/OTPVerification" element={<OTPVerificationClient />} />
            <Route path="Client/ForgotPassword" element={<ForgotPasswordClient />} />
            <Route path="Client/ResetPassword" element={<ResetPasswordClient />} />
            <Route path="Client/PasswordChanged" element={<PasswordChanged />} />
          </Route>
          <Route element={<ProtectedClient />}>
            <Route path="/Client" element={<Layout />}>
              <Route path="ServiceLog" element={<ServiceLogClient />} />
              <Route path="Invoices" element={<InvoicesClient />} />
              <Route path="CompanyForms" element={<CompanyFormsClient />} />
              <Route path="FillForm/:id" element={<FillFormClient />} />
              <Route path="EditForm/:id" element={<EditFormClient />} />
              <Route path="ViewForm/:id" element={<ViewFormClient />} />
              <Route path="RequestTypes" element={<RequestTypesClient />} />
              <Route path="MyRequests" element={<MyRequestsClient />} />
              <Route path="RequestServiceLogs" element={<RequestServiceLogsClient />} />
              <Route path="Profile" element={<ClientProfile />} />
            </Route>
          </Route>
          <Route path={'/Client/*'} element={<Error />} />
        </Route>
        <Route element={<SuperAdmin />}>
          <Route element={<ProtectedSuperAdminNotLogin />}>
            <Route path="SuperAdmin/LoginPage" element={<LoginSuperAdmin />} />
            {/* <Route path='SuperAdmin/NewPasswordClient' element={<SetNewPasswordClient />} /> */}
            <Route path="SuperAdmin/OTPVerification" element={<OTPVerificationSuperAdmin />} />
            <Route path="SuperAdmin/ForgotPassword" element={<ForgotPasswordSuperAdmin />} />
            <Route path="SuperAdmin/ResetPassword" element={<ResetPasswordSuperAdmin />} />
            <Route path="SuperAdmin/PasswordChanged" element={<PasswordChanged />} />
          </Route>
          <Route element={<ProtectedSuperAdmin />}>
            <Route path="/SuperAdmin" element={<Layout />}>
              <Route path="Dashboard" element={<DashboardEmployee />} />
              <Route path="Clients" element={<SuperAdminClient />} />
              <Route path="Profile" element={<ClientProfile />} />
            </Route>
          </Route>
          <Route path={'/SuperAdmin/*'} element={<Error />} />
        </Route>
        <Route element={<Admin />}>
          <Route element={<ProtectedNotLogin />}>
            <Route path="Admin/LoginPage" element={<LoginPage />} />
            <Route path="Admin/ForgotPassword" element={<ForgotPassword />} />
            <Route path="Admin/OTPVerification" element={<OTPVerification />} />
            <Route path="Admin/ResetPassword" element={<ResetPassword />} />
            <Route path="Admin/PasswordChanged" element={<PasswordChanged />} />
            <Route path="/Admin/*" element={<Error />} />
          </Route>
          <Route element={<Protected />}>
            <Route path="/Admin" element={<Layout />}>
              <Route path="Dashboard" element={<Dashboard />} />
              <Route path="Employees" element={<Employees />} />
              <Route path="EmployeesBlockDays" element={<EmployeesBlockDays />} />
              <Route path="SendNotificationDashboard" element={<SendNotificationDashboard />} />
              <Route path="EmployeeActions" element={<EmployeeActions />} />
              <Route path="EmployeesResendAccountInvite" element={<EmployeesResendAccountInvite />} />
              <Route path="EmployeeSetBlockDays" element={<SetBlockDays />} />
              <Route path="Reports" element={<Reports />} />
              <Route path="Clients" element={<Clients />} />
              <Route path="Services" element={<Services />} />
              <Route path="AddEmployee" element={<AddEmployee />} />
              <Route path="AddNonbillableEmployee" element={<AddInternalEmployee />} />
              <Route path="AddParentCompany" element={<AddParentCompany />} />
              <Route path="SendNotification" element={<SendNotification />} />
              <Route path="AddNewClient" element={<AddNewClient />} />
              <Route path="GiveAccessClient/:id" element={<GiveAccessClient />} />
              <Route path="EditAccessClient/:id" element={<EditAccessClient />} />
              <Route path="GiveAccessParent/:id" element={<GiveAccessParent />} />
              <Route path="EditAccessParent/:id" element={<EditAccessParent />} />
              <Route path="AddService" element={<AddService />} />
              <Route path="EditService/:id" element={<EditService />} />
              <Route path="EditParentCompany/:id" element={<EditParentCompany />} />
              <Route path="EditClient/:id" element={<EditClient />} />
              <Route path="ServiceLog" element={<ServiceLog />} />
              <Route path="UnbillableHours" element={<UnbillableHoursAdmin />} />
              <Route path="SendServiceLogs" element={<SendServiceLogs />} />
              <Route path="SendServiceLogsCase" element={<SendServiceLogs />} />
              <Route path="PublishServiceLogs" element={<PublishServiceLogs />} />
              <Route path="SendCustomLogs" element={<SendCustomLogs />} />
              <Route path="SendCustomLogsCase" element={<SendCustomLogsCase />} />
              <Route path="PublishCustomLogs" element={<PublishCustomLogs />} />
              <Route path="PublishCustomLogsCase" element={<PublishCustomLogsCase />} />
              <Route path="PublishInvoices" element={<PublishInvoices />} />
              <Route path="PublishInvoicesRequest" element={<PublishInvoicesRequest />} />
              <Route path="PublishInvoicesCase" element={<PublishInvoicesCase />} />
              <Route path="SendInvoices" element={<SendInvoices />} />
              <Route path="SendInvoicesRequest" element={<SendInvoicesRequest />} />
              <Route path="SendRequestLogAdmin" element={<SendRequestLogAdmin />} />
              <Route path="PublishRequestLogAdmin" element={<PublishRequestLogAdmin />} />
              <Route path="SendInvoicesCase" element={<SendInvoicesCase />} />
              <Route path="RemindPayment" element={<RemindPayment />} />
              <Route path="ExportInvoices" element={<ExportInvoices />} />
              <Route path="EditEmployees/:id" element={<EditEmployees />} />
              <Route path="EditNonbillableEmployees/:id" element={<EditNonbillableEmployees />} />
              <Route path="Invoices" element={<Invoices />} />
              <Route path="Payments" element={<Payments />} />
              <Route path="EditServiceLogCustom/:id" element={<EditServiceLogCustom />} />
              <Route path="CreateInvoice" element={<CreateInvoice />} />
              <Route path="CreateInvoiceRequest" element={<CreateInvoiceRequest />} />
              <Route path="CreateRequestLog" element={<CreateRequestLog />} />
              <Route path="CreateCaseInvoice" element={<CreateCaseInvoice />} />
              <Route path="CreateCustomLog" element={<CreateCusomLog />} />
              <Route path="RequestServiceLogs" element={<RequestServiceLogs />} />
              <Route path="CreateCustomLogCase" element={<CreateCustomLogCase />} />
              <Route path="RegenerateInvoice/:id" element={<RegenerateInvoice />} />
              <Route path="EditInvoice/:id" element={<EditInvoice />} />
              <Route path="RegenerateCustomLog/:id" element={<RegenerateCustomLog />} />
              <Route path="CreateCompanyForm" element={<CreateCompanyFormNew />} />
              <Route path="CreateCompanyFormClients" element={<CreateCompanyFormClients />} />
              <Route path="AssignRequestFormToClients/:id" element={<AssignRequestFormToClients />} />
              <Route path="AssignFormToClient" element={<AssignFormToClient />} />
              <Route path="AssignRequestToEmployees/:id" element={<AssignRequestToEmployee />} />
              <Route path="EditRequestToEmployees/:id" element={<EditRequestToEmployees />} />
              <Route path="EditCompanyForm/:id" element={<EditCompanyForm />} />
              <Route path="EditCompanyFormClient/:id" element={<EditCompanyFormClient />} />
              <Route path="Profile" element={<AdminProfile />} />
              <Route path="CompanyForms" element={<CompanyForms />} />
              <Route path="Requests" element={<Requests />} />
              <Route path="RequestForms" element={<RequestForms />} />
              <Route path="FillForm/:id" element={<FillFormAdmin />} />
              <Route path="CreateRequestForm" element={<CreateRequestForm />} />
              <Route path="EditRequestForm/:id" element={<EditRequestForm />} />
              <Route path="Cases" element={<Cases />} />
              <Route path="CreateCase" element={<CreateCase />} />
              <Route path="EditCase/:id" element={<EditCase />} />
              <Route path="CompanyFormsClients" element={<CompanyFormsClientAdmin />} />
              <Route path="ServiceLog/Employee/:id2/Calendar/:id1" element={<ScheduleAdmin />} />
              <Route path="UnbillableHours/Employee/:id2/Calendar/:id1" element={<SchedulerAdminUnbillableHours />} />
              <Route path="EditServiceLog/:id/:employe_id" element={<EditServiceLogAdmin />} />
              <Route path="EditServiceLogRequest/:id/:employe_id" element={<EditServiceLogRequestAdmin />} />
              <Route path="AddServiceLog/:employe_id" element={<AddServiceLogAdmin />} />
              <Route path="AddServiceLogRequest/:employe_id" element={<AddServiceLogRequestAdmin />} />
              <Route path="EditUnbillableHours/:id/:employe_id" element={<EditUnbillableHoursAdmin />} />
              <Route path="EditForm/:id" element={<EditFormAdmin />} />
            </Route>
          </Route>
          <Route path={'/Admin/*'} element={<Error />} />
        </Route>
        <Route path={'/'} element={<MainPage />} />
        <Route path={'/Terms&Conditions'} element={<TermsAndConditions />} />
        <Route path={'/PrivacyPolicy'} element={<PrivacyPolicy />} />
        <Route path={'/*'} element={<Error />} />
      </Routes>
    </Router>
  );
};

export default RootContainer;
