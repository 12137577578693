import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useFieldArray, useForm } from 'react-hook-form';
import { useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ClientHttp from '../../http/clientsHttp/clientsHttp';
import GiveAccessClientDefaultValue from './GiveAccessClientDefaultValue';
import InputLabel from '../../components/InputLabel/InputLabel';
import { ToastContainer } from 'react-toastify';
import { notifySuccess } from '../../utils/utils';
import 'react-toastify/dist/ReactToastify.css';
import AddEmailsField from './components/AddEmailsField';

export default function GiveAccessClient() {
  const [isLoading, setIsLoading] = useState(false);
  const [hasEmail, setHasEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
  } = useForm({
    defaultValues: useMemo(() => GiveAccessClientDefaultValue(), []),
  });

  const {
    fields: AddEmailsFields,
    append: AddEmailsAppend,
    remove: AddEmailsRemove,
  } = useFieldArray({
    control,
    name: `add_emails`,
  });

  const onSubmit = data => {
    setIsLoading(true)
    data.add_emails = data?.add_emails?.map(elem => ({
      email: elem?.email,
      id: elem?.id,
      password: elem?.password || null,
      access_for_invoices: elem?.access_for_invoices ? 1 : 0,
      access_for_service_logs: elem?.access_for_service_logs ? 1 : 0,
      access_for_request: elem?.access_for_request ? 1 : 0,
      // access_for_invoices : 1,
      // access_for_service_logs : 1
    }));
    data.email = hasEmail ? data?.email : null;
    data.password = hasEmail ? data?.password : null;
    data.access_for_invoices = data?.access_for_invoices ? 1 : 0;
    data.access_for_service_logs = data?.access_for_service_logs ? 1 : 0;
    // data.access_for_invoices =  1
    // data.access_for_service_logs = 1
    ClientHttp.giveAccessToClient(params?.id, data)
      .then(() => afterSubmit())
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false)
      });
  };

  const afterSubmit = () => {
    notifySuccess('Client successfully got Access');
    setTimeout(() => {
      navigate('/Admin/Clients');
    }, 2000);
  };

  const onClickCancel = () => {
    navigate('/Admin/Clients');
  };

  const onChangePassword = () => {
    setValue('password', '');
    setShowPassword(prev => !prev);
  };

  return (
    <div className="sm:p-8 p-4 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-4">
          <Title
            title={'Grant Access to the Client'}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full  mt-2">
          <div className="grid sm:px-7 px-4 sm:grid-cols-1 lg:grid-cols-1 sm:gap-6 gap-2">
            <div className="w-full mt-5 gap-2 flex flex-col">
              <div
                className={`w-full gap-2 flex  ${errors?.email?.message ? 'items-center' : 'items-end'} ${
                  !hasEmail && 'justify-end'
                }`}>
                {hasEmail && (
                  <div className="w-full">
                    <InputLabel
                      labelTitle={'Email'}
                      inputId={`email`}
                      className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                      placeholder={'Email'}
                      classNameInput="w-full"
                      name={`email`}
                      register={register}
                      classNameLabel={'text-[#60657B] w-full opacity-50 text-xs font-normal'}
                    />
                    {errors && <p className="mb-0 text-sm text-amber-800">{errors?.email?.message}</p>}
                  </div>
                )}
                {hasEmail && (
                  <div className={`flex justify-end`}>
                    <section
                      className={`border rounded flex justify-center text-center px-3 py-1 min-w-[204px] max-w-[204px] h-[42px]`}>
                      <button className={`text-[#4885ED] text-sm font-normal`} type="button" onClick={onChangePassword}>
                        {!showPassword ? 'Set Password' : 'Remove Password'}
                      </button>
                    </section>
                  </div>
                )}
                {
                  <div className={`flex justify-end`}>
                    <section
                      className={`border rounded flex justify-center text-center px-3 py-1 min-w-[204px] max-w-[204px] h-[42px] cursor-pointer`}>
                      <button
                        className={`text-[#4885ED] text-sm font-normal w-full`}
                        type="button"
                        onClick={() => setHasEmail(prev => !prev)}>
                        {hasEmail ? 'Remove Email Access' : 'Set Email Access'}
                      </button>
                    </section>
                  </div>
                }
              </div>

              {hasEmail && showPassword && (
                <div className="w-full">
                  <InputLabel
                    labelTitle={'Password'}
                    inputId={`password`}
                    className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                    placeholder={'Password'}
                    classNameInput="w-full"
                    name={`password`}
                    register={register}
                    classNameLabel={'text-[#60657B] w-full opacity-50 text-xs font-normal'}
                  />
                  {errors && <p className="mb-0 text-sm text-amber-800">{errors?.password?.message}</p>}
                </div>
              )}
            </div>
            {hasEmail && (
              <div className="space-y-5 mt-5">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="access_for_service_logs"
                      aria-describedby="comments-description"
                      name="access_for_service_logs"
                      {...register('access_for_service_logs')}
                      type="checkbox"
                      className="h-4 w-4 rounded text-sm text-[#2B2D38] font-normal border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900">
                      Access For Service Logs
                    </label>
                  </div>
                </div>
              </div>
            )}
            {hasEmail && (
              <div className="space-y-5 ">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="access_for_invoices"
                      aria-describedby="comments-description"
                      name="access_for_invoices"
                      {...register('access_for_invoices')}
                      type="checkbox"
                      className="h-4 w-4 rounded text-sm text-[#2B2D38] font-normal border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900">
                      Access For Invoices
                    </label>
                  </div>
                </div>
              </div>
            )}

            <AddEmailsField
              AddEmailsFields={AddEmailsFields}
              register={register}
              errors={errors}
              control={control}
              AddEmailsAppend={AddEmailsAppend}
              AddEmailsRemove={AddEmailsRemove}
            />
          </div>
          <div className="mt-16">
            <CancleAdd buttonText={'Grant Access'} onClickCancel={onClickCancel} disabled={isLoading} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
