import { useCallback, useEffect, useState } from 'react';
import InputLabel from '../../../components/InputLabel/InputLabel';
import Select from '../../../components/Select/Select';
import Service from '../../../http/seviceHttp/seviceHttp';
import requestHttp from '../../../http/requestHttp/requestHttp';
import EmployeesHttp from '../../../http/employeesHttp/employeesHttp';
import CurrencyInputNew from '../../../components/CurrencyInputNew/CurrencyInputNew';

export default function InnerFields({ index, setValue, errors, register, Remove, onClickAppend, formId }) {
  const [services, setServicesData] = useState([]);
  const [positions, setPositions] = useState([]);
  const [currentEmployee, setCurrentEmployee] = useState();
  const [currentServiceId, setCurrentServiceId] = useState();
  const [currentPosition, setCurrentPosition] = useState();
  const [currentRate, setCurrentRate] = useState();
  const [allEmployeesData, setAllEmployeesData] = useState([]);

  const onChangeEmployee = useCallback(e => {
    setCurrentEmployee(e?.target?.value);
    setCurrentServiceId('');
    setCurrentPosition('');
    setCurrentRate('');
    // setCurrentPositionId("")
    setValue(`employee_ids.${index}.service_id`, '');
    setValue(`employee_ids.${index}.position_id`, '');
    setValue(`employee_ids.${index}.hourly_rate`, '');
  }, []);

  const onChangeService = useCallback(e => {
    setCurrentServiceId(e?.target?.value);
    setCurrentPosition('');
    setCurrentRate('');
    setValue(`employee_ids.${index}.position_id`, '');
    setValue(`employee_ids.${index}.hourly_rate`, '');
  }, []);

  const onChangePosition = useCallback(e => {
    setCurrentPosition(e?.target?.value);
    setCurrentRate('');
    setValue(`employee_ids.${index}.hourly_rate`, '');
  }, []);

  useEffect(() => {
    currentEmployee &&
      Service.servicesForRequest(formId, currentEmployee)
        .then(res =>
          setServicesData(
            res?.data?.data?.map(({ id, name }) => ({
              id,
              name,
            })),
          ),
        )
        .catch(error => {
          console.log(error);
        });
  }, [currentEmployee]);

  useEffect(() => {
    currentServiceId &&
      requestHttp
        .getPositionsForRequest(currentServiceId, formId, currentEmployee)
        .then(res =>
          setPositions(
            res?.data?.data?.map(({ id, name }) => ({
              id,
              name,
            })),
          ),
        )
        .catch(error => {
          console.log(error);
        });
  }, [currentServiceId]);

  useEffect(() => {
    currentPosition &&
      currentEmployee &&
      requestHttp
        .getRateForRequest(currentServiceId, formId, currentPosition)
        .then(res => {
          setCurrentRate(res?.data?.data?.rate);
        })
        .catch(error => {
          console.log(error);
        });
  }, [currentPosition, currentEmployee, currentServiceId]);

  useEffect(() => {
    setValue(`employee_ids.${index}.id`, currentEmployee);
  }, [allEmployeesData]);

  useEffect(() => {
    setValue(`employee_ids.${index}.service_id`, currentServiceId);
  }, [services]);

  useEffect(() => {
    setValue(`employee_ids.${index}.position_id`, currentPosition);
  }, [positions]);

  useEffect(() => {
    setValue(`employee_ids.${index}.hourly_rate`, currentRate);
  }, [currentRate]);

  useEffect(() => {
    EmployeesHttp.employeesFullinks().then(e => {
      setAllEmployeesData(e?.data?.data);
    });
  }, []);

  return (
    <>
      <div className="w-full bg-white p-4 rounded-md">
        <div className="grid items-end">
          <div className="w-full bg-white">
            <Select
              register={register}
              title={'To Employee'}
              selectData={allEmployeesData}
              selectClass="flex-col gap-2"
              onChange={onChangeEmployee}
              name={`employee_ids.${index}.id`}
              className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
              classNameTitle="text-[#60657B] opacity-50 text-xs"
            />
            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.employee_ids?.[index]?.id?.message}</p>}
          </div>
        </div>
        <div className="mt-5">
          <InputLabel
            classNameInput="flex flex-col"
            classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
            className="w-full border p-[7px]"
            type="date"
            labelTitle={'Completion deadline'}
            register={register}
            name={`employee_ids.${index}.deadline`}
          />
          {errors && <p className="mb-0 text-sm text-amber-800">{errors?.employee_ids?.[index]?.deadline?.message}</p>}
        </div>
        <div className="w-full bg-white mt-5">
          <Select
            register={register}
            title={'Service'}
            selectData={services ?? []}
            onChange={onChangeService}
            selectClass="flex-col gap-2"
            name={`employee_ids.${index}.service_id`}
            className="w-full pl-4 focus:outline-none h-[40px] border rounded cursor-pointer"
            classNameTitle="text-[#60657B] opacity-50 text-xs"
            // required={{ required: 'This field is required' }}
          />
          {errors && (
            <p className="mb-0 text-sm text-amber-800">{errors?.employee_ids?.[index]?.service_id?.message}</p>
          )}
        </div>
        <div className="w-full bg-white mt-5">
          <Select
            register={register}
            title={'Positions'}
            selectData={positions ?? []}
            onChange={onChangePosition}
            selectClass="flex-col gap-2"
            name={`employee_ids.${index}.position_id`}
            className="w-full pl-4 focus:outline-none h-[40px] border rounded cursor-pointer"
            classNameTitle="text-[#60657B] opacity-50 text-xs"
          />
          {errors && (
            <p className="mb-0 text-sm text-amber-800">{errors?.employee_ids?.[index]?.position_id?.message}</p>
          )}
        </div>
        <div className="mt-5">
          <CurrencyInputNew
            classNameLabel="text-[#60657B]  text-xs font-normal p-1 opacity-50 mb-2"
            className={'w-full border p-[7px] outline-none rounded border p-1'}
            name={`employee_ids.${index}.hourly_rate`}
            setValue={setValue}
            // disabled={salary == 0}
            placeholder="$0.00"
            labelTitle={'Hourly Rate'}
            defaultValue={currentRate}
          />
          {errors && (
            <p className="mb-0 text-sm text-amber-800">{errors?.employee_ids?.[index]?.hourly_rate?.message}</p>
          )}
        </div>
        {index !== 0 && (
          <div className="grid mt-5 grid-cols-1 sm:gap-6 gap-2">
            <section className="text-end ">
              <button
                onClick={() => {
                  Remove(index);
                }}
                className="text-[#4885ED] px-3 py-1 border rounded text-base min-w-[204px] max-w-[204px]"
                type="button">
                Remove
              </button>
            </section>
          </div>
        )}
        <div className="grid grid-cols-1 sm:gap-6 gap-2 mt-5">
          <section className="text-end">
            <button
              className="text-[#4885ED] px-3 py-1 border rounded text-base min-w-[204px] max-w-[204px]"
              type="button"
              onClick={onClickAppend}>
              Add
            </button>
          </section>
        </div>
      </div>
    </>
  );
}
