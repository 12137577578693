import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';

import Title from '../../components/Title/Title';
import InputLabel from '../../components/InputLabel/InputLabel';
import CheckboxesFields from './components/CheckboxesFields';
import ViewRequestFormEmployeeDefaultValue from './ViewRequestFormEmployeeDefaultValue';
import requestHttp from '../../http/requestHttp/requestHttp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDropzone } from 'react-dropzone';
import { notifySuccess } from '../../utils/utils';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import MultiDropZone from '../../components/DropZone/MultiDropZone';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import ServiceLogModalShow from '../../components/Modal/ServiceLogModalShow';
import { columnsRequestLogViewDataEmployee } from './helpers/columnsRequestLogViewDataEmployee';

export default function ViewRequestFormEmployee() {
  const [form, setForm] = useState([]);
  const [stateImage, setStateImage] = useState('');
  const [stateImageFromClient, setStateImageFromClient] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [isOpenCommentShow, setIsOpenCommentShow] = useState(false);
  const [activityId, setActivityId] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const onClickCancel = () => {
    navigate('/Employee/MyRequests');
  };

  const { register, control, reset, handleSubmit, setError } = useForm({
    defaultValues: useMemo(() => ViewRequestFormEmployeeDefaultValue(form), [form]),
  });

  const { fields: Fields } = useFieldArray({
    control,
    name: 'formFields',
  });

  const afterSubmit = () => {
    notifySuccess('File successfully Added');
    setTimeout(() => {
      navigate('/Employee/MyRequests');
    }, 2000);
  };

  const onSubmit = async data => {
    setIsLoading(true);
    const formData = new FormData();
    acceptedFiles?.forEach(file => {
      formData?.append('files', file); // Appends multiple files
    });
    data.files = formData?.getAll('files'); // Ensure multiple files are handled
    requestHttp
      ?.addFile(params?.id, data)
      .then(() => afterSubmit())
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false);
      });
  };

  useEffect(() => {
    reset(ViewRequestFormEmployeeDefaultValue(form));
    if (form?.request_files) {
      setStateImage({ cdnUrl: form?.request_files });
    }
    setShowFile(!!form?.files?.length > 0);
    if (!!form?.files?.length > 0) {
      setStateImageFromClient({ cdnUrl: form?.files });
    } else {
      setStateImageFromClient([]);
    }
     requestHttp.requestLogViewEmployee(params.id).then(e => {
          setViewData(e?.data?.data);
    
          // setStateImageEmployee()
        });
  }, [form]);

  useEffect(() => {
    requestHttp.getSubmittedFormEmployee(params?.id).then(e => {
      setForm(e?.data?.data);
    });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    multiple: true,
  });

  const handleRemoveFile = id => {
    requestHttp?.removeFileForEmployee(params?.id, id).then(e => setForm(e?.data?.data));
  };

  const onClickCommentShow = obj => {
    setIsOpenCommentShow(true);
    setActivityId(obj);
  };

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <ServiceLogModalShow open={isOpenCommentShow} setOpen={setIsOpenCommentShow} activityId={activityId} />
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title={`Edit Form for - ${form?.title}`}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="py-4 w-full">
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline">
              {Fields?.map(({ label, type, id }, index) =>
                type === 'input' ? (
                  <div className="w-full" key={id}>
                    <InputLabel
                      labelTitle={label}
                      register={register}
                      name={`formFields.${index}.value`}
                      classNameLabel="text-[#60657B] opacity-50 text-xs"
                      classNameInput="flex flex-col flex-1"
                      type={'text'}
                      placeholder={label}
                      disabled={true}
                      className="w-full"
                      // required={{ required: 'This field is required' }}
                    />
                  </div>
                ) : (
                  type === 'date' && (
                    <div className="" key={id + label}>
                      <InputLabel
                        classNameInput="flex flex-col "
                        classNameLabel={'font-[system-ui] text-[#60657B] text-xs font-normal opacity-50'}
                        className="w-full border p-[7px]"
                        type="date"
                        labelTitle={label}
                        register={register}
                        name={`formFields.${index}.value`}
                        // required={{ required: 'This field is required' }}
                        disabled={true}
                      />
                    </div>
                  )
                ),
              )}
            </div>
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline mt-5 h-full">
              {Fields?.map(
                ({ label, type, id }, index) =>
                  type === 'textarea' && (
                    <div className="" key={id + label}>
                      <div className="w-full flex flex-col gap-2">
                        <label className={'font-[system-ui] text-[#60657B] opacity-50 text-xs font-normal'}>
                          {label}
                        </label>
                        <textarea
                          disabled={true}
                          rows={7}
                          className="p-[14px] outline-none w-full border"
                          {...register(`formFields.${index}.value`)}
                        />
                      </div>
                    </div>
                  ),
              )}
            </div>
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline mt-5 h-full">
              {Fields?.map(
                ({ name, label, type, id }, index) =>
                  type === 'checkbox' && (
                    <div className="space-y-5 h-full" key={id + name + label}>
                      <p className="text-lg font-semibold">{label}</p>
                      <CheckboxesFields control={control} register={register} index={index} disabled={true} />
                    </div>
                  ),
              )}
            </div>
            {showFile && (
              <div className="sm:px-7 px-4">
                {stateImageFromClient && <p className="text-xl font-bold my-2">Uploaded Documents From The Client</p>}
                <MultiDropZone
                  getRootProps={getRootProps}
                  getInputProps={false}
                  isFocused={isFocused}
                  isDragAccept={isDragAccept}
                  isDragReject={isDragReject}
                  state={stateImageFromClient}
                />
              </div>
            )}
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 sm:gap-6 gap-2 items-baseline mt-5 h-full">
              <div>
                <InputLabel
                  labelTitle={'Request Name'}
                  className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                  placeholder={'Request Name'}
                  name={`name`}
                  register={register}
                  classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                  // required={{ required: 'This field is required' }}
                  disabled={true}
                />
              </div>
              <div>
                <InputLabel
                  labelTitle={'Service'}
                  className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                  placeholder={'Service'}
                  name={`service`}
                  register={register}
                  classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                  // required={{ required: 'This field is required' }}
                  disabled={true}
                />
              </div>
            </div>
            <div className="sm:px-7 px-4">
              <MultiDropZone
                getRootProps={getRootProps}
                getInputProps={form?.status === 'Approved' ? false : getInputProps}
                isFocused={isFocused}
                isDragAccept={isDragAccept}
                isDragReject={isDragReject}
                state={stateImage}
                handleRemoveFile={handleRemoveFile}
              />
            </div>
          </div>
          {viewData.length != 0 && (
            <div className={`px-8 py-1 mt-4 bg-white rounded-xl ${isLoading && 'animate-pulse'}`}>
              <div className="w-full overflow-x-auto">
                <div className="inline-block min-w-full">
                  <TableEmployeesAdmin
                    tbody={viewData?.days ?? []}
                    columns={columnsRequestLogViewDataEmployee(viewData, onClickCommentShow)}
                    maxHeight="700px"
                  />
                </div>
              </div>
              <div className="mt-5">
                <div>
                  <p className="text-base font-black text-center">{`Total: ${viewData?.total}`}</p>
                </div>
              </div>
            </div>
          )}
          <div>
            <div className="mt-5 z-50">
              <CancleAdd disabled={isLoading} buttonText={`Save`} onClickCancel={onClickCancel} />
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
