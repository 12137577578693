import CheckBox from "../../../components/Checkbox/Checkbox";

export const columnsCreateInvoiceAdmin = register => [
    {
      accessorKey: 'employeeFullName',
      header: 'Employee', //uses the default width from defaultColumn prop
      // hishem avelacnem
      // size: 40,
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['employeeFullName']}</p>,
    },
    {
      accessorKey: 'InvoiceGenerationOption',
      header: 'Invoice Generation Option', //uses the default width from defaultColumn prop
      // hishem avelacnem
      // size: 40,
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['InvoiceGenerationOption']}</p>,
    },
    {
      accessorKey: 'select',
      header: 'Generate Invoice for', //uses the default width from defaultColumn prop
      // hishem avelacnem
      // size: 20,
      Cell: ({ row }) => (
        <CheckBox
          register={register}
          name={`${row?.original?.employee_id}`}
          label={``}
          disabled={true}
        />
      ),
    },
  ];


export function filterMatchingObjects(arr, newArr) {
    const idSet = new Set(arr);
    const filtered = newArr?.filter(obj => idSet?.has(obj?.employee_id));
    return filtered?.length === arr?.length ? filtered : false;
}

// const arr = [23, 11,55];
// const newArr = [
//     { employee_id: 23, name: "aaa" },
//     { employee_id: 34, name: "765756543543" },
//     { employee_id: 55, name: "45fgdgfdasdsa" },
//     { employee_id: 11, name: "bbb" }
// ];

// console.log(filterMatchingObjects(arr, newArr),"sdsdsdsdsdsdssd"); 