import { Fragment, useEffect, useMemo, } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useForm } from 'react-hook-form'
import { notifySuccess } from '../../utils/utils'
import CancleAdd from '../CancleAdd/CancleAdd'
import AdminServiceLogHttp from '../../http/adminServiceLogHttp/adminServiceLogHttp'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";




export default function ServiceLogModal({ open, setOpen, activityId, setViewData }) {

    const onClickClose = () => {
        setValue("comment", "")
        setOpen(false)
    }

    const defaultValue = () => {
        return {
            comment: ""
        }
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        setError,
    } = useForm({
        defaultValues: useMemo(() => defaultValue(), []),
        // defaultValues: 
        resolver: yupResolver(yup.object().shape({
            comment: yup.string().required("This field is required"),
        })),
    });

    const afterSubmit = () => {
        notifySuccess("Comment successfully sent")
        onClickClose()
        setViewData([])
        // setUpdate(prev => !prev)
    }

    const onSubmit = (data) => {
        data.service_log_time_activity_id = activityId?.id
        AdminServiceLogHttp.leaveComment(data).then(() => {
            afterSubmit()
        })
            .catch(err => {
                const errorFromBackend = Object.keys(err?.response?.data?.errors)
                errorFromBackend.forEach((item) => (
                    setError(item, { type: "custom", message: err?.response?.data?.errors?.[item] })
                ))
            })
    };

    useEffect(() => {
        reset( defaultValue(activityId));
    }, [activityId]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[50rem] sm:p-6">
                                <div className="absolute right-0 top-0 pr-4 pt-4">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={onClickClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="sm:flex-col sm:items-center justify-center">
                                    <div className="mt-3 text-center ">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {`leave a comment for activity`}
                                        </Dialog.Title>
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {`${activityId?.day} ${activityId?.start} to ${activityId?.end}`}
                                        </Dialog.Title>

                                    </div>
                                    <div className="sm:px-8 px-2">
                                        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                                            <div className="w-full flex flex-col gap-2">
                                                <textarea rows={7} className="p-[14px] outline-none w-full border" {...register("comment")} />
                                                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.comment?.message}</p>}
                                            </div>
                                            <div className=''>
                                                <CancleAdd buttonText={`Send`} onClickCancel={onClickClose} />
                                            </div>
                                        </form>
                                    </div>
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
