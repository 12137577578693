export const getNavigation = (role, employeeData) =>
    role === 'employee'
    ? [
        {
          name: 'Dashboard',
          icon: '/dashboardIcon.svg',
          icon_white: '/dashboardIconWhite.svg',
          href: 'Dashboard',
        },
        {
          name: 'Service Log',
          icon: '/Layer_2.svg',
          icon_white: '/ServiceLogIconWhite.svg',
          href: 'ServiceLog',
          children:
            employeeData?.type == 1 && employeeData?.is_both == 0
              ? [
                  {
                    name: 'Service Log',
                    icon: '/Layer_2.svg',
                    icon_white: '/ServiceLogIconWhite.svg',
                    href: 'UnbillableHours',
                  },
                ]
              : [
                  {
                    name: 'Service Log',
                    icon: '/Layer_2.svg',
                    icon_white: '/ServiceLogIconWhite.svg',
                    href: 'ServiceLog',
                  },
                  {
                    name: 'Non-Service Hours',
                    icon: '/Layer_2.svg',
                    icon_white: '/ServiceLogIconWhite.svg',
                    href: 'UnbillableHours',
                  },
                ],
        },
        {
          name: 'Company Forms',
          icon: '/CompanyFormsImage.svg',
          icon_white: '/CompanyIconWhite.svg',
          href: 'CompanyForms',
        },
        {
          name: 'My Requests',
          icon: '/CompanyFormsImage.svg',
          icon_white: '/CompanyIconWhite.svg',
          href: 'MyRequests',
        },
      ]
    : role === 'client'
    ? [
        {
          name: 'Service Log',
          icon: '/Layer_2.svg',
          icon_white: '/ServiceLogIconWhite.svg',
          href: 'ServiceLog',
        },
        {
          name: 'Invoices',
          icon: '/InvoicesImage.svg',
          icon_white: '/InvoiceIconWhite.svg',
          href: 'Invoices',
        },
        {
          name: 'Forms',
          icon: '/CompanyFormsImage.svg',
          icon_white: '/CompanyIconWhite.svg',
          href: 'CompanyForms',
        },

        {
          name: 'Report - Requests',
          icon: '/CompanyFormsImage.svg',
          icon_white: '/CompanyIconWhite.svg',
          href: 'RequestProjects',
          children: [
            {
              name: 'My Requests',
              icon: '/CompanyFormsImage.svg',
              icon_white: '/CompanyIconWhite.svg',
              href: 'MyRequests',
            },
            {
              name: 'Request Types',
              icon: '/CompanyFormsImage.svg',
              icon_white: '/CompanyIconWhite.svg',
              href: 'RequestTypes',
            },
            {
              name: 'Request Service Logs',
              icon: '/CompanyFormsImage.svg',
              icon_white: '/CompanyIconWhite.svg',
              href: 'RequestServiceLogs',
            },
          ],
        },
      ]
    : role === 'superAdmin'
    ? [
        {
          name: 'Dashboard',
          icon: '/dashboardIcon.svg',
          icon_white: '/dashboardIconWhite.svg',
          href: 'Dashboard',
        },
        {
          name: 'Clients',
          icon: '/Layer_1.svg',
          icon_white: '/Layer_1_White.svg',
          href: 'Clients',
        },
      ]
    : [
        {
          name: 'Dashboard',
          icon: '/dashboardIcon.svg',
          icon_white: '/dashboardIconWhite.svg',
          href: 'Dashboard',
        },

        {
          name: 'Employees',
          icon: '/EmployeesIcon.svg',
          icon_white: '/EmployeesIconWhite.svg',
          href: 'Employees',
          children: [
            {
              name: 'Employees',
              icon: '/EmployeesIcon.svg',
              icon_white: '/EmployeesIconWhite.svg',
              href: 'Employees',
            },
            {
              name: 'Employee Actions',
              icon: '/EmployeesIcon.svg',
              icon_white: '/EmployeesIconWhite.svg',
              href: 'EmployeeActions',
            },
          ],
        },
        {
          name: 'Clients',
          icon: '/Layer_1.svg',
          icon_white: '/Layer_1_White.svg',
          href: 'Clients',
        },
        {
          name: 'Services',
          icon: '/Union1.svg',
          icon_white: '/ServicesIconWhite.svg',
          href: 'Services',
        },
        {
          name: 'Service Log',
          icon: '/Layer_2.svg',
          icon_white: '/ServiceLogIconWhite.svg',
          href: 'ServiceLog',
          children: [
            {
              name: 'Service Log',
              icon: '/Layer_2.svg',
              icon_white: '/ServiceLogIconWhite.svg',
              href: 'ServiceLog',
            },
            {
              name: 'Non-Service Hours',
              icon: '/Layer_2.svg',
              icon_white: '/ServiceLogIconWhite.svg',
              href: 'UnbillableHours',
            },
          ],
        },

        {
          name: 'Invoices',
          icon: '/InvoicesImage.svg',
          icon_white: '/InvoiceIconWhite.svg',
          href: 'Invoices',
          children: [
            {
              name: 'Invoices',
              icon: '/InvoicesImage.svg',
              icon_white: '/InvoiceIconWhite.svg',
              href: 'Invoices',
            },
            {
              name: 'Payments',
              icon: '/InvoicesImage.svg',
              icon_white: '/InvoiceIconWhite.svg',
              href: 'Payments',
            },
          ],
        },
        {
          name: 'Company Forms',
          icon: '/CompanyFormsImage.svg',
          icon_white: '/CompanyIconWhite.svg',
          href: 'CompanyForms',
          children: [
            {
              name: 'Company Form Employee',
              icon: '/CompanyFormsImage.svg',
              icon_white: '/CompanyIconWhite.svg',
              href: 'CompanyForms',
            },
            {
              name: 'Company Form Client',
              icon: '/CompanyFormsImage.svg',
              icon_white: '/CompanyIconWhite.svg',
              href: 'CompanyFormsClients',
            },
          ],
        },
        {
          name: 'Reports',
          icon: '/filled1.svg',
          icon_white: '/ReportIconWhite.svg',
          href: 'Reports',
        },
        {
          name: 'Requests',
          icon: '/CompanyFormsImage.svg',
          icon_white: '/CompanyIconWhite.svg',
          href: 'CompanyForms',
          children: [
            {
              name: 'Requests',
              icon: '/CompanyFormsImage.svg',
              icon_white: '/CompanyIconWhite.svg',
              href: 'Requests',
            },
            {
              name: 'Request Forms',
              icon: '/CompanyFormsImage.svg',
              icon_white: '/CompanyIconWhite.svg',
              href: 'RequestForms',
            },
            {
              name: 'Request Service Logs',
              icon: '/CompanyFormsImage.svg',
              icon_white: '/CompanyIconWhite.svg',
              href: 'RequestServiceLogs',
            },
          ],
        },
        {
          name: 'Project/Cases',
          icon: '/filled1.svg',
          icon_white: '/ReportIconWhite.svg',
          href: 'Cases',
        },
      ];
