import { useState } from 'react';
import { useParams } from 'react-router-dom';

import InputLabel from '../../../components/InputLabel/InputLabel';

export default function AdditionalFieldWithPassword({ index, errors, register, AddEmailsRemove, elem, resendAccess }) {
  const params = useParams();

  const [showPasswordAdd, setShowPasswordAdd] = useState(false);

  const onChangePasswordAdd = () => {
    setShowPasswordAdd(prev => !prev);
  };

  const isVerified = !!elem?.otp_verified;

  return (
    <>
      <div className="w-full mt-5 gap-2 flex flex-col">
        <div
          className={`w-full gap-2 flex  ${
            errors?.add_emails?.[index]?.email?.message ? 'items-center' : 'items-end'
          }`}>
          <div className="w-full">
            <InputLabel
              labelTitle={`Additional Email ${index + 1}`}
              inputId={`add_emails.${index}.email`}
              className={`border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182] ${
                isVerified && 'bg-[#A6ADC9] text-[white]'
              }`}
              placeholder={`Additional Email ${index + 1}`}
              classNameInput="w-full"
              name={`add_emails.${index}.email`}
              disabled={isVerified}
              register={register}
              classNameLabel={'text-[#60657B] w-full opacity-50 text-xs font-normal'}
            />
            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.add_emails?.[index]?.email?.message}</p>}
          </div>
          <div className="flex justify-end">
            <section
              className={`border rounded flex justify-center text-center px-3 py-1 min-w-[204px] max-w-[204px] h-[42px] cursor-pointer ${
                isVerified && 'bg-[#A6ADC9] cursor-not-allowed'
              }`}>
              <button
                className={`text-[#4885ED] text-sm font-normal w-full ${
                  isVerified && 'text-[white] cursor-not-allowed'
                }`}
                type="button"
                onClick={onChangePasswordAdd}
                disabled={isVerified}>
                {!showPasswordAdd ? 'Set Password' : 'Remove Password'}
                {/* {"Set Password"} */}
              </button>
            </section>
          </div>
          {isVerified && (
            <button
              className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900 h-[42px]"
              type="button">
              <img className="max-w-[60px] h-[20px] block cursor-pointer" src="/Done.svg" alt="Done" />
            </button>
          )}
          {isVerified === false && elem?.email && (
            <div className="flex justify-end">
              <section
                className={`border rounded flex justify-center text-center px-3 py-1 min-w-[204px] max-w-[204px] h-[42px]`}>
                <button
                  className={`text-[#4885ED] text-sm font-normal w-full`}
                  type="button"
                  onClick={() => resendAccess(params?.id, { add_school_id: elem?.id_, is_add: true })}>
                  {'Resend Password'}
                </button>
              </section>
            </div>
          )}
          <div className="flex justify-end">
            <section
              className={`border rounded flex justify-center text-center px-3 py-1 min-w-[204px] max-w-[204px] h-[42px]`}>
              <button
                className={`text-[#4885ED] text-sm font-normal w-full`}
                type="button"
                onClick={() => {
                  AddEmailsRemove(index);
                }}>
                Remove Additional Email
              </button>
            </section>
          </div>
        </div>
        {showPasswordAdd && (
          <div className="w-full">
            <InputLabel
              labelTitle={`Additional Email ${index + 1} Password`}
              inputId={`add_emails.${index}.password`}
              className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
              placeholder={`Additional Email ${index + 1} Password`}
              classNameInput="w-full"
              name={`add_emails.${index}.password`}
              register={register}
              classNameLabel={'text-[#60657B] w-full opacity-50 text-xs font-normal'}
            />
            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.add_emails?.[index]?.password?.message}</p>}
          </div>
        )}
      </div>
      <div className="space-y-5 mt-5">
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="access_for_service_logs"
              aria-describedby="comments-description"
              name="access_for_service_logs"
              {...register(`add_emails.${index}.access_for_service_logs`)}
              type="checkbox"
              className="h-4 w-4 rounded text-sm text-[#2B2D38] font-normal border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="comments" className="font-medium text-gray-900">
              Access For Service Logs
            </label>
          </div>
        </div>
      </div>
      <div className="space-y-5 ">
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="access_for_invoices"
              aria-describedby="comments-description"
              name="access_for_invoices"
              {...register(`add_emails.${index}.access_for_invoices`)}
              type="checkbox"
              className="h-4 w-4 rounded text-sm text-[#2B2D38] font-normal border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="comments" className="font-medium text-gray-900">
              Access For Invoices
            </label>
          </div>
        </div>
      </div>
      <div className="space-y-5 ">
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="access_for_request"
              aria-describedby="comments-description"
              name="access_for_request"
              {...register(`add_emails.${index}.access_for_request`)}
              type="checkbox"
              className="h-4 w-4 rounded text-sm text-[#2B2D38] font-normal border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="comments" className="font-medium text-gray-900">
              Access For Request Logs
            </label>
          </div>
        </div>
      </div>
    </>
  );
}
