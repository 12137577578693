export const loginFields = [
  {
    labelText: 'First Name ',
    id: 1,
    name: 'name',
    type: 'text',
    placeholder: 'First Name ',
  },
  {
    labelText: 'Middle Name',
    id: 2,
    name: 'middleName',
    type: 'text',
    placeholder: 'Middle Name',
  },
  {
    labelText: 'Last Name',
    id: 3,
    name: 'lastName',
    type: 'text',
    placeholder: 'Last Name',
  },
];
