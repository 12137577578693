import { $authHost } from '..';

export default class casesHttp {
  static getCasesList(id, employee_id, school_id, status) {
    return $authHost.get(
      `/admin/new-cases?page=${id ? id : ''}&employee_id=${employee_id ?? ''}&school_id=${school_id ?? ''}&status=${
        status ?? ''
      }`,
    );
  }
  static deleteCase(id) {
    return $authHost.post(`/admin/new-cases/${id}`);
  }
  static getClientForCases() {
    return $authHost.get(`/admin/getClientsForCases`);
  }
  static getServiceForCases(client_id) {
    return $authHost.get(`/admin/getServicesForCases/${client_id}`);
  }
  static getServiceTypesForCases(service_id) {
    return $authHost.get(`/admin/getServiceTypes/${service_id}`);
  }
  static getEmployeesForCases(client_id, service_id) {
    return $authHost.get(`/admin/getEmployees/${client_id}/${service_id}`);
  }
  static getCase(id) {
    return $authHost.get(`/admin/new-cases/${id}`);
  }
  static createCases(data) {
    return $authHost.post(`/admin/new-cases`, data);
  }
  static editCases(id, data) {
    return $authHost.post(`/admin/new-cases/${id ?? ''}`, data);
  }
}
