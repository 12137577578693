import classNames from '../../../utils/utils';

export const columnsServiceLogAdmin = (
  onClickRemind,
  onClickDelete,
  dataPage,
  navigate,
  onClickView,
  onClickSend,
  onClickMove,
  onClickUnMove,
  isLoading,
) => [
  {
    accessorKey: 'id',
    header: 'Id', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 30,
    mantineTableBodyCellProps: ({ row }) =>
      row?.original?.['week_status']
        ? {
            sx: {
              backgroundColor: '#d8dadc',
            },
          }
        : {},
    Cell: ({ row }) => <p className="whitespace-nowrap">#{row?.original?.id}</p>,
  },
  {
    accessorKey: 'week of',
    header: 'Week Of', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 80,
    mantineTableBodyCellProps: ({ row }) =>
      row?.original?.['week_status']
        ? {
            sx: {
              backgroundColor: '#d8dadc',
            },
          }
        : {},
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['week of']}</p>,
  },
  {
    accessorKey: 'employee',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    mantineTableBodyCellProps: ({ row }) =>
      row?.original?.['week_status']
        ? {
            sx: {
              backgroundColor: '#d8dadc',
            },
          }
        : {},
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['employee']}</p>,
  },
  {
    accessorKey: 'school',
    header: 'Clients', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    mantineTableBodyCellProps: ({ row }) =>
      row?.original?.['week_status']
        ? {
            sx: {
              backgroundColor: '#d8dadc',
            },
          }
        : {},
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['school']?.[0] ?? ''}</p>,
  },
  {
    accessorKey: 'hours',
    header: 'Hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 40,
    mantineTableBodyCellProps: ({ row }) =>
      row?.original?.['week_status']
        ? {
            sx: {
              backgroundColor: '#d8dadc',
            },
          }
        : {},
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['hours']}</p>,
  },
  {
    accessorKey: `status`,
    header: 'Status', //uses the default width from defaultColumn prop
    maxSize: 60,
    mantineTableBodyCellProps: ({ row }) =>
      row?.original?.['week_status']
        ? {
            sx: {
              backgroundColor: '#d8dadc',
            },
          }
        : {},
    Cell: ({ row }) => (
      <p
        className={classNames(
          row?.original?.status == '2'
            ? 'text-[#00B1B2]'
            : row?.original?.status == '1'
            ? 'text-[#07BE6E]'
            : 'text-[red]',
          'whitespace-nowrap',
        )}>
        {row?.original?.status == '1' ? 'Approved' : row?.original?.status == '2' ? 'Completed' : 'Not Completed'}
      </p>
    ),
  },
  {
    accessorKey: 'Action',
    header: 'Action',
    size: 140, //decrease the width of this column
    // maxSize: 100,
    mantineTableBodyCellProps: ({ row }) =>
      row?.original?.['week_status']
        ? {
            sx: {
              backgroundColor: '#d8dadc',
            },
          }
        : {},
    Cell: ({ row }) =>
      row?.original?.['status'] == '0' ? (
        row?.original?.['week_status'] ? (
          <div className="flex gap-2">
            <button
              onClick={() =>
                onClickDelete(
                  row?.original?.id,
                  row?.original?.employee_id,
                  dataPage?.links?.current_page,
                  row?.original?.week_status,
                )
              }
              className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
              Return
            </button>
          </div>
        ) : (
          <div className="flex gap-2">
            <button
              disabled={isLoading}
              onClick={() => onClickRemind(row?.original?.id, row?.original?.employee_id)}
              className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
              Remind
            </button>
            <button
              onClick={() =>
                onClickDelete(
                  row?.original?.id,
                  row?.original?.employee_id,
                  dataPage?.links?.current_page,
                  row?.original?.week_status,
                )
              }
              className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
              Delete
            </button>
          </div>
        )
      ) : row?.original?.['status'] == '2' ? (
        <div className="flex gap-2">
          <button
            onClick={() =>
              navigate(`Employee/${row?.original?.employee_id}/Calendar/${row?.original.id}`, {
                state: { page: dataPage?.links?.current_page },
              })
            }
            className="text-[#4885ED] border px-4 border-[#4885ED] block rounded py-1 mr-2 sm:text-sm text-xs hover:text-indigo-900 ">
            <img className="max-w-[60px] h-[20px] block" src="/CalendarIcon.svg" alt="img" />
          </button>
          <button
            onClick={() =>
              onClickView(
                row?.original?.id,
                row?.original?.employee_id,
                row?.original?.['status'],
                row?.original?.school_id,
                row?.original?.school?.[0],
              )
            }
            className="text-[#4885ED] block border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
            View
          </button>
        </div>
      ) : (
        row?.original?.['status'] == '1' && (
          <div className="flex gap-2 min-w-[180px]">
            <button
              onClick={() => onClickSend(row?.original?.id, row?.original?.employee_id, row?.original?.school_id)}
              className="text-[#4885ED] block  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
              Send
            </button>
            <button
              onClick={() =>
                onClickView(
                  row?.original?.id,
                  row?.original?.employee_id,
                  row?.original?.['status'],
                  row?.original?.school_id,
                  row?.original?.school?.[0],
                  row?.original?.employee,
                  row?.original?.['week of'],
                )
              }
              className="text-[#4885ED] block border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
              View
            </button>
            {(row?.original?.['school_access']?.[0] == true || row?.original?.['parent_access']?.[0] == true) &&
            row?.original?.['moved_status'] != 1 ? (
              <button
                onClick={() =>
                  onClickMove(
                    row?.original?.id,
                    row?.original?.employee_id,
                    row?.original?.school_id,
                    dataPage?.links?.current_page,
                  )
                }
                className={`text-[#4885ED] block border px-4 border-[#4885ED] rounded py-1 text-sm  hover:text-indigo-900`}>
                {'Publish'}
              </button>
            ) : (
              (row?.original?.['school_access']?.[0] == true || row?.original?.['parent_access']?.[0] == true) &&
              row?.original?.['moved_status'] == 1 && (
                <button
                  onClick={() =>
                    onClickUnMove(
                      row?.original?.id,
                      row?.original?.employee_id,
                      row?.original?.school_id,
                      dataPage?.links?.current_page,
                    )
                  }
                  className={`text-[#4885ED] block border px-4 border-[#4885ED] rounded py-1 text-sm  hover:text-indigo-900`}>
                  {'Unpublish'}
                </button>
              )
            )}
          </div>
        )
      ),
  },
];

export const columnsCusomServiceLogAdmin = (
  onClickViewCusom,
  onClickMoveCustom,
  dataPageCustom,
  onClickUnMoveCustom,
  onClickRegenerate,
  onClickSendCustom,
  onClickDeleteCustom,
  onClickEmployeeList,
  onClickEditCustom,
) => [
  {
    accessorKey: 'id',
    header: 'Id', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 30,
    Cell: ({ row }) => <p className="whitespace-nowrap">#{row?.original?.id}</p>,
  },
  {
    accessorKey: 'Date',
    header: 'Date', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 80,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['date']}</p>,
  },
  {
    accessorKey: 'employee',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => (
      // <p className="whitespace-nowrap">{row?.original?.['employee']}</p>
      <p
        className="whitespace-nowrap cursor-pointer"
        onClick={() => onClickEmployeeList(row?.original?.['employees'], 'For which employees is this Custom Log')}>
        {row?.original?.['employees']?.map((a, index, array) => `${a + `${index !== array?.length - 1 ? `,` : ' '} `}`)}
      </p>
    ),
  },
  {
    accessorKey: 'client',
    header: 'Clients', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.client ?? ''}</p>,
  },
  {
    accessorKey: 'hours',
    header: 'Hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['total']}</p>,
  },
  {
    accessorKey: 'Action',
    header: 'Action',
    size: 250, //decrease the width of this column
    // maxSize: 100,
    Cell: ({ row }) => (
      <div className="flex gap-2 min-w-[250px]">
        <button
          onClick={() =>
            onClickViewCusom(row?.original?.id, row?.original?.client, row?.original?.employee, row?.original?.date)
          }
          className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
          View
        </button>
        {(row?.original?.access == true || row?.original?.parentAccess == true) &&
        row?.original?.['moved_status'] != 1 ? (
          <button
            onClick={() => onClickMoveCustom(row?.original?.id, dataPageCustom?.links?.current_page)}
            className={`text-[#4885ED] block  border px-4 border-[#4885ED] rounded py-1 text-sm hover:text-indigo-900`}>
            {'Publish'}
          </button>
        ) : (
          (row?.original?.access == true || row?.original?.parentAccess == true) &&
          row?.original?.['moved_status'] == 1 && (
            <button
              onClick={() => onClickUnMoveCustom(row?.original?.id, dataPageCustom?.links?.current_page)}
              className={`text-[#4885ED] block  border px-4 border-[#4885ED] rounded py-1 text-sm hover:text-indigo-900`}>
              {'Unpublish'}
            </button>
          )
        )}
        <button
          onClick={() => onClickRegenerate(row?.original?.id)}
          disabled={row?.original?.is_send || row?.original?.['moved_status']}
          className={`text-[#4885ED] block border px-4 border-[#4885ED] rounded py-1 text-sm ${
            (row?.original?.is_send === false || row?.original?.['moved_status'] === false) && `hover:text-indigo-900`
          }   ${
            (row?.original?.is_send || row?.original?.['moved_status']) && `text-[white] bg-[#A6ADC9] border-[#A6ADC9]`
          } `}>
          Regenerate
        </button>
        <button
          onClick={() => onClickSendCustom(row?.original?.id, dataPageCustom?.links?.current_page)}
          className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
          {row?.original?.is_send ? 'Resend' : 'Send'}
        </button>
        <button
          onClick={() => onClickDeleteCustom(row?.original?.id, dataPageCustom?.links?.current_page)}
          className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
          Delete
        </button>
        {row?.original?.is_assignment && (
          <button
            onClick={() => onClickEditCustom(row?.original?.id)}
            className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
            Edit
          </button>
        )}
      </div>
    ),
  },
];

export const columnsServiceLogViewDataAdmin = (viewData, onClickComment, onClickCommentShow) => [
  {
    accessorKey: 'date',
    header: 'Date', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 50,
    Cell: ({ row }) => (
      <div>
        <p className="whitespace-nowrap">{row?.original?.day}</p>
        <p className="whitespace-nowrap">{row?.original?.name}</p>
      </div>
    ),
  },
  {
    accessorKey: 'start time',
    header: 'Start Time', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 40,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        <p className={`whitespace-nowrap ${elem?.is_request_log && 'text-[#00B1B2]'} `} key={index}>
          {elem?.['Start Time']}
        </p>
      )),
  },
  {
    accessorKey: 'end time',
    header: 'End Time', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 60,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        <div className="flex" key={index}>
          <p className={`whitespace-nowrap ${elem?.is_request_log && 'text-[#00B1B2]'} `}>{elem?.['End Time']}</p>
          {viewData?.status == 2 && (
            <img
              onClick={() =>
                onClickComment({
                  id: elem?.['id'],
                  day: row?.original?.day,
                  start: elem?.['Start Time'],
                  end: elem?.['End Time'],
                })
              }
              className="w-4 ml-2 cursor-pointer"
              src={'/PenActive.svg'}
              alt="img"
            />
          )}
          {elem?.['comment']?.length > 0 && (
            <img
              onClick={() =>
                onClickCommentShow({
                  id: elem?.['id'],
                  day: row?.original?.day,
                  start: elem?.['Start Time'],
                  end: elem?.['End Time'],
                  comment: elem?.['comment'],
                })
              }
              className="w-4 ml-2 cursor-pointer"
              src={'/comment.svg'}
              alt="img"
            />
          )}
        </div>
      )),
  },
  {
    accessorKey: 'Total Time',
    header: 'Total Time', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 40,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        // <div>
        <p className={`whitespace-nowrap ${elem?.is_request_log && 'text-[#00B1B2]'} `} key={index}>
          {elem?.['Total Time']}
        </p>
      )),
  },
  {
    accessorKey: 'client',
    header: 'Client', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        // <div>
        <p className={`whitespace-nowrap ${elem?.is_request_log && 'text-[#00B1B2]'} `} key={index}>
          {elem?.['client']}
        </p>
      )),
  },
  {
    accessorKey: 'request',
    header: 'Request', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        // <div>
        <p className={`whitespace-nowrap ${elem?.is_request_log && 'text-[#00B1B2]'} `} key={index}>
          {elem?.['form_name'] ?? ' - '}
        </p>
      )),
  },
  {
    accessorKey: 'Positions',
    header: 'Position', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        // <div>
        <p className={`whitespace-nowrap ${elem?.is_request_log && 'text-[#00B1B2]'} `} key={index}>
          {elem?.['Positions']}
        </p>
      )),
  },
  {
    accessorKey: 'activity',
    header: 'Description', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        <li
          className={`whitespace-nowrap overflow-hidden cursor-pointer ${elem?.is_request_log && 'text-[#00B1B2]'} `}
          key={index}
          onClick={() =>
            onClickCommentShow({
              id: elem?.['id'],
              day: row?.original?.day,
              start: elem?.['Start Time'],
              end: elem?.['End Time'],
              comment: `${elem?.['activity']?.[0]?.label} ${elem?.rush == 0 ? '' : ' (Rush)'}`,
            })
          }>
          <span>{elem?.['activity']?.[0]?.label}</span>
          <span>{elem?.rush == 0 ? '' : ' (Rush)'}</span>
        </li>
      )),
  },
];

export const statuses = [
  { name: 'All Statuses', id: '' },
  { name: 'Approved', id: 1 },
  { name: 'Completed', id: 2 },
  { name: 'Not Completed', id: 0 },
];
