import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import InvoiceHttp from '../../http/invoiceHttp/invoiceHttp';
import Pagination from '../../components/Pagination/Pagination';
import TitleBlockInvoice from '../../components/TitleBlockInvoice/TitleBlockInvoice';
import InvoiceModal from '../../components/Modal/InvoiceModal';
import { useNavigate } from 'react-router-dom';
import TableClients from '../../components/DataTable/TableClients';
import EmployeesHttp from '../../http/employeesHttp/employeesHttp';
import ClientHttp from '../../http/clientsHttp/clientsHttp';
import { afterMove, afterSend, afterUnMove, columnsInvoiceAdmin } from './helpers/InvoicesUtils';
import InvoiceCreateModal from '../../components/Modal/InvoiceCreateModal';
import InfoListModal from '../../components/Modal/InfoListModal';

import 'react-toastify/dist/ReactToastify.css';

export default function Invoices() {
  const [dataId, setDateId] = useState('');
  const [data, setData] = useState([]);
  const [dataPage, setDataPage] = useState([]);
  const [isSend, setIsSend] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenSendModal, setIsOpenSendModal] = useState(false);
  const [isOpenPublishModal, setIsOpenPublishModal] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [currentPage, setCurrentPage] = useState('');
  const [AllEmployeesData, setAllEmployeesData] = useState([]);
  const [AllClientsData, setAllClientsData] = useState([]);
  const [dataEmployeeCurrent, setDataEmployeeCurrent] = useState('');
  const [dataClientCurrent, setDataClientCurrent] = useState('');
  const [dataPublishCurrent, setDataPublishCurrent] = useState('');
  const [dataSentCurrent, setDataSentCurrent] = useState('');
  const [List, setList] = useState();
  const [isOpenList, setIsOpenList] = useState(false);

  const naviagete = useNavigate();

  const fetchData = async (id, e_id, is_send, page) => {
    try {
      const response = await InvoiceHttp.getInvoicePdf(id);
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      setFileUrl({ url, filename: `Invoice_${e_id}`, id: e_id, is_send: is_send, page: page });
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  const onClickEmployeeList = list => {
    if (list?.length > 1) {
      setIsOpenList(true);
      setList(list);
    }
  };

  const onClickResend = (id, page) => {
    InvoiceHttp.sendInvoice(id)
      .then(e => afterSend())
      .then(() => setCurrentPage(page))
      .then(() => setIsSend(!isSend));
  };
  const onClickMove = (id, page) => {
    InvoiceHttp.moveInvoice(id)
      .then(() => afterMove())
      .then(() => setCurrentPage(page))
      .then(() => setIsSend(!isSend));
  };
  const onClickUnMove = (id, page) => {
    InvoiceHttp.unMoveInvoice(id)
      .then(() => afterUnMove())
      .then(() => setCurrentPage(page))
      .then(() => setIsSend(!isSend));
  };

  const onClickSend = (id, page) => {
    InvoiceHttp.sendInvoice(id)
      .then(() => afterSend())
      .then(() => setCurrentPage(page))
      .then(() => setIsOpen(false))
      .then(() => setIsSend(!isSend));
  };

  const onClickRegenerate = id => {
    naviagete(`/Admin/RegenerateInvoice/${id}`);
  };
  const onClickEdit = id => {
    naviagete(`/Admin/EditInvoice/${id}`);
  };

  const onClickView = (e, e_id, is_send, page) => {
    setIsOpen(true);
    fetchData(e, e_id, is_send, page);
  };

  const onPaginationClick = id => {
    InvoiceHttp.getInvoicePaginationPage(
      id,
      dataEmployeeCurrent,
      dataClientCurrent,
      dataPublishCurrent,
      dataSentCurrent,
    ).then(e => {
      setDataPage(e?.data);
      setData(e?.data?.data);
    });
    ClientHttp.getAllClientss().then(e => {
      e?.data?.data.unshift({ name: 'All Clients', id: '' });
      const clients = e?.data?.data;
      setAllClientsData(clients);
    });
    EmployeesHttp.employeesAllLinks().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          id: item?.id || item?.[0]?.employee_id,
          name: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.id)) {
            uniqueIdsSet.add(item.id);
            result.push(item);
          }
          return result;
        }, []);
      employesList.unshift({ id: '', name: 'All Employees' });
      employesList?.length
        ? setAllEmployeesData(employesList)
        : setAllEmployeesData([{ id: '', name: 'All Employees' }]);
    });
  };

  useEffect(() => {
    EmployeesHttp.employeesAllLinks().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          id: item?.id || item?.[0]?.employee_id,
          name: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.id)) {
            uniqueIdsSet.add(item.id);
            result.push(item);
          }
          return result;
        }, []);
      employesList.unshift({ id: '', name: 'All Employees' });
      employesList?.length
        ? setAllEmployeesData(employesList)
        : setAllEmployeesData([{ id: '', name: 'All Employees' }]);
    });
    ClientHttp.getAllClientss().then(e => {
      e?.data?.data.unshift({ name: 'All Clients', id: '' });
      const clients = e?.data?.data;
      setAllClientsData(clients);
    });
    InvoiceHttp.getInvoicePagination(
      dataId,
      dataEmployeeCurrent,
      currentPage,
      dataClientCurrent,
      dataPublishCurrent,
      dataSentCurrent,
    ).then(e => {
      setDataPage(e?.data);
      setData(e?.data?.data);
    });
  }, [dataId, isSend, dataEmployeeCurrent, dataClientCurrent, dataPublishCurrent, dataSentCurrent]);

  return (
    <div className="w-full sm:p-8 p-2 overflow-hidden">
      <InvoiceCreateModal
        open={isOpenCreateModal}
        setOpen={setIsOpenCreateModal}
        navigateRequestEmployee="/Admin/CreateInvoiceRequest"
      />
      <InvoiceCreateModal
        open={isOpenSendModal}
        setOpen={setIsOpenSendModal}
        navigateEmployee="/Admin/SendInvoices"
        navigateNonEmployee="/Admin/SendInvoices"
        navigateDuelEmployee="/Admin/SendInvoicesCase"
        navigateRequestEmployee="/Admin/SendInvoicesRequest"
        />
      <InvoiceCreateModal
        open={isOpenPublishModal}
        setOpen={setIsOpenPublishModal}
        navigateEmployee="/Admin/PublishInvoices"
        navigateNonEmployee="/Admin/PublishInvoices"
        navigateDuelEmployee="/Admin/PublishInvoicesCase"
        navigateRequestEmployee="/Admin/PublishInvoicesRequest"
      />
      <InfoListModal
        open={isOpenList}
        setOpen={setIsOpenList}
        List={List}
        title={'For which employees is this Invoice'}
      />
      <InvoiceModal open={isOpen} setOpen={setIsOpen} fileUrl={fileUrl} onClickSend={onClickSend} sendable={true} />
      <TitleBlockInvoice
        src={'/InvoicesIcon.svg'}
        textItem={'Invoices'}
        className="text-base font-bold"
        setDateId={setDateId}
        setDataEmployeeCurrent={setDataEmployeeCurrent}
        AllEmployeesData={AllEmployeesData}
        setCurrentPage={setCurrentPage}
        AllClientsData={AllClientsData}
        setDataClientCurrent={setDataClientCurrent}
        setDataPublishCurrent={setDataPublishCurrent}
        setDataSentCurrent={setDataSentCurrent}
        setIsOpenCreateModal={setIsOpenCreateModal}
        setIsOpenSendModal={setIsOpenSendModal}
        setIsOpenPublishModal={setIsOpenPublishModal}
      />
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className="w-full overflow-x-auto">
          <div className="inline-block min-w-[1200px]">
            <TableClients
              tbody={data ?? []}
              maxHeight="600px"
              columns={columnsInvoiceAdmin(
                onClickView,
                onClickResend,
                dataPage,
                onClickMove,
                onClickUnMove,
                onClickRegenerate,
                onClickEdit,
                onClickEmployeeList,
              )}
            />
          </div>
        </div>
        <Pagination onPaginationClick={onPaginationClick} paginationData={dataPage.links} />
      </div>
      <ToastContainer />
    </div>
  );
}
