import { useEffect, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import { notifySuccess } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClientsSlice, loadParentsSlice } from '../../redux/clientsSlice/clientsSlice';
import Select from '../../components/Select/Select';
import AdminServiceLogHttp from '../../http/adminServiceLogHttp/adminServiceLogHttp';
import InvoiceModal from '../../components/Modal/InvoiceModal';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import DatePicker from 'react-multi-date-picker';
import ClientHttp from '../../http/clientsHttp/clientsHttp';
import { columnsSendServiceLogAdmin } from './helpers/SendServiceLogsUtils';

import 'react-toastify/dist/ReactToastify.css';

export default function SendServiceLogs() {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [dateRange, setDateRange] = useState(null);
  const [allParentsSelectData, setAllParentsSelectData] = useState();
  const [allParentsClients, setAllParentsClients] = useState([]);
  const [currentParentId, setCurrentParentId] = useState('');

  const dispatch = useDispatch();
  const clientsData = useSelector(state => state?.clients?.storeAllClientsData);
  const allParents = useSelector(state => state?.clients?.parentClientSliceDataAll?.data);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
  });
  const navigate = useNavigate();

  const [isError, setIsError] = useState();
  const [checked, setChecked] = useState([]);

  const [currentSchoolId, setCurrentSchoolId] = useState();
  const [tbodyData, setTbodyData] = useState([]);

  const afterSubmit = () => {
    notifySuccess('ServiceLogs successfully sent');
    setTimeout(() => {
      navigate('/Admin/ServiceLog');
    }, 2000);
  };

  const onSubmit = data => {
    setIsLoading(true);
    const sendedData = {
      group_school_id: data?.group_school_id || null,
      school_id: data?.school_id || null,
      selectedIds: checked,
    };
    AdminServiceLogHttp.serviceLogSendLogsLinks(sendedData)
      .then(() => afterSubmit())
      .catch(err => {
        setIsError(err?.response?.data?.message);
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false)
      });
  };

  const onClickCancel = () => {
    navigate('/Admin/ServiceLog');
  };
  const onChangeSchoolId = e => {
    setCurrentSchoolId(e.target.value);
    setValue('school_id', e.target.value);
    setValue('masterCheckbox', false);
    setChecked([]);
    reset();
  };

  const onChangeParentSchoolId = e => {
    setCurrentSchoolId('');
    setValue('school_id', '');
    setCurrentParentId(e.target.value);
    setValue('masterCheckbox', false);
    setChecked([]);
    reset();
  };

  const handleDateChange = value => {
    const date = {
      startDate: value?.[0]?.format('MM/DD/YYYY'),
      endDate: value?.[1]?.format('MM/DD/YYYY'),
    };
    setDateRange(date);
    setValue('masterCheckbox', false);
    setChecked([]);
    reset();
  };

  const fetchData = async (id, employeId, clientId, client_name) => {
    try {
      const response = await AdminServiceLogHttp.serviceLogWeekAdminPDFLinks(id, employeId, clientId);
      const blob = new Blob([response?.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      setFileUrl({ url, filename: 'ServiceLog', client_name });
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  const onClickView = (id, employeId, clientId) => {
    setIsOpen(true);
    fetchData(id, employeId, clientId);
  };

  const handleMasterCheckboxChange = e => {
    if (e.target.checked) {
      const allCheckboxIds = tbodyData.map(item => ({
        week_id: item?.week_id,
        employee_id: item?.employee_id,
        school_id: item?.school_id,
      }));
      setChecked(allCheckboxIds);
      tbodyData?.length > 0 &&
        tbodyData?.map(
          item => (Number(item?.id) ? setValue(`${Number(item?.id)}`, true) : item),
        );
    } else {
      setChecked([]);
      tbodyData?.length > 0 &&
        tbodyData?.map(item => (Number(item?.id) ? setValue(`${Number(item?.id)}`, false) : item));
    }
  };

  const changeCheckBox = (g, row) => {
    if (g?.target?.checked) {
      setChecked(prev => [
        ...prev,
        {
          week_id: row?.original?.week_id,
          employee_id: row?.original?.employee_id,
          school_id: row?.original?.school_id,
        },
      ]);
      Number(row?.original?.id) && setValue(`${Number(row?.original?.id)}`, true);
    } else if (g?.target?.checked === false) {
      setChecked(prev =>
        [...prev]?.filter(
          elem =>
            elem?.week_id !== row?.original?.week_id ||
            elem?.employee_id !== row?.original?.employee_id ||
            elem?.school_id !== row?.original?.school_id,
        ),
      );
      Number(row?.original?.id) && setValue(`${Number(row?.original?.id)}`, false);
    }
  };

  useEffect(() => {
    reset({ school_id: currentSchoolId, group_school_id: currentParentId });
    setValue('school_id', currentSchoolId);
    setValue('group_school_id', currentParentId);
  }, [tbodyData]);

  useEffect(() => {
    dispatch(getAllClientsSlice());
    dispatch(loadParentsSlice());
  }, []);

  useEffect(() => {
    (currentParentId || currentSchoolId) && dateRange?.startDate && dateRange?.endDate
      ? AdminServiceLogHttp.serviceLogSendGetListLogs(currentSchoolId, dateRange, currentParentId).then(e =>
          setTbodyData(e?.data?.data),
        )
      : setTbodyData([]);
  }, [currentSchoolId, dateRange, currentParentId]);

  useEffect(() => {
    setValue('school_id', '');
    setCurrentSchoolId('');
  }, [clientsData]);

  useEffect(() => {
    const parents = allParents?.map(item => ({
      name: item?.['Client Name'],
      id: item?.id,
    }));
    parents?.unshift({ id: '', name: 'Not Selected' });
    setAllParentsSelectData(parents);
  }, [allParents]);

  useEffect(() => {
    currentParentId &&
      ClientHttp.getClientsByParent(currentParentId).then(e => {
        const data = e?.data?.data;
        data?.unshift({ id: '', name: 'Not Selected' });
        setAllParentsClients(data);
        setValue('school_id', '');
        setCurrentSchoolId('');
      });
  }, [currentParentId]);

  useEffect(() => {
    if (tbodyData?.length > 0) {
      checked?.length === tbodyData?.length ? setValue('masterCheckbox', true) : setValue('masterCheckbox', false);
    }
  }, [checked, tbodyData]);

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <InvoiceModal open={isOpen} setOpen={setIsOpen} fileUrl={fileUrl} />
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={'Send Service Logs'} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          {allParentsSelectData && (
            <div className="w-full bg-white sm:px-7 px-2">
              <Select
                register={register}
                title={'Select Parent Company'}
                selectData={allParentsSelectData}
                selectClass="flex-col gap-2"
                onChange={onChangeParentSchoolId}
                name={'group_school_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.group_school_id?.message}</p>}
            </div>
          )}
          <div className="py-4 sm:px-7 px-2 w-full flex gap-6">
            {currentParentId == '' ? (
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Select Client Company'}
                  selectData={clientsData}
                  selectClass="flex-col gap-2"
                  name={'school_id'}
                  onChange={onChangeSchoolId}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_id?.message}</p>}
              </div>
            ) : (
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Select Client Company'}
                  selectData={allParentsClients}
                  selectClass="flex-col gap-2"
                  name={'school_id'}
                  onChange={onChangeSchoolId}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_id?.message}</p>}
              </div>
            )}
          </div>
          <div className="py-4 sm:px-7 px-2 w-full flex gap-2">
            <div
              className={`w-full [&>div>input]:w-full [&>div]:w-full [&>div>input]:h-[30px] [&>div>input]:border-none [&>div>input]:focus:border-hidden [&>div>input]:hover:shadow-none [&>div>input]:focus:outline-none [&>div>input]:hover:border-none border rounded [&>div>input]:focus:shadow-none sm:text-base text-xs`}>
              <DatePicker range format="MM/DD/YYYY" placeholder="MM/DD/YYYY - MM/DD/YYYY" onChange={handleDateChange} />
            </div>
          </div>
          <div>
            <div className="px-4 py-1 sm:m-4 mx-4 my-6 bg-white rounded-xl">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full">
                  <TableEmployeesAdmin
                    tbody={tbodyData ?? []}
                    columns={columnsSendServiceLogAdmin(
                      register,
                      handleMasterCheckboxChange,
                      tbodyData,
                      checked,
                      changeCheckBox,
                      onClickView,
                    )}
                    maxHeight="500px"
                  />
                </div>
              </div>
            </div>
          </div>
          {isError && <p className="text-base font-medium text-[red] py-4 sm:px-7 px-2">{isError}</p>}
          <div>
            <CancleAdd
              disabled={isLoading || checked?.length < 1}
              buttonText={`Send Service Logs`}
              onClickCancel={onClickCancel}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
