import { notifySuccess } from '../../../utils/utils';

export const columnsInvoiceAdmin = (
  onClickView,
  onClickResend,
  dataPage,
  onClickMove,
  onClickUnMove,
  onClickRegenerate,
  onClickEdit,
  onClickEmployeeList
) => [
  {
    accessorKey: 'id',
    header: 'Id', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 30,
    Cell: ({ row }) => <p className="whitespace-nowrap">#{row?.original?.invoice_number}</p>,
  },
  {
    accessorKey: 'employee',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => 
    <p className={`whitespace-nowrap ${row?.original?.['employees']?.length >1 &&"cursor-pointer"}`} onClick={()=>onClickEmployeeList(row?.original?.['employees'])}>
      { row?.original?.['employees']?.map(
      (a, index, array) => `${a + `${index !== array?.length - 1 ? `,` : ' '} `}`,
    )}</p>,
  },
  {
    accessorKey: 'client',
    header: 'Clients', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.client ?? ''}</p>,
  },
  {
    accessorKey: 'date',
    header: 'Date Range', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.date ?? ''}</p>,
  },
  // {
  //   accessorKey: 'date',
  //   header: 'Date Range', //uses the default width from defaultColumn prop
  //   // hishem avelacnem
  //   // size: 40,
  //   Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.date ?? ''}</p>,
  // },
  {
    accessorKey: 'price',
    header: 'Amount', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 60,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.price ?? ''}</p>,
  },
  {
    accessorKey: 'type',
    header: 'Type', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 50,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.type ?? ''}</p>,
  },
  {
    accessorKey: 'Action',
    header: 'Action',
    // size: 250, //decrease the width of this column
    minSize: 350, //decrease the width of this column
    // maxSize: 250,
    Cell: ({ row }) => (
      <div className="flex gap-2 min-w-[300px]">
        <button
          onClick={() =>
            onClickView(
              row?.original?.id,
              row?.original?.invoice_number,
              row?.original?.is_send,
              dataPage?.links?.current_page,
            )
          }
          className="text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm hover:text-indigo-900">
          View
        </button>
        <button
          onClick={() => onClickResend(row?.original?.id, dataPage?.links?.current_page)}
          className="text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm hover:text-indigo-900 w-[80px]">
          {row?.original?.is_send ? 'Resend' : 'Send'}
        </button>
        {(row?.original?.access == true || row?.original?.parentAccess) && row?.original?.['moved_status'] != 1 ? (
          <button
            onClick={() => onClickMove(row?.original?.id, dataPage?.links?.current_page)}
            className={`text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm hover:text-indigo-900`}>
            {'Publish'}
          </button>
        ) : (
          (row?.original?.access == true || row?.original?.parentAccess) &&
          row?.original?.['moved_status'] == 1 && (
            <button
              onClick={() => onClickUnMove(row?.original?.id, dataPage?.links?.current_page)}
              className={`text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm hover:text-indigo-900`}>
              {'Unpublish'}
            </button>
          )
        )}
        <button
          onClick={() => onClickRegenerate(row?.original?.id)}
          disabled={row?.original?.is_send || row?.original?.['moved_status']}
          className={`text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm ${
            (row?.original?.is_send === false || row?.original?.['moved_status'] === false) && `hover:text-indigo-900`
          }   ${
            (row?.original?.is_send || row?.original?.['moved_status']) && `text-[white] bg-[#A6ADC9] border-[#A6ADC9]`
          } `}
          >
          Regenerate
        </button>
        <button
          onClick={() =>
            onClickEdit(
              row?.original?.id,
            )
          }
          className="text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm hover:text-indigo-900">
          Edit
        </button>
      </div>
    ),
  },
];

export const afterSend = () => {
  notifySuccess('Invoice successfully sent');
};
export const afterMove = () => {
  notifySuccess('Invoice successfully posted');
};
export const afterUnMove = () => {
  notifySuccess('Invoice successfully unposted');
};
