import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import Select from '../../components/Select/Select';
import Title from '../../components/Title/Title';
import { useEffect, useMemo, useState } from 'react';
import InputLabel from '../../components/InputLabel/InputLabel';
import DatePickerTime from '../../components/DatePickerTime/DatePickerTime';
import AddServiceLogRequestDefaultValue from './AddServiceLogRequestDefaultValue';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import serviceLogHttp from '../../http/serviceLogHttp/serviceLogHttp';
import { notifySuccess } from '../../utils/utils';
import { ToastContainer } from 'react-toastify';
import TimerDifference from '../../components/TimerDifference/TimerDifference';
import TotalTime from './TotalTime';
import { yupResolver } from '@hookform/resolvers/yup';
import RequestErrorAddServiceLogRequest from './RequestErrorAddServiceLogRequest';
import CreateableSelect from '../../components/CreateableSelect/CreateableSelect';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

export default function AddServiceLogRequest() {
  const errorTime = useSelector(state => state?.error?.error);
  const [errorss, setErrorss] = useState(false);
  const [diableAdd, setDiableAdd] = useState(false);
  const [requestsData, setRequestsData] = useState([]);
  // const [requestsDataCurrent, setRequestsDataCurrent] = useState();
  const [clientsDataCurrent, setClientsDataCurrent] = useState();
  const [positionsData, setPositionsData] = useState([]);
  const [activitiesData, setActivitiesData] = useState([]);
  const [clientsDataServiceCurrent, setClientsDataServiceCurrent] = useState();
  const [errorFromBack, setErrorFromBack] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isEligibleOvertimeForRequests =
  useSelector(state => state?.adminProfile?.employeeProfiledata?.eligibleOvertimeForRequests) == 1 ? true : false;

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
    setValue,
    watch,
    setError,
  } = useForm({
    defaultValues: useMemo(() => AddServiceLogRequestDefaultValue(requestsData), [requestsData]),
    resolver: yupResolver(useMemo(() => RequestErrorAddServiceLogRequest(), [])),
  });
  const {
    fields: ServiceLogTimeActivitiesFields,
    append: ServiceLogTimeActivitiesAppend,
    remove: ServiceLogTimeActivitiesRemove,
  } = useFieldArray({
    control,
    name: 'serviceLogTimeActivities',
  });

  const onClickRemove = index => {
    // setValue(`serviceLogTimeActivities.${index}.currentMinError`,false)
    ServiceLogTimeActivitiesRemove(index);
  };

  const onChangeRequestsData = e => {
    // setRequestsDataCurrent(e.target.value);
    setValue('request_id', e.target.value);

    const currentRequest = requestsData?.find(elem => elem?.id == e.target.value);

    setValue('service', currentRequest?.service);
    setValue('service_id', currentRequest?.service_id);
    setValue('school', currentRequest?.school);
    setValue('school_id', currentRequest?.school_id);

    setClientsDataServiceCurrent(currentRequest?.service_id);
    setClientsDataCurrent(currentRequest?.school_id);

    getValues('serviceLogTimeActivities').forEach((elem, index) => {
      setValue(`serviceLogTimeActivities.${index}.position_id`, '');
      setValue(`serviceLogTimeActivities.${index}.activiteee`, {});
    });
    // setPositionsData([]);
  };

  const afterSubmit = () => {
    notifySuccess('Service Log successfully created');
    setTimeout(() => {
      navigate('/Employee/ServiceLog');
    }, 2000);
  };

  const onSubmit = data => {
    setIsLoading(true);
    data.serviceLogTimeActivities = data.serviceLogTimeActivities.map(
      ({ position_id, activiteee, start_time, end_time }) => {
        const activity_id = activiteee?.value !== activiteee?.label ? activiteee?.value : null;
        const activity = activiteee?.value === activiteee?.label ? activiteee?.value : null;
        const formattedStartTime =
          typeof start_time === 'string' && !start_time.includes('GMT')
            ? start_time
            : start_time?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        const formattedEndTime =
          typeof end_time === 'string'
            ? end_time
            : end_time?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        return {
          position_id,
          activity_id,
          activity,
          start_time: formattedStartTime,
          end_time: formattedEndTime,
        };
      },
    );
    serviceLogHttp
      .serviceLogRequestAddLinks(data)
      .then(() => {})
      .then(afterSubmit)
      .catch(err => {
        setErrorFromBack(err?.response?.data?.message || err?.response?.data?.errors);
        setIsLoading(false);
      });
  };

  const onClickCancel = () => {
    navigate('/Employee/ServiceLog');
  };

  const onClickAddNewTimeActivity = lastTime => {
    const Startdate = new Date(lastTime);
    Startdate.setMinutes(Startdate.getMinutes());
    Startdate.setHours(Startdate.getHours());
    Startdate.setSeconds('00');
    const Enddate = new Date(lastTime);
    Enddate.setMinutes(Startdate.getMinutes() == '45' ? '00' : Enddate.getMinutes() + 15);
    Enddate.setHours(Startdate.getMinutes() == '45' ? Enddate.getHours() + 1 : Enddate.getHours());
    Enddate.setSeconds('00');
    ServiceLogTimeActivitiesAppend({
      start_time: Startdate,
      end_time: Enddate,
    });
  };

  useEffect(() => {
    serviceLogHttp.serviceLogRequestsLinks().then(e => {
      setRequestsData(e?.data?.data);
      // setRequestsDataCurrent(e?.data?.data?.[0]?.id);
      setValue('school_id', e?.data?.data?.[0]?.school_id);
      setValue('school', e?.data?.data?.[0]?.school);
      setValue('service_id', e?.data?.data?.[0]?.service_id);
      setValue('service', e?.data?.data?.[0]?.service);
      setClientsDataServiceCurrent(e?.data?.data?.[0]?.service_id);
      setClientsDataCurrent(e?.data?.data?.[0]?.school_id);
    });
  }, []);


  useEffect(() => {
    clientsDataCurrent &&
      clientsDataServiceCurrent &&
      serviceLogHttp.serviceLogPositionsRequestLinks(clientsDataServiceCurrent).then(e => {
        setPositionsData(e?.data?.data);
      });
    clientsDataCurrent &&
      clientsDataServiceCurrent &&
      serviceLogHttp.serviceLogActivitiesRequestLinks(clientsDataServiceCurrent).then(e => {
        setActivitiesData(e?.data?.data);
      });
  }, [clientsDataServiceCurrent, clientsDataCurrent]);

  useEffect(() => {
    reset(AddServiceLogRequestDefaultValue(requestsData));
  }, [requestsData]);

  useEffect(() => {
    getValues('serviceLogTimeActivities').forEach((elem, index) => {
      setValue(`serviceLogTimeActivities.${index}.position_id`, '');
      setValue(`serviceLogTimeActivities.${index}.activiteee`, {});
    });
  }, [positionsData]);

  const more4Hour = watch('serviceLogTimeActivities')?.some(e => e?.currentMinError === true);
  const min15Step = watch('serviceLogTimeActivities')?.some(e => e?.currentStepMinError === true);
  const activitesCount = watch('serviceLogTimeActivities');

  useEffect(() => {
    activitesCount?.length == 1 && setDiableAdd(false);
    activitesCount?.length == 1 && setErrorss(false);
  }, [activitesCount]);

  return (
    <div className="sm:px-8 px-4 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title={'Add Service Log Request'}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2">
            <div className="w-full bg-white">
              <Select
                register={register}
                title={'Request Name'}
                selectData={requestsData}
                selectClass="flex-col gap-2"
                onChange={onChangeRequestsData}
                name={'request_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.request_id?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <InputLabel
                labelTitle={'Client Company'}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'Client Company'}
                register={register}
                disabled={true}
                name={`school`}
                classNameLabel={'text-[#60657B] text-xs font-normal opacity-50'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_id?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <InputLabel
                labelTitle={'Service'}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'Service'}
                register={register}
                disabled={true}
                name={`service`}
                classNameLabel={'text-[#60657B] text-xs font-normal opacity-50'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.service_id?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <InputLabel
                classNameInput="flex flex-col"
                classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                className="w-full border p-[7px]"
                type="date"
                max={new Date().toISOString().split('T')[0]}
                labelTitle={'Date'}
                register={register}
                name={'date'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.date?.message}</p>}
            </div>
          </div>
          {ServiceLogTimeActivitiesFields.map((elem, index) => (
            <div key={elem?.id} className="grid sm:px-7 px-4 grid-cols-1 gap-2 mt-5">
              <div className="flex flex-row gap-2 md:w-1/2 md:pr-2">
                <div>
                  <DatePickerTime
                    setError={setError}
                    title="Start Time"
                    setValue={setValue}
                    value={getValues(`serviceLogTimeActivities.${index}.start_time`)}
                    name={`serviceLogTimeActivities.${index}.start_time`}
                    control={control}
                    index={index}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.start_time?.message}
                    </p>
                  )}
                </div>
                <div>
                  <DatePickerTime
                    setError={setError}
                    title="End Time"
                    setValue={setValue}
                    name={`serviceLogTimeActivities.${index}.end_time`}
                    control={control}
                    value={getValues(`serviceLogTimeActivities.${index}.end_time`)}
                    index={index}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.end_time?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="grid md:grid-cols-2 gap-4">
                {positionsData && (
                  <div className="w-full bg-white mt-2">
                    <Select
                      register={register}
                      title={'Position'}
                      selectData={positionsData}
                      selectClass="flex-col gap-2"
                      name={`serviceLogTimeActivities.${index}.position_id`}
                      className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                      classNameTitle="text-[#60657B] opacity-50 text-xs"
                    />
                    {errors && (
                      <p className="mb-0 text-sm text-amber-800">
                        {errors?.serviceLogTimeActivities?.[index]?.position_id?.message}
                      </p>
                    )}
                  </div>
                )}
                <div className="mt-2">
                  <CreateableSelect
                    register={register}
                    defaultOption={
                      // servicesData?.filter(item => item.id == clientsDataServiceCurrent)?.[0]?.activities
                      activitiesData
                    }
                    control={control}
                    setValue={setValue}
                    name={`serviceLogTimeActivities.${index}.activiteee`}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.activiteee?.value?.message ||
                        errors?.serviceLogTimeActivities?.[index]?.activiteee?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-5">
                <TimerDifference
                  setValue={setValue}
                  index={index}
                  nameStart={`serviceLogTimeActivities.${index}.start_time`}
                  nameEnd={`serviceLogTimeActivities.${index}.end_time`}
                  control={control}
                />
              </div>
              <>
                {index !== 0 && index == ServiceLogTimeActivitiesFields.length - 1 && (
                  <TotalTime
                    control={control}
                    index={index}
                    setErrorss={setErrorss}
                    setDiableAdd={setDiableAdd}
                    isOverTime={false}
                    isEligibleOvertimeForRequests={isEligibleOvertimeForRequests}
                  />
                )}
              </>
              <div className="flex justify-end">
                <section
                  className={`border rounded  text-center px-3 py-1 min-w-[204px] max-w-[204px] cursor-pointer ${
                    (diableAdd || errorss || more4Hour) && `bg-[#A6ADC9]`
                  } `}
                  onClick={() =>
                    onClickAddNewTimeActivity(
                      getValues(
                        `serviceLogTimeActivities.${getValues(`serviceLogTimeActivities`)?.length - 1}.end_time`,
                      ),
                    )
                  }>
                  <button
                    className={`text-[#4885ED] text-sm font-normal ${
                      (diableAdd || errorss || more4Hour) && `text-[white]`
                    }`}
                    type="button"
                    disabled={diableAdd || errorss || more4Hour}>
                    Add New Time Activity
                  </button>
                </section>
              </div>
              {ServiceLogTimeActivitiesFields.length > 1 && (
                <div className="flex flex-col gap-6">
                  <div className="flex justify-end">
                    <section
                      className="border rounded px-3 py-1  text-center min-w-[204px] max-w-[204px] cursor-pointer"
                      onClick={() => {
                        onClickRemove(index);
                      }}>
                      <button className="text-[#4885ED] text-sm font-normal" type="button">
                        Remove Time Activity
                      </button>
                    </section>
                  </div>
                </div>
              )}
            </div>
          ))}
          <div className="sm:px-7 px-4 mt-5">
            {errorFromBack && <p className="text-base font-medium text-[red]">{errorFromBack}</p>}
          </div>
          <div className="mt-6">
            <CancleAdd
              buttonText={'Add'}
              onClickCancel={onClickCancel}
              disabled={errorss || errorTime || more4Hour || min15Step || isLoading}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
